import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { CustomerItem } from './da'
import { CustomerController } from './controller'

interface CustomerSimpleResponse {
    data?: CustomerItem,
    onLoading?: boolean,
    type?: string
}

const initState: CustomerSimpleResponse = {
    data: undefined,
    onLoading: false
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETINFOR':
                    state.data = action.payload.data
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = customerSlice.actions

export default customerSlice.reducer

export class CustomerActions {
    static getInfor = (dispatch: Dispatch<UnknownAction>, id: string) => {
        dispatch(onFetching())
        CustomerController.getByIds([id]).then(res => {
            if (res.code === 200) {
                dispatch(handleActions({
                    type: 'GETINFOR',
                    data: res.data[0],
                }))
            }
        })
    }
}