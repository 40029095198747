import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import styles from './captureElement.module.css'

const CaptureElement = (props: { children: ReactNode, style?: CSSProperties, className?: string }) => {
    // Create a ref for the target element
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (elementRef.current) {
            resizeObserver.observe(elementRef.current);
            return () => { if (elementRef.current) resizeObserver.unobserve(elementRef.current) }
        }
    }, [elementRef])

    return <div className={`${props.className ?? ''} ${styles['capture-container']}`} style={props.style}>
        <div ref={elementRef}>{props.children}</div>
    </div>
};

export default CaptureElement;

const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
        const _rect = entry.contentRect
        const parentRect = entry.target.parentElement?.getBoundingClientRect()
        if (parentRect) {
            const _parentStyle = window.getComputedStyle(entry.target.parentElement as any)
            if (_rect.width >= _rect.height) {
                const _scale = (parentRect.width - parseFloat(_parentStyle.paddingLeft.replace("px", "")) - parseFloat(_parentStyle.paddingRight.replace("px", ""))) / _rect.width;
                (entry.target as any).style.scale = _scale
            } else {
                const _scale = (parentRect.height - parseFloat(_parentStyle.paddingTop.replace("px", "")) - parseFloat(_parentStyle.paddingBottom.replace("px", ""))) / _rect.height;
                (entry.target as any).style.scale = _scale
            }
        }
    }
});