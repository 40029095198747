import styles from './view.module.css'
import { forwardRef, useEffect, useRef, useState } from "react";
import { DataController, SettingDataController } from "../../module/controller";
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, Popup, Select1, showDialog, showPopup, Text, TextField, ToastMessage } from "wini-web-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { OutlineAppSetupPreferences, OutlineEdit, OutlineTrashCan } from "../../../assets/icon";
import { CardType, Reducers } from "../../module/da";
import RenderCardNewsByType from "../../module/manager/cardByType/cardNews";
import { randomGID } from '../../../Utils';
import { Select1Form, TextAreaForm, TextFieldForm } from '../../../project-component/component-form';
import { useForm } from 'react-hook-form';
import { TableController } from './controller';
import { EventStep } from './da';
import CaptureElement from '../../../project-component/captureElement';

export default function SettingCardTab({ pid, item }) {
    const _settingDataController = new SettingDataController({ pid: pid, setting: "card" })
    const _dataController = new DataController({ pid: pid, module: item.Name })
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [dataModel, setDataModel] = useState()
    const ref = useRef()
    const dialogRef = useRef()
    const [onEditId, setOnEditId] = useState()

    const getData = async () => {
        const res = await _settingDataController.getListSimple({ page: 1, size: 20, query: `@TbName:{${item.Name}}`, sortby: { BY: "DateCreated" } })
        if (res.code === 200) {
            setData({
                data: res.data.map(e => {
                    return { ...e, Props: typeof e.Props === "string" ? JSON.parse(e.Props) : e.Props }
                }),
                totalCount: res.totalCount
            })
        }
    }

    const showDrawerCard = (item) => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', right: 0, bottom: 0, height: `calc(100% - 9rem)`, maxHeight: '100%', borderRadius: 0, width: 'calc(100vw - min(max(24%, 36rem), 48rem))' },
            content: <DrawerSettingCard
                ref={ref}
                pid={pid}
                cardItem={item}
                dataModel={dataModel}
                onSubmit={getData}
            />
        })
    }

    useEffect(() => {
        if (dataModel) getData()
    }, [dataModel])

    useEffect(() => {
        _dataController.getListSimple({ page: 1, size: 1 }).then(res => {
            if (res.code === 200) setDataModel(res.data[0])
        })
    }, [])

    return <div className='row' style={{ flex: 1 }}>
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='col' style={{ width: "24%", minWidth: "36rem", maxWidth: "48rem", borderRight: "var(--neutral-border)", backgroundColor: "var(--neutral-background)", height: "100%" }}>
            <div className='row' style={{ padding: '0 1.6rem', height: '4.8rem', gap: '1.6rem' }}>
                <TextField
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: "1.2rem", color: "var(--neutral-color-subtitle)" }} />}
                    placeholder='Search'
                    style={{ height: '3.2rem', padding: '0 1.2rem', borderRadius: '0.4rem', backgroundColor: "#fff", flex: 1 }}
                />
                <button type='button' className='row'><OutlineAppSetupPreferences size={'2.4rem'} /></button>
            </div>
            <div className='row' style={{ padding: "1.6rem 2.4rem", backgroundColor: "var(--neutral-background-selected)", gap: "0.8rem" }}>
                <Text className='label-3' style={{ flex: 1 }}>All card</Text>
                <Text className='body-3'>4</Text>
            </div>
            <div className='col' style={{ flex: 1, overflow: "hidden auto", padding: '1.6rem 2.4rem', gap: '1.6rem' }}>
                {Object.keys(CardType).map((k) => {
                    return <div key={k} className={`col ${styles['demo-admin-assets']}`} draggable onDragStart={(ev) => { ev.dataTransfer.setData("card-type", CardType[k]) }}>
                        <img src={require(`../../../assets/card/${CardType[k].replaceAll(" ", "-").toLowerCase()}.png`)} alt='' height={'100%'} />
                        <Text className='heading-8'>{CardType[k]}</Text>
                    </div>
                })}
            </div>
        </div>
        <div className={`row ${styles['list-card-container']}`} onDragOver={(ev) => { ev.preventDefault() }} onDrop={async (ev) => {
            ev.preventDefault();
            const _type = ev.dataTransfer.getData("card-type")
            const newCardRes = await _settingDataController.action("add", {
                data: [{
                    Id: randomGID(),
                    Name: _type,
                    TbName: item.Name,
                    DateCreated: (new Date()).getTime(),
                    Description: "default description...",
                    Type: _type,
                }]
            })
            if (newCardRes.code !== 200) return ToastMessage.errors(newCardRes.message)
            getData()
        }}>
            {data.data.map((e) => {
                return <div key={e.Id} className={`col col12 col8-xxl`} style={{ gap: "0.4rem", '--gutter': "2.4rem" }}>
                    <div onDoubleClick={() => { setOnEditId(e.Id) }}>
                        <TextField
                            autoFocus
                            defaultValue={e.Name}
                            className={`label-4 ${styles["input-card-name"]}`}
                            disabled={onEditId !== e.Id}
                            style={{ padding: '0.4rem' }}
                            onComplete={(ev) => ev.target.blur()}
                            onBlur={async (ev) => {
                                if (onEditId === e.Id) {
                                    const res = await _settingDataController.action("edit", {
                                        data: [{
                                            ...e,
                                            Name: ev.target.value,
                                            Props: typeof e.Props === "string" ? e.Props : JSON.stringify(e.Props)
                                        }]
                                    })
                                    if (res.code !== 200) return ToastMessage.errors(res.message)
                                    setData({ ...data, data: data.data.map(d => d.Id === e.Id ? { ...d, Name: ev.target.value } : d) })
                                    setOnEditId(undefined)
                                }
                            }}
                        />
                    </div>
                    <div className={`col ${styles["demo-container"]}`}>
                        <CaptureElement className='col demo-card-container' style={{ width: "100%", height: "100%", flex: 1, padding: "1.6rem" }}>
                            <RenderCardNewsByType
                                pid={pid}
                                style={{ width: e.Type.includes("horizontal") ? '80rem' : '42.4rem', height: '100%', flex: 1, padding: '2.4rem', backgroundColor: "var(--neutral-absolute-background-color)" }}
                                cardItem={e}
                                data={dataModel}
                            />
                        </CaptureElement>
                        <div className='row'>
                            <button type='button' className='row icon-button40' onClick={() => { showDrawerCard(e) }}><OutlineEdit size={'2.8rem'} color={"#fff"} /></button>
                            <button type='button' className='row icon-button40' onClick={() => {
                                showDialog({
                                    ref: dialogRef,
                                    alignment: DialogAlignment.center,
                                    status: ComponentStatus.WARNING,
                                    title: "Confirm delete",
                                    content: "Are you sure you want to delete this card",
                                    cancelTitle: "Cancel",
                                    submitTitle: "Delete",
                                    onSubmit: () => {
                                        _settingDataController.action("delete", { ids: [e.Id] }).then(res => {
                                            if (res.code === 200) getData()
                                        })
                                    },
                                })
                            }}
                            >
                                <OutlineTrashCan size={'2.8rem'} color={"#fff"} /></button>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div >
}

const DrawerSettingCard = forwardRef(function DrawerSettingCard(data, ref) {
    const _settingDataController = new SettingDataController({ pid: data.pid, setting: "card" })
    const _relController = new TableController(data.pid, "rel")
    const methods = useForm({ shouldFocusError: false })
    const methodProps = useForm({ shouldFocusError: false })
    const dialogRef = useRef()
    const [pkRels, setPkRels] = useState([])
    const [fkRels, setFkRels] = useState([])

    const _onSubmit = (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: 'Confirm save',
            content: "Every changes will be applied on this card",
            submitTitle: "Submit",
            cancelTitle: "Cancel",
            onSubmit: async () => {
                debugger
                let _cardData = { ...ev, Props: methodProps.getValues() }
                _cardData.Props = JSON.stringify(_cardData.Props)
                const res = await _settingDataController.action("edit", { data: [_cardData] })
                if (res.code !== 200) return ToastMessage.errors(res.message)
                data.onSubmit()
                closePopup(ref)
            }
        })
    }

    const renderSettingCard = () => {
        if (data.cardItem.Type.includes("Card-news")) {
            return <RenderSettingCardNews
                pid={data.pid}
                cardItem={data.cardItem}
                dataModel={data.dataModel}
                methodProps={methodProps}
                fkRels={fkRels}
                pkRels={pkRels}
            />
        } else if (data.cardItem.Type.includes("Card-product")) {
            return <RenderCardNewsByType
                pid={data.pid}
                style={{ width: '100%', padding: '2.4rem', border: 'var(--neutral-border)' }}
                cardItem={data.cardItem}
                data={{
                    ...data.dataModel,
                    Options: [
                        {
                            Id: "1",
                            children: <button type='button' key={"default"} className='col' style={{ border: 'var(--neutral-border)', borderStyle: 'dashed', padding: "1.6rem", borderRadius: "0.8rem" }}>
                                <Text className='button-text-2'>Select Options data</Text>
                            </button>
                        }
                    ],
                }}
            />
        }
    }

    useEffect(() => {
        Object.keys(data.cardItem).forEach((k) => {
            if (k === "Props") {
                const _props = typeof data.cardItem[k] === "string" ? JSON.parse(data.cardItem[k]) : (data.cardItem[k] ?? {})
                Object.keys(_props).forEach(pk => {
                    methodProps.setValue(pk, _props[pk])
                })
            } else {
                methods.setValue(k, data.cardItem[k])
            }
        })
        _relController.getListSimple({ page: 1, size: 100, query: `@TablePK:{${data.cardItem.TbName}}` }).then((res) => {
            if (res.code === 200) setFkRels(res.data)
        })
        _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${data.cardItem.TbName}}` }).then((res) => {
            if (res.code === 200) setPkRels(res.data)
        })
    }, [])

    return <div className={`col ${styles['setting-form-item-container']}`}>
        <Dialog ref={dialogRef} />
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <Text className='heading-7' style={{ flex: 1 }}>{data.cardItem.Name}</Text>
            <button type='button' className='row icon-button28' onClick={() => { closePopup(ref) }}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
        <div className='row' style={{ padding: "1.6rem 2.4rem", alignItems: 'start', height: "100%", flex: 1 }}>
            <div className={`col col16 ${styles['overview-card-container']}`}>
                <Text className='heading-8'>Overview</Text>
                {renderSettingCard()}
            </div>
            <div className={`col col8 ${styles['infor-container']}`}>
                <Text className='heading-8'>Setting</Text>
                <TextFieldForm
                    required
                    name='Name'
                    register={methods.register}
                    errors={methods.formState.errors}
                    label='Form name'
                />
                <TextAreaForm
                    name='Description'
                    register={methods.register}
                    errors={methods.formState.errors}
                    label='Form description'
                />
            </div>
        </div>
        <div className={`row`} style={{ padding: "1.6rem 2.4rem", borderTop: "var(--neutral-border)", justifyContent: "end", gap: "0.8rem" }}>
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "#fff", color: "var(--neutral-color-subtitle)" }}
                onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Confirm cancel',
                        content: "Every changes will be unsaved",
                        submitTitle: "Submit",
                        cancelTitle: "Cancel",
                        onSubmit: () => { closePopup(ref) }
                    })
                }}
            />
            <Button
                label="Save"
                className="button-primary"
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(_onSubmit)}
            />
        </div>
    </div>
})

const RenderSettingCardNews = ({ pid, cardItem, dataModel, pkRels = [], fkRels = [], methodProps }) => {
    const methods = useForm({ shouldFocusError: false })
    const ref = useRef()

    useEffect(() => {
        const _rId = methodProps.getValues("ListItem")
        if (_rId && pkRels.length) {
            const _relItem = pkRels.find(e => e.Id === _rId)
            if (!_relItem) return undefined
            const _tbPKController = new DataController({ pid: pid, module: _relItem.TablePK })
            _tbPKController.getByListId(dataModel[_relItem.Column]?.split(",") ?? []).then((res) => {
                if (res.code === 200) methods.setValue("ListItem", res.data)
            })
        } else methods.setValue("ListItem", undefined)
    }, [methodProps.watch("ListItem"), pkRels.length])

    useEffect(() => {
        watchFkProps("Statistics1")
    }, [methodProps.watch("Statistics1")])

    useEffect(() => {
        watchFkProps("Statistics2")
    }, [methodProps.watch("Statistics2")])

    const watchFkProps = (propName) => {
        const _tmpProps = methodProps.getValues(propName)
        if (_tmpProps) {
            const _relItem = fkRels.find(e => e.Id === _tmpProps.RelativeId)
            if (!_relItem) {
                methods.setValue(propName, undefined)
                methodProps.setValue(propName, undefined)
                return
            }
            //
            const _tbFKController = new DataController({ pid: pid, module: _relItem.TableFK })
            _tbFKController.group({
                searchRaw: `@${_relItem.Column}:{${dataModel.Id}*}`,
                reducers: `GROUPBY 1 @${_relItem.Column} REDUCE ${_tmpProps.Reducer} ${_tmpProps.ReducerBy ? `1 @${_tmpProps.ReducerBy}` : 0} AS _value`,
            }).then((res) => {
                if (res.code === 200) {
                    const _value = res.data.length ? res.data.find((e) => e[_relItem.Column].includes(dataModel.Id)) : { "_value": 0 }
                    methods.setValue(propName, { Id: _value.Id, Name: _tmpProps.Name, Value: _value['_value'] })
                }
            })
        } else methods.setValue(propName, undefined)
    }

    const showPopupSettingFKProps = ({ relItem, onSubmit, onCancel }) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            content: <PopupEditCardProps
                ref={ref}
                pid={pid}
                relItem={relItem}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />,
        })
    }

    return <>
        <Popup ref={ref} />
        {cardItem && dataModel && <RenderCardNewsByType
            pid={pid}
            style={{ width: '100%', padding: '2.4rem', border: 'var(--neutral-border)' }}
            cardItem={{ ...cardItem }}
            data={{
                ...dataModel,
                ListItem: [
                    {
                        Id: "1",
                        children: methods.watch("ListItem") ? <div key={"demo-values"} className={`row ${styles['button-select-relative-data']}`}>
                            <div className='col' style={{ flex: 1 }}>
                                {methods.watch("ListItem").map(e => <div key={e.Id} className="row" style={{ gap: '0.8rem' }}>
                                    {e.Icon}
                                    <Text className="label-4" style={{ flex: 1, textAlign: 'start' }} maxLine={1}>{e.Name}</Text>
                                </div>)}
                            </div>
                            <button type='button' className='row icon-button24' onClick={() => { methodProps.setValue("ListItem", undefined) }}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                        </div> : <div key={"default"} className={`col ${styles['button-select-relative-data']}`}>
                            <Text className='button-text-2'>Select ListItem data</Text>
                            <Select1
                                readOnly
                                options={pkRels.map(e => { return { id: e.Id, name: e.TablePK } })}
                                placeholder="Select tablePK"
                                value={methodProps.watch("ListItem")}
                                onChange={(v) => { methodProps.setValue("ListItem", v.id) }}
                            />
                        </div>
                    }
                ],
                Statistics1: {
                    children: methods.watch("Statistics1") ? <div className={`row ${styles['button-select-relative-data']}`} style={{ flex: 1, height: '10.4rem' }}>
                        <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                            <Text className="heading-6" maxLine={1} style={{ width: '100%' }}>{methods.watch("Statistics1").Value}</Text>
                            <Text className="subtitle-4" maxLine={1} style={{ width: '100%' }}>{methods.watch("Statistics1").Name}</Text>
                        </div>
                        <button type='button' className='row icon-button24' onClick={() => { methodProps.setValue("Statistics1", undefined) }}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                    </div> : <div className={`col ${styles['button-select-relative-data']}`} style={{ flex: 1 }}>
                        <Select1Form
                            control={methodProps.control}
                            errors={methodProps.formState.errors}
                            name='Statistics1'
                            label='Select Statistics 1 data'
                            readOnly
                            options={fkRels.map(e => { return { id: e.Id, name: e.TableFK, ...e } })}
                            placeholder="Select tableFK"
                            onChange={(v) => {
                                showPopupSettingFKProps({
                                    relItem: v,
                                    onCancel: () => { methodProps.setValue("Statistics1", undefined) },
                                    onSubmit: (ev) => { methodProps.setValue("Statistics1", ev) }
                                })
                            }}
                        />
                    </div>
                },
                Statistics2: {
                    children: methods.watch("Statistics2") ? <div className={`row ${styles['button-select-relative-data']}`} style={{ flex: 1, height: '10.4rem' }}>
                        <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                            <Text className="heading-6" maxLine={1} style={{ width: '100%' }}>{methods.watch("Statistics2").Value}</Text>
                            <Text className="subtitle-4" maxLine={1} style={{ width: '100%' }}>{methods.watch("Statistics2").Name}</Text>
                        </div>
                        <button type='button' className='row icon-button24' onClick={() => { methodProps.setValue("Statistics2", undefined) }}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                    </div> : <div className={`col ${styles['button-select-relative-data']}`} style={{ flex: 1 }}>
                        <Select1Form
                            control={methodProps.control}
                            errors={methodProps.formState.errors}
                            name='Statistics2'
                            label='Select Statistics 2 data'
                            readOnly
                            options={fkRels.map(e => { return { id: e.Id, name: e.TableFK, ...e } })}
                            placeholder="Select tableFK"
                            onChange={(v) => {
                                showPopupSettingFKProps({
                                    relItem: v,
                                    onCancel: () => { methodProps.setValue("Statistics2", undefined) },
                                    onSubmit: (ev) => { methodProps.setValue("Statistics2", ev) }
                                })
                            }}
                        />
                    </div>
                },
            }}
        />}
    </>
}

const PopupEditCardProps = forwardRef(function PopupEditCardProps({ pid, relItem, onCancel, onSubmit }, ref) {
    const methods = useForm({ shouldFocusError: false })
    const _colController = new TableController(pid, "column")
    const [cols, setCols] = useState([])

    const _onSubmit = (ev) => {
        delete ev.GroupBy
        onSubmit(ev)
        closePopup(ref)
    }

    useEffect(() => {
        if (relItem) {
            methods.setValue("RelativeId", relItem.Id)
            _colController.getListSimple(
                {
                    page: 1,
                    size: 50,
                    query: `@TableName:{${relItem.TableFK}}`,
                    returns: ['Id', 'Name', 'DataType']
                }
            ).then((res) => {
                if (res.code === 200) setCols(res.data)
                else ToastMessage.errors(res.message)
            })
        }
        return () => { if (!methods.getValues("Name")?.length) onCancel() }
    }, [relItem])

    return <div className='col' style={{ backgroundColor: "var(--neutral-absolute-background-color)", borderRadius: "0.8rem" }}>
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <Text className='heading-7' style={{ flex: 1 }}>Caculate statistics</Text>
            <button type='button' className='row icon-button28' onClick={() => { closePopup(ref) }}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
        <div className='col' style={{ padding: "1.6rem 2.4rem", gap: "1.6rem" }}>
            <TextFieldForm
                required
                label="Title"
                name="Name"
                style={{ gap: '0.8rem' }}
                errors={methods.formState.errors}
                register={methods.register}
            />
            <div className="row" style={{ gap: '1.6rem', paddingBottom: '2rem' }}>
                <Select1Form
                    disabled
                    label="GroupBy"
                    name="GroupBy"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={[{ id: relItem.Column, name: relItem.Column }]}
                />
                <Select1Form
                    required
                    label="Reducer"
                    name="Reducer"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={EventStep.REDUCE.EXPRESSION.map((el) => {
                        return {
                            id: el,
                            name: el
                        }
                    })}
                />
                {methods.watch("Reducer") && methods.watch("Reducer") !== Reducers.COUNT ? <Select1Form
                    required
                    label="By"
                    name="ReducerBy"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={cols.map((el) => {
                        return {
                            id: el.Name,
                            name: el.Name,
                            DataType: el.DataType
                        }
                    })}
                    onChange={(v) => { methods.setValue("DataType", v.DataType) }}
                /> : <div style={{ flex: 1 }} />}
            </div>
        </div>
        <div className={`row popup-footer`}>
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "var(--neutral-background)", color: "var(--neutral-color-subtitle)" }}
                onClick={() => { closePopup(ref) }}
            />
            <Button
                label="Save"
                className='button-primary'
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(_onSubmit)}
            />
        </div>
    </div>
})