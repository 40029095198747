import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Select1, Text, TextField } from "wini-web-components";

export default function DimensionsHeader() {
    const methods = useForm({ shouldFocusError: false })
    const dimentionList = [{ id: "responsive", value: "responsive" }, { id: "320", value: "Mobile S" }, { id: "375", value: "Mobile M" }, { id: "425", value: "Mobile L" }, { id: "768", value: "Tablet" }, { id: "1024", value: "Laptop" }, { id: "1440", value: "Laptop L" }];
    const _iframe = document.getElementById("iframe-render-page-view")

    useEffect(() => {
        if (!_iframe) return
        setTimeout(() => {
            const resizeObserver = new ResizeObserver((entries) => {
                methods.setValue("width", _iframe.offsetWidth)
                methods.setValue("height-placeholder", _iframe.offsetHeight)
                methods.setValue("dimention-options", methods.getValues("dimention-options") ?? "responsive")
                console.log("Size changed");
            });
            resizeObserver.observe(_iframe);
            return () => resizeObserver.unobserve(_iframe);
        }, 100)
    }, [_iframe])
    return <div className="row setting-page-header">
        <div className='row' style={{ flex: 1, justifyContent: 'center', alignItems: 'center', height: "100%", padding: "1rem 0rem" }}>
            <div className='row' style={{ alignItems: "center", justifyContent: "flex-start" }}>
                <div className="row" style={{ gap: 4, justifyContent: "flex-end" }}>
                    <Text className='semibold1'>Dimensions:</Text>
                    <Select1
                        readOnly
                        className="regular1"
                        style={{ width: '120px', height: 20 }}
                        popupClassName="dimention-options"
                        value={methods.watch("dimention-options")}
                        options={dimentionList.map(e => {
                            return {
                                id: e.id,
                                name: e.value
                            }
                        })}
                        onChange={(ev) => {
                            const _tmp = methods.getValues('dimention-options')
                            if (_tmp !== ev.id) {
                                methods.setValue("dimention-options", ev.id)
                                _iframe.style.width = ev.id + 'px'
                            }
                        }}
                    />
                </div>
                <div className="row" style={{ paddingLeft: 20, justifyItems: 'center', gap: 4 }} >
                    <TextField
                        className="regular1"
                        name="width"
                        style={{ width: '16%', height: 20 }}
                        onComplete={(ev) => ev.target.blur()}
                        register={methods.register('width', {
                            onBlur: (ev) => {
                                _iframe.style.width = ev.target.value.trim() + 'px'
                                if (dimentionList.find(e => e.id !== ev.target.value.trim())) methods.setValue("dimention-options", "responsive")
                            }
                        })}
                        type="number"
                    />
                    <Text className="semibold3" style={{ color: '#999999' }}>x</Text>
                    <TextField
                        className="regular1"
                        name="height"
                        onComplete={(ev) => ev.target.blur()}
                        placeholder={`${methods.getValues("height-placeholder") ?? ''}`}
                        style={{ width: '16%', height: 20 }}
                        register={methods.register('height', {
                            onBlur: (ev) => {
                                _iframe.style.height = ev.target.value.trim() + 'px'
                            }
                        })}
                        type="number"

                    />
                    <Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('height-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }, { id: 'fit', name: 'fit' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('height-unit')
                            if (_tmp !== v.id) {
                                if (v.id === 'fit') {
                                    methods.setValue('height-unit', 'fit')
                                } else {
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
}