import styles from './container.module.css'
import { faBars, faChevronDown, faColumns, faGears, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Controller, useForm } from "react-hook-form"
import { Text, Select1, TextField } from "wini-web-components"
import { OutlineArrowDown, OutlineArrowRight, OutlineArrowTurnLeft, OutlineRadius4Angle, OutlineRadiusAll } from "../../../../assets/icon"
import { useEffect, useState } from "react"
import { PageActions } from "../reducer"
import { useDispatch, useSelector } from "react-redux"
import { onSelectedLayer } from "../pageView"
import { Ultis } from '../../../../Utils'
import { ColorPicker, getSizeValue, listBreakpoint, tilteBreakpoints } from './functions/funtions'
import { regexResponsiveClass } from '../../config'
import { CheckboxForm } from '../../../../project-component/component-form'
import { DesignTokenType } from '../../../home/designToken/da'

export default function SettingContainer({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const layers = useSelector((store) => store.page.data?.layers)
    const dispatch = useDispatch()
    const [radiusAll, setRadiusAll] = useState(true)
    const [paddingAll, setPaddingAll] = useState(true)
    const [breakPoint, setBreakPoint] = useState(false)
    const [position, setPosition] = useState(false)
    const _iframe = document.getElementById("iframe-render-page-view")
    const designToken = useSelector((store) => store.designToken.data)

    const tiltePositionss = ['static', 'relative', 'fixed', 'absolute', 'sticky'];
    const [widthUnits, setWidthUnits] = useState([{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }, { id: 'fit', name: 'fit' }, { id: 'flex', name: 'flex' }]);

    const updateStyle = (style) => {
        let _newSettingStyle = { ...selectedLayer.Setting.style, ...style }
        for (const prop in _newSettingStyle) {
            if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
        }
        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle } }])
    }

    useEffect(() => {
        if (!_iframe || !selectedLayer) return
        setTimeout(() => {
            const _newElement = _iframe.contentWindow.document.getElementById(selectedLayer.Id)
            if (_newElement) {
                const _newStyle = window.getComputedStyle(_newElement)
                const _rootFz = parseFloat(window.getComputedStyle(_iframe.contentWindow.document.documentElement)['font-size'].replace("px", ""))
                const _direction = _newStyle.flexDirection
                methods.setValue('flex-direction', _direction)
                if (_direction != "row") {
                    setWidthUnits(widthUnits.filter(e => e.id !== "flex"))
                } else {
                    if (widthUnits.filter(e => e.id === "flex").length === 0) {
                        setWidthUnits([...widthUnits, { id: 'flex', name: 'flex' }])
                    }
                }
                methods.setValue('flex-wrap', _newStyle.flexWrap)
                methods.setValue('flex-alignment', (_direction === "column" ? (_newStyle.justifyContent + "-" + _newStyle.alignItems) : (_newStyle.alignItems + "-" + _newStyle.justifyContent)).replaceAll("normal", "start"))
                if (selectedLayer.Setting.style?.backgroundColor.startsWith("var")) {
                    methods.setValue('background-color-value', _newStyle.backgroundColor)
                    methods.setValue('background-color-id', selectedLayer.Setting.style?.backgroundColor.replace("var(--", "").replace(")", ""))
                }
                else if (selectedLayer.Setting.style?.backgroundColor) {
                    const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                    methods.setValue('background-color-value', _bgColor.substring(0, 7))
                    methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                    methods.setValue('opacity', parseFloat(_newStyle.opacity))
                } else {
                    methods.setValue('background-color-value', undefined)
                }
                if (selectedLayer.Setting.style?.position) {
                    if (selectedLayer.Setting.style?.position !== "static") {
                        if (['top', 'right', 'bottom', 'left'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                            if (selectedLayer.Setting.style?.top?.includes("px")) {
                                var _top = _newStyle['top'].replace("px", "")
                                var _topUnit = 'px'
                            } else if (selectedLayer.Setting.style?.top?.includes("%")) {
                                _top = parseFloat(_newStyle['top'].replace("%", ""))
                                _topUnit = '%'
                            } else {
                                _top = (parseFloat(_newStyle['top'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _topUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.right?.includes("px")) {
                                var _right = _newStyle['right']?.replace("px", "")
                                var _rightUnit = 'px'
                            } else if (selectedLayer.Setting.style?.right?.includes("%")) {
                                _right = parseFloat(_newStyle['right']?.replace("%", ""))
                                _rightUnit = '%'
                            } else {
                                _right = (parseFloat(_newStyle['right']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _rightUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.bottom?.includes("px")) {
                                var _bottom = _newStyle['bottom']?.replace("px", "")
                                var _bottomUnit = 'px'
                            } else if (selectedLayer.Setting.style?.bottom?.includes("%")) {
                                _bottom = parseFloat(_newStyle['bottom']?.replace("%", ""))
                                _bottomUnit = '%'
                            } else {
                                _bottom = (parseFloat(_newStyle['bottom']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _bottomUnit = "rem"
                            }
                            if (selectedLayer.Setting.style?.left?.includes("px")) {
                                var _left = _newStyle['left']?.replace("px", "")
                                var _leftUnit = 'px'
                            } else if (selectedLayer.Setting.style?.left?.includes("%")) {
                                _left = parseFloat(_newStyle['left']?.replace("%", ""))
                                _leftUnit = '%'
                            } else {
                                _left = (parseFloat(_newStyle['left']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                                _leftUnit = "rem"
                            }
                            methods.setValue('position-top', _top)
                            methods.setValue('position-top-unit', _topUnit)
                            methods.setValue('position-right', _right)
                            methods.setValue('position-right-unit', _rightUnit)
                            methods.setValue('position-bottom', _bottom)
                            methods.setValue('position-bottom-unit', _bottomUnit)
                            methods.setValue('position-left', _left)
                            methods.setValue('position-left-unit', _leftUnit)
                        }
                        methods.setValue('position-style', selectedLayer.Setting.style.position)
                        setPosition(true)
                    } else {
                        methods.setValue('position-style', "static")
                        methods.setValue('position-top-unit', "rem")
                        methods.setValue('position-right-unit', "rem")
                        methods.setValue('position-bottom-unit', "rem")
                        methods.setValue('position-left-unit', "rem")
                        methods.setValue('position-top', undefined)
                        methods.setValue('position-right', undefined)
                        methods.setValue('position-bottom', undefined)
                        methods.setValue('position-left', undefined)
                        setPosition(false)
                    }

                } else {
                    methods.setValue('position-style', "static")
                    methods.setValue('position-top-unit', "rem")
                    methods.setValue('position-right-unit', "rem")
                    methods.setValue('position-bottom-unit', "rem")
                    methods.setValue('position-left-unit', "rem")
                    methods.setValue('position-top', undefined)
                    methods.setValue('position-right', undefined)
                    methods.setValue('position-bottom', undefined)
                    methods.setValue('position-left', undefined)

                    setPosition(false)
                }

                if (selectedLayer.ParentId) {
                    const _clN = [..._newElement.classList].find(e => regexResponsiveClass.test(e))

                    if ([..._newElement.classList].some(e => regexResponsiveClass.test(e))) {
                        const _brp = [..._newElement.classList].map(item => getSizeValue(item)).filter(Boolean);
                        if (_brp) {
                            setBreakPoint(true)
                            _brp.forEach(e => {
                                methods.setValue(`${e.brp}-brp`, e.value)
                            })
                        } else {
                            setBreakPoint(false)
                        }
                    } else {
                        setBreakPoint(false)
                        tilteBreakpoints.forEach(e => {
                            methods.setValue(`${e}-brp`, undefined)
                        })
                    }
                    if (_clN) {
                        methods.setValue('select-col', _clN)
                        methods.setValue('width-unit', "flex")
                    } else if (selectedLayer.Setting.style?.width?.includes("px")) {
                        methods.setValue('width', _newStyle['width'].replace("px", ""))
                        methods.setValue('width-unit', "px")
                    } else if (selectedLayer.Setting.style?.width?.includes("%")) {
                        methods.setValue('width', parseFloat(selectedLayer.Setting.style.width.replace("%", "")))
                        methods.setValue('width-unit', "%")
                    } else {
                        methods.setValue('width', (parseFloat(_newStyle['width'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                        methods.setValue('width-unit', "rem")
                    }

                    if (selectedLayer.Setting.style?.height?.includes("px")) {
                        methods.setValue('height', _newStyle['height'].replace("px", ""))
                        methods.setValue('height-unit', "px")
                    } else if (selectedLayer.Setting.style?.height?.includes("%")) {
                        methods.setValue('height', parseFloat(selectedLayer.Setting.style.height.replace("%", "")))
                        methods.setValue('height-unit', "%")
                    } else {
                        methods.setValue('height', (parseFloat(_newStyle['height'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                        methods.setValue('height-unit', "rem")
                    }
                }

                const _isScrollX = _newStyle.overflowX // hidden
                const _isScrollY = _newStyle.overflowY // hidden

                methods.setValue('isScroll', _isScrollY != undefined && _isScrollY != 'hidden' || _isScrollX != undefined && _isScrollX != 'hidden')

                const _borderStyle = _newStyle.borderStyle
                methods.setValue('border-style', _borderStyle)

                const _borderColor = Ultis.rgbToHex(_newStyle.borderColor)
                methods.setValue('border-color', _borderColor.substring(0, 7))
                methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)

                if (selectedLayer.Setting.style.borderWidth?.includes("px")) {
                    methods.setValue('border-width', _newStyle['border-width'].replace("px", ""))
                    methods.setValue('border-unit', "px")
                } else if (selectedLayer.Setting.style.borderWidth?.includes("%")) {
                    methods.setValue('border-width', parseFloat(_newStyle['border-width'].replace("%", "")))
                    methods.setValue('border-unit', "%")
                } else {
                    methods.setValue('border-width', (parseFloat(_newStyle['border-width'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                    methods.setValue('border-unit', "rem")
                }

                if (['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                    if (selectedLayer.Setting.style?.paddingTop?.includes("px")) {
                        var _top = _newStyle['padding-top'].replace("px", "")
                        var _topUnit = 'px'
                    } else if (selectedLayer.Setting.style?.paddingTop?.includes("%")) {
                        _top = parseFloat(_newStyle['padding-top'].replace("%", ""))
                        _topUnit = '%'
                    } else {
                        _top = (parseFloat(_newStyle['padding-top'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _topUnit = "rem"
                    }
                    if (selectedLayer.Setting.style?.paddingRight?.includes("px")) {
                        var _right = _newStyle['padding-right']?.replace("px", "")
                        var _rightUnit = 'px'
                    } else if (selectedLayer.Setting.style?.paddingRight?.includes("%")) {
                        _right = parseFloat(_newStyle['padding-right']?.replace("%", ""))
                        _rightUnit = '%'
                    } else {
                        _right = (parseFloat(_newStyle['padding-right']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _rightUnit = "rem"
                    }
                    if (selectedLayer.Setting.style?.paddingBottom?.includes("px")) {
                        var _bottom = _newStyle['padding-bottom']?.replace("px", "")
                        var _bottomUnit = 'px'
                    } else if (selectedLayer.Setting.style?.paddingBottom?.includes("%")) {
                        _bottom = parseFloat(_newStyle['padding-bottom']?.replace("%", ""))
                        _bottomUnit = '%'
                    } else {
                        _bottom = (parseFloat(_newStyle['padding-bottom']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _bottomUnit = "rem"
                    }
                    if (selectedLayer.Setting.style?.paddingLeft?.includes("px")) {
                        var _left = _newStyle['padding-left']?.replace("px", "")
                        var _leftUnit = 'px'
                    } else if (selectedLayer.Setting.style?.paddingLeft?.includes("%")) {
                        _left = parseFloat(_newStyle['padding-left']?.replace("%", ""))
                        _leftUnit = '%'
                    } else {
                        _left = (parseFloat(_newStyle['padding-left']?.replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _leftUnit = "rem"
                    }
                    methods.setValue('padding-top', _top)
                    methods.setValue('padding-top-unit', _topUnit)
                    methods.setValue('padding-right', _right)
                    methods.setValue('padding-right-unit', _rightUnit)
                    methods.setValue('padding-bottom', _bottom)
                    methods.setValue('padding-bottom-unit', _bottomUnit)
                    methods.setValue('padding-left', _left)
                    methods.setValue('padding-left-unit', _leftUnit)
                    methods.setValue('padding', _top)
                    methods.setValue('padding-unit', _topUnit)
                    if (paddingAll) setPaddingAll(false)
                } else {
                    if (selectedLayer.Setting.style?.padding?.includes("px")) {
                        var _v = _newStyle['padding'].replace("px", "")
                        var _vUnit = 'px'
                    } else if (selectedLayer.Setting.style?.padding?.includes("%")) {
                        _v = parseFloat(_newStyle['padding'].replace("%", ""))
                        _vUnit = '%'
                    } else {
                        _v = (parseFloat(_newStyle['padding'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnit = "rem"
                    }
                    methods.setValue('padding', _v)
                    methods.setValue('padding-unit', _vUnit)
                    methods.setValue('padding-top', _v)
                    methods.setValue('padding-top-unit', _vUnit)
                    methods.setValue('padding-right', _v)
                    methods.setValue('padding-right-unit', _vUnit)
                    methods.setValue('padding-bottom', _v)
                    methods.setValue('padding-bottom-unit', _vUnit)
                    methods.setValue('padding-left', _v)
                    methods.setValue('padding-left-unit', _vUnit)
                    if (!paddingAll) setPaddingAll(true)
                }

                if (['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                    if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("px")) {
                        var _vTL = _newStyle['border-top-left-radius'].replace("px", "")
                        var _vUnitTL = 'px'
                    } else if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("%")) {
                        _vTL = parseFloat(_newStyle['border-top-left-radius'].replace("%", ""))
                        _vUnitTL = '%'
                    } else {
                        _vTL = (parseFloat(_newStyle['border-top-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitTL = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("px")) {
                        var _vTR = _newStyle['border-top-right-radius'].replace("px", "")
                        var _vUnitTR = 'px'
                    } else if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("%")) {
                        _vTR = parseFloat(_newStyle['border-top-right-radius'].replace("%", ""))
                        _vUnitTR = '%'
                    } else {
                        _vTR = (parseFloat(_newStyle['border-top-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitTR = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("px")) {
                        var _vBL = _newStyle['border-bottom-left-radius'].replace("px", "")
                        var _vUnitBL = 'px'
                    } else if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("%")) {
                        _vBL = parseFloat(_newStyle['border-bottom-left-radius'].replace("%", ""))
                        _vUnitBL = '%'
                    } else {
                        _vBL = (parseFloat(_newStyle['border-bottom-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitBL = "rem"
                    }
                    if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("px")) {
                        var _vBR = _newStyle['border-bottom-right-radius'].replace("px", "")
                        var _vUnitBR = 'px'
                    } else if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("%")) {
                        _vBR = parseFloat(_newStyle['border-bottom-right-radius'].replace("%", ""))
                        _vUnitBR = '%'
                    } else {
                        _vBR = (parseFloat(_newStyle['border-bottom-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnitBR = "rem"
                    }
                    methods.setValue('border-top-left-radius', _vTL)
                    methods.setValue('border-top-left-radius-unit', _vUnitTL)
                    methods.setValue('border-top-right-radius', _vTR)
                    methods.setValue('border-top-right-radius-unit', _vUnitTR)
                    methods.setValue('border-bottom-left-radius', _vBL)
                    methods.setValue('border-bottom-left-radius-unit', _vUnitBL)
                    methods.setValue('border-bottom-right-radius', _vBR)
                    methods.setValue('border-bottom-right-radius-unit', _vUnitBR)
                    methods.setValue('border-radius', _vTL)
                    methods.setValue('border-radius-unit', _vUnitTL)
                    if (radiusAll) setRadiusAll(false)
                } else {
                    if (selectedLayer.Setting.style?.borderRadius?.includes("px")) {
                        var _v = _newStyle['border-radius'].replace("px", "")
                        var _vUnit = 'px'
                    } else if (selectedLayer.Setting.style?.borderRadius?.includes("%")) {
                        _v = parseFloat(_newStyle['border-radius'].replace("%", ""))
                        _vUnit = '%'
                    } else {
                        _v = (parseFloat(_newStyle['border-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                        _vUnit = "rem"
                    }
                    methods.setValue('border-radius', _v)
                    methods.setValue('border-radius-unit', _vUnit)
                    methods.setValue('border-top-left-radius', _v)
                    methods.setValue('border-top-left-radius-unit', _vUnit)
                    methods.setValue('border-top-right-radius', _v)
                    methods.setValue('border-top-right-radius-unit', _vUnit)
                    methods.setValue('border-bottom-left-radius', _v)
                    methods.setValue('border-bottom-left-radius-unit', _vUnit)
                    methods.setValue('border-bottom-right-radius', _v)
                    methods.setValue('border-bottom-right-radius-unit', _vUnit)
                    if (!radiusAll) setRadiusAll(true)
                }

                if (_newStyle.flexWrap === "wrap") {
                    const _gapC = _newStyle['column-gap']
                    if (_gapC === "normal" || selectedLayer.Setting.style?.columnGap?.includes("px")) {
                        methods.setValue('column-gap', _gapC === "normal" ? 0 : _gapC.replace("px", ""))
                        methods.setValue('column-gap-unit', "px")
                    } else if (selectedLayer.Setting.style?.columnGap?.includes("%")) {
                        methods.setValue('column-gap', parseFloat(_gapC.replace("%", "")))
                        methods.setValue('column-gap-unit', "%")
                    } else {
                        methods.setValue('column-gap', (parseFloat(_gapC.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                        methods.setValue('column-gap-unit', "rem")
                    }
                    const _gapR = _newStyle['row-gap']
                    if (_gapR === "normal" || selectedLayer.Setting.style?.rowGap?.includes("px")) {
                        methods.setValue('row-gap', _gapR === "normal" ? 0 : _gapR.replace("px", ""))
                        methods.setValue('row-gap-unit', "px")
                    } else if (selectedLayer.Setting.style?.rowGap?.includes("%")) {
                        methods.setValue('row-gap', parseFloat(_gapR.replace("%", "")))
                        methods.setValue('row-gap-unit', "%")
                    } else {
                        methods.setValue('row-gap', (parseFloat(_gapR.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                        methods.setValue('row-gap-unit', "rem")
                    }
                } else {
                    const _gap = _newStyle['gap'].split(" ")[0]
                    if (_gap === "normal" || selectedLayer.Setting.style?.gap?.includes("px")) {
                        methods.setValue('gap', _gap === "normal" ? 0 : _gap.replace("px", ""))
                        methods.setValue('gap-unit', "px")
                    } else if (selectedLayer.Setting.style?.gap?.includes("%")) {
                        methods.setValue('gap', parseFloat(_gap.replace("%", "")))
                        methods.setValue('gap-unit', "%")
                    } else {
                        methods.setValue('gap', (parseFloat(_gap.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                        methods.setValue('gap-unit', "rem")
                    }
                }

            }
            onSelectedLayer(selectedLayer.Id)
        }, 150)
    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faBars} /></button>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Layout</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faChevronDown} /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.8rem', flex: 1, height: '100%' }}>
                    <div className={`row ${styles['flex-direction-options']}`}>
                        <button type="button" className={`row ${methods.watch("flex-direction") === 'column' ? styles.selected : ""}`}
                            onClick={() => {
                                let _st = { ...selectedLayer.Setting.style, overflowY: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowX: undefined }
                                delete _st.flexWrap
                                delete _st.placeContent
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: _st, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'row'), "col"].join(" ")
                                    }
                                }])
                            }}
                        >
                            <OutlineArrowDown color={'#fff'} size={'1.4rem'} />
                        </button>
                        <button type="button" className={`row ${methods.watch("flex-direction") === 'row' && methods.watch("flex-wrap") !== 'wrap' ? styles.selected : ""}`}
                            onClick={() => {
                                let _st = {
                                    ...selectedLayer.Setting.style,
                                    overflowX: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowY: undefined
                                }
                                delete _st.flexWrap
                                delete _st.placeContent
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: _st, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'col'), "row"].join(" "),
                                    }
                                }])
                            }}>
                            <OutlineArrowRight color={'#fff'} size={'1.4rem'} />
                        </button>
                        <button type="button" className={`row ${methods.watch("flex-wrap") === 'wrap' ? styles.selected : ""}`}
                            onClick={() => {
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: {
                                            ...selectedLayer.Setting.style, flexWrap: 'wrap',
                                            overflowY: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowX: undefined
                                        }, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'col'), "row"].join(" "),
                                    }
                                }])
                            }}>
                            <OutlineArrowTurnLeft color={'#fff'} size={'1.4rem'} />
                        </button>
                    </div>
                    {methods.watch("flex-wrap") !== 'wrap' ? <TextField
                        onComplete={(ev) => ev.target.blur()}
                        className="regular1"
                        name="gap"
                        register={methods.register('gap', {
                            onBlur: (ev) => {
                                updateStyle({
                                    gap: ev.target.value.trim() + methods.getValues('gap-unit'),
                                    rowGap: undefined,
                                    columnGap: undefined,
                                })
                            }
                        })}
                        placeholder="gap"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('gap-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('gap-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({
                                        gap: v.id === 'rem' ? `${parseFloat(methods.getValues('gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('gap'))}${v.id}`,
                                        rowGap: undefined,
                                        columnGap: undefined,
                                    })
                                }
                            }}
                        />}
                    /> : null}
                </div>
                <div className={`row ${styles['alignment-options']}`}>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = { justifyContent: 'start', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'start', alignItems: 'center' } : { justifyContent: 'center', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start'
                            updateStyle(_v)
                        }}
                    >
                        <div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'start', alignItems: 'end' } : { justifyContent: 'end', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'center', alignItems: 'start' } : { justifyContent: 'start', alignItems: undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = { justifyContent: 'center', alignItems: methods.getValues("flex-direction") === 'column' ? 'center' : undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'center', alignItems: 'end' } : { justifyContent: 'end', alignItems: undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'end', alignItems: 'start' } : { justifyContent: 'start', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'end', alignItems: 'center' } : { justifyContent: 'center', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = { justifyContent: 'end', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                </div>
                <button type="button" className="row icon-button24"><FontAwesomeIcon icon={faGears} style={{ fontSize: '1.2rem' }} /></button>
            </div>
            {methods.watch("flex-wrap") === "wrap" ? <div className="row" style={{ gap: '0.8rem' }}>
                <TextField
                    onComplete={(ev) => ev.target.blur()}

                    className="regular1"
                    name="column-gap"
                    register={methods.register('column-gap', {
                        onBlur: (ev) => {
                            updateStyle({
                                columnGap: ev.target.value.trim() + methods.getValues('column-gap-unit'),
                                gap: undefined,
                            })
                        }
                    })}
                    placeholder="column-gap"
                    type="number"
                    style={{ flex: 1 }}
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('column-gap-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('column-gap-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    columnGap: v.id === 'rem' ? `${parseFloat(methods.getValues('column-gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('column-gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('column-gap'))}${v.id}`,
                                    gap: undefined,
                                })
                            }
                        }}
                    />}
                />
                <TextField
                    onComplete={(ev) => ev.target.blur()}
                    className="regular1"
                    name="row-gap"
                    register={methods.register('row-gap', {
                        onBlur: (ev) => {
                            updateStyle({
                                rowGap: ev.target.value.trim() + methods.getValues('row-gap-unit'),
                                gap: undefined,
                            })
                        }
                    })}
                    placeholder="row-gap"
                    type="number"
                    style={{ flex: 1 }}
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('row-gap-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('row-gap-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    rowGap: v.id === 'rem' ? `${parseFloat(methods.getValues('row-gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('row-gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('row-gap'))}${v.id}`,
                                    gap: undefined,
                                })
                            }
                        }}
                    />}
                />
                <div style={{ width: '1.6rem' }} />
            </div> : null}
            <div className="col" style={{ gap: '0.4rem', }}>
                <CheckboxForm label={<Text className="semibold1" >IsScroll</Text>} name="isScroll" size={16} control={methods.control} onChange={(v) => {
                    const flexDirection = methods.getValues('flex-direction')
                    const flexWrap = methods.getValues('flex-wrap')

                    if (v) {
                        updateStyle({ overflow: undefined, overflowY: flexDirection === 'column' || flexWrap === 'wrap' ? 'scroll' : undefined, overflowX: flexDirection === 'row' && flexWrap !== 'wrap' ? 'scroll' : undefined })
                    } else {
                        updateStyle({ overflowY: undefined, overflowX: undefined, overflow: undefined })
                    }
                }} />
            </div>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Box Properties</Text>
            <div className='row' style={{ gap: 10 }}>
                <button type="button" className="row icon-button16" onClick={() => { setBreakPoint(!breakPoint) }}><FontAwesomeIcon icon={faColumns} /></button>
                <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faChevronDown} /></button>
            </div>
        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem 0.8rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>

            {selectedLayer.ParentId &&
                <div className="col" style={{ gap: '0.4rem', }}>
                    {/* setBreakPoint layout */}
                    {breakPoint && <div className="col" style={{ gap: '0.8rem', padding: '0.8rem 0.8rem', borderRadius: '0.8rem', border: '1px solid #444444' }}>
                        <Text className="semibold1" style={{}}>Breakpoint Layout</Text>
                        <div className='row' style={{ gap: '0.4rem', flexWrap: 'wrap' }}>
                            {tilteBreakpoints.map((brp, index) => {
                                const _brpCols = ['none', ...listBreakpoint].map(e => e == "none" ? e : `${e}-${brp.toLowerCase()}`)
                                return <div key={index} className="col8 col" style={{ gap: '0.5rem', flex: 1, '--gutter': '0.4rem' }}>
                                    <Text className="semibold1" style={{ color: '#999999' }}>{brp}</Text>
                                    <Select1
                                        readOnly
                                        style={{ flex: 1 }}
                                        className="regular1"
                                        popupClassName={`${brp}-brp`}
                                        placeholder={'none'}
                                        value={methods.watch(`${brp}-brp`)}
                                        options={_brpCols.map(e => {
                                            return {
                                                id: e,
                                                name: e
                                            }
                                        })}
                                        onChange={(ev) => {
                                            if (ev === 'none') {
                                                PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: [...selectedLayer.Setting.className] } }])
                                            } else {
                                                PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: [...(selectedLayer.Setting.className ?? "").split(" ")].filter(cls => _brpCols.every(e => cls !== e)).join(" ") + ` ${ev.id}` } }])
                                            }
                                        }}
                                    />
                                </div>
                            })}
                        </div>
                    </div>}
                    <div className="row" style={{ gap: '0.8rem' }}>
                        <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                            <Text className="semibold1" style={{ color: '#999999' }}>Width</Text>
                            {methods.watch('width-unit') === 'flex' && methods.watch('select-col') != 'fixed' && methods.getValues('flex-direction') == 'row' ? <Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-col"
                                value={methods.watch('select-col')}
                                options={['fixed', ...listBreakpoint].map(e => {
                                    return {
                                        id: e,
                                        name: e
                                    }
                                })}
                                onChange={(ev) => {
                                    const _tmp = methods.getValues('select-col')
                                    const { columnGap, gap } = layers.find(e => e.Id === selectedLayer.ParentId)?.Setting?.style
                                    if (_tmp !== ev.id) {
                                        if (ev.id == 'fixed') {
                                            updateStyle({ width: methods.getValues('width') + methods.getValues('width-unit') })
                                            PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: selectedLayer.Setting.className.split(" ").filter(e => e !== _tmp).join(" "), style: { ...selectedLayer.Setting.style, '--gutter': columnGap ?? gap } } }])
                                        }
                                        else {
                                            PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: selectedLayer.Setting.className.split(" ").map(e => e === _tmp ? ev.id : e).join(" "), style: { ...selectedLayer.Setting.style, '--gutter': columnGap ?? gap } } }])
                                        }
                                    }
                                }}
                            /> :
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}
                                    className="regular1"
                                    name="width"
                                    register={methods.register('width', { onBlur: (ev) => { updateStyle({ width: ev.target.value.trim() + methods.getValues('width-unit') }) } })}
                                    placeholder="width"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('width-unit')}
                                        options={widthUnits}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('width-unit')
                                            const _newElement = _iframe.contentWindow.document.getElementById(selectedLayer?.Id)
                                            const _rootFz = parseFloat(window.getComputedStyle(_iframe.contentWindow.document.documentElement)['font-size'].replace("px", ""))
                                            if (_tmp !== v.id) {
                                                if (v.id === 'flex') {
                                                    PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: [...(selectedLayer.Setting.className ?? "").split(" "), 'remain'].join(" ") } }])
                                                } else if (v.id === 'fit') {
                                                    updateStyle({ width: undefined })
                                                } else {
                                                    switch (v.id) {
                                                        case 'rem':
                                                            updateStyle({ width: `${_newElement.offsetWidth / _rootFz}rem` })
                                                            break;
                                                        case 'px':
                                                            updateStyle({ width: `${_newElement.offsetWidth}px` })
                                                            break;
                                                        case '%':
                                                            updateStyle({ width: `${(_newElement.offsetWidth * 100 / _newElement.parentElement.offsetWidth).toFixed(2)}%` })
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }
                                            }
                                        }}
                                    />}
                                />}
                        </div>
                        <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                            <Text className="semibold1" style={{ color: '#999999' }}>Height</Text>
                            <TextField
                                onComplete={(ev) => ev.target.blur()}

                                className="regular1"
                                name="height"
                                register={methods.register('height', { onBlur: (ev) => { updateStyle({ height: ev.target.value.trim() + methods.getValues('height-unit') }) } })}
                                placeholder="height"
                                type="number"
                                suffix={<Select1
                                    readOnly
                                    className="regular1"
                                    popupClassName="select-unit-options"
                                    value={methods.watch('height-unit')}
                                    options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }, { id: 'fit', name: 'fit' }]}
                                    style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                    onChange={(v) => {
                                        const _tmp = methods.getValues('height-unit')
                                        if (_tmp !== v.id) {
                                            if (v.id === 'fit') {
                                                methods.setValue('height-unit', 'fit')
                                                updateStyle({ height: undefined })
                                            } else {
                                                updateStyle({ height: v.id === 'rem' ? `${parseFloat(methods.getValues('height')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('height')) * 10}${v.id}` : `${parseFloat(methods.getValues('height'))}${v.id}` })
                                            }
                                        }
                                    }}
                                />}
                            />
                        </div>
                    </div>
                </div>
            }
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <Text className="semibold1" style={{ color: '#999999' }}>Position style</Text>
                <Select1
                    readOnly
                    className="regular1"
                    popupClassName="position-style"
                    value={methods.watch('position-style')}
                    options={tiltePositionss.map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(ev) => {
                        const _tmp = methods.getValues('position-style')
                        if (_tmp !== ev.id) {
                            updateStyle({ position: ev.id })
                        }
                    }}
                />
                {
                    position ?
                        <div className="row" style={{ gap: '0.8rem', padding: '0.8rem 0.8rem', borderRadius: '0.8rem', border: '1px solid #444444' }}>
                            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                                <Text className="semibold1" style={{ color: '#999999' }}>Position Top</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}

                                    className="regular1"

                                    name="position-top"
                                    register={methods.register('position-top', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                top: ev.target.value.trim() + methods.getValues('position-top-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="top"
                                    type="number"
                                    suffix={<Controller
                                        name={'position-top-unit'}
                                        control={methods.control}
                                        render={({ field }) => <Select1
                                            readOnly
                                            className="regular1"
                                            popupClassName="select-unit-options"
                                            value={field.value}
                                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                            onChange={(v) => {
                                                const _tmp = methods.getValues('position-top-unit')
                                                if (_tmp !== v.id) {
                                                    updateStyle({
                                                        position: methods.getValues('position-style'),
                                                        top: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-top')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-top')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-top'))}${v.id}`}`
                                                    })
                                                }
                                            }}
                                        />}
                                    />}
                                />
                                <Text className="semibold1" style={{ color: '#999999', paddingTop: '0.4rem' }}>Position Right</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}

                                    className="regular1"
                                    name="position-right"
                                    register={methods.register('position-right', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                right: ev.target.value.trim() + methods.getValues('position-right-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="right"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-right-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-right-unit')
                                            if (_tmp !== v.id) {
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    right: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-right')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-right')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-right'))}${v.id}`}`
                                                })
                                            }
                                        }}
                                    />}
                                />
                            </div>
                            <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                            <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                                <Text className="semibold1" style={{ color: '#999999' }}>Position Bottom</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}

                                    className="regular1"
                                    name="position-bottom"
                                    register={methods.register('position-bottom', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                bottom: ev.target.value.trim() + methods.getValues('position-bottom-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="bottom"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-bottom-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-bottom-unit')
                                            if (_tmp !== v.id)
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    bottom: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-bottom')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-bottom')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-bottom'))}${v.id}`}`
                                                })
                                        }}
                                    />}
                                />
                                <Text className="semibold1" style={{ color: '#999999', paddingTop: '0.4rem' }}>Position Left</Text>
                                <TextField
                                    onComplete={(ev) => ev.target.blur()}

                                    className="regular1"
                                    name="position-left"
                                    register={methods.register('position-left', {
                                        onBlur: (ev) => {
                                            updateStyle({
                                                position: methods.getValues('position-style'),
                                                left: ev.target.value.trim() + methods.getValues('position-left-unit'),
                                            })
                                        }
                                    })}
                                    placeholder="left"
                                    type="number"
                                    suffix={<Select1
                                        readOnly
                                        className="regular1"
                                        popupClassName="select-unit-options"
                                        value={methods.watch('position-left-unit')}
                                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                        onChange={(v) => {
                                            const _tmp = methods.getValues('position-left-unit')
                                            if (_tmp !== v.id)
                                                updateStyle({
                                                    position: methods.getValues('position-style'),
                                                    left: `${v.id === 'rem' ? `${parseFloat(methods.getValues('position-left')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('position-left')) * 10}${v.id}` : `${parseFloat(methods.getValues('position-left'))}${v.id}`}`
                                                })
                                        }}
                                    />}
                                />
                            </div>
                        </div> : <></>
                }
            </div>
            <div className="row" style={{ gap: '0.8rem' }}>
                <Text className="semibold1" style={{ color: '#999999' }}>Padding style</Text>
                <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                    <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: paddingAll ? undefined : "#9c6334" }} onClick={() => setPaddingAll(false)}>
                        <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                    </button>
                    <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: paddingAll ? "#9c6334" : undefined }} onClick={() => setPaddingAll(true)}>
                        <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                    </button>
                </div>
            </div>
            {paddingAll ? <TextField
                onComplete={(ev) => ev.target.blur()}

                className="regular1"
                name="padding"
                register={methods.register('padding', {
                    onBlur: (ev) => {
                        updateStyle({
                            padding: ev.target.value.trim() + methods.getValues('padding-unit'),
                            paddingTop: undefined,
                            paddingRight: undefined,
                            paddingBottom: undefined,
                            paddingLeft: undefined
                        })
                    }
                })}
                placeholder="Padding container"
                type="number"
                suffix={<Select1
                    readOnly
                    className="regular1"
                    popupClassName="select-unit-options"
                    value={methods.watch('padding-unit')}
                    options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                    style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                    onChange={(v) => {
                        const _tmp = methods.getValues('padding-unit')
                        if (_tmp !== v.id) {
                            updateStyle({
                                padding: v.id === 'rem' ? `${parseFloat(methods.getValues('padding')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('padding')) * 10}${v.id}` : `${parseFloat(methods.getValues('padding'))}${v.id}`,
                                paddingTop: undefined,
                                paddingRight: undefined,
                                paddingBottom: undefined,
                                paddingLeft: undefined
                            })
                        }
                    }}
                />}
            /> : <div className="row" style={{ gap: '0.8rem', }}>
                <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Top</Text>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}

                        className="regular1"
                        name="padding-top"
                        register={methods.register('padding-top', {
                            onBlur: (ev) => {
                                updateStyle({
                                    padding: undefined,
                                    paddingTop: ev.target.value.trim() + methods.getValues('padding-top-unit'),
                                })
                            }
                        })}
                        placeholder="top"
                        type="number"
                        suffix={<Controller
                            name={'padding-top-unit'}
                            control={methods.control}
                            render={({ field }) => <Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={field.value}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('padding-top-unit')
                                    if (_tmp !== v.id) {
                                        updateStyle({
                                            padding: undefined,
                                            paddingTop: `${v.id === 'rem' ? `${parseFloat(methods.getValues('padding-top')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('padding-top')) * 10}${v.id}` : `${parseFloat(methods.getValues('padding-top'))}${v.id}`}`
                                        })
                                    }
                                }}
                            />}
                        />}
                    />
                    <Text className="semibold1" style={{ color: '#999999', paddingTop: '0.4rem' }}>Right</Text>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}

                        className="regular1"
                        name="padding-right"
                        register={methods.register('padding-right', {
                            onBlur: (ev) => {
                                updateStyle({
                                    padding: undefined,
                                    paddingRight: ev.target.value.trim() + methods.getValues('padding-right-unit'),
                                })
                            }
                        })}
                        placeholder="right"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('padding-right-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('padding-right-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({
                                        padding: undefined,
                                        paddingRight: `${v.id === 'rem' ? `${parseFloat(methods.getValues('padding-right')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('padding-right')) * 10}${v.id}` : `${parseFloat(methods.getValues('padding-right'))}${v.id}`}`
                                    })
                                }
                            }}
                        />}
                    />
                </div>
                <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Bottom</Text>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}

                        className="regular1"
                        name="padding-bottom"
                        register={methods.register('padding-bottom', {
                            onBlur: (ev) => {
                                updateStyle({
                                    padding: undefined,
                                    paddingBottom: ev.target.value.trim() + methods.getValues('padding-bottom-unit'),
                                })
                            }
                        })}
                        placeholder="bottom"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('padding-bottom-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('padding-bottom-unit')
                                if (_tmp !== v.id)
                                    updateStyle({
                                        padding: undefined,
                                        paddingBottom: `${v.id === 'rem' ? `${parseFloat(methods.getValues('padding-bottom')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('padding-bottom')) * 10}${v.id}` : `${parseFloat(methods.getValues('padding-bottom'))}${v.id}`}`
                                    })
                            }}
                        />}
                    />
                    <Text className="semibold1" style={{ color: '#999999', paddingTop: '0.4rem' }}>Left</Text>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        className="regular1"
                        name="padding-left"
                        register={methods.register('padding-left', {
                            onBlur: (ev) => {
                                updateStyle({
                                    padding: undefined,
                                    paddingLeft: ev.target.value.trim() + methods.getValues('padding-left-unit'),
                                })
                            }
                        })}
                        placeholder="left"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('padding-left-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('padding-left-unit')
                                if (_tmp !== v.id)
                                    updateStyle({
                                        padding: undefined,
                                        paddingLeft: `${v.id === 'rem' ? `${parseFloat(methods.getValues('padding-left')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('padding-left')) * 10}${v.id}` : `${parseFloat(methods.getValues('padding-left'))}${v.id}`}`
                                    })
                            }}
                        />}
                    />
                </div>
            </div>}
            <div className="row" style={{ gap: '0.8rem' }}>
                <Text className="semibold1" style={{ color: '#999999' }}>Border radius</Text>
                <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                    <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? undefined : "#9c6334" }} onClick={() => setRadiusAll(false)}>
                        <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                    </button>
                    <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? "#9c6334" : undefined }} onClick={() => setRadiusAll(true)}>
                        <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                    </button>
                </div>
            </div>
            {radiusAll ? <TextField
                onComplete={(ev) => ev.target.blur()}

                className="regular1"
                name="border-radius"
                register={methods.register('border-radius', {
                    onBlur: (ev) => {
                        updateStyle({
                            borderRadius: ev.target.value.trim() + methods.getValues('border-radius-unit'),
                            borderTopLeftRadius: undefined,
                            borderBottomLeftRadius: undefined,
                            borderTopRightRadius: undefined,
                            borderBottomRightRadius: undefined,
                        })
                    }
                })}
                placeholder="Border radius"
                type="number"
                suffix={<Select1
                    readOnly
                    className="regular1"
                    popupClassName="select-unit-options"
                    value={methods.watch('border-radius-unit')}
                    options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                    style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                    onChange={(v) => {
                        const _tmp = methods.getValues('border-radius-unit')
                        if (_tmp !== v.id) {
                            updateStyle({
                                borderRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-radius'))}${v.id}`,
                                borderTopLeftRadius: undefined,
                                borderBottomLeftRadius: undefined,
                                borderTopRightRadius: undefined,
                                borderBottomRightRadius: undefined,
                            })
                        }
                    }}
                />}
            /> : <div className="row" style={{ padding: '0.4rem 0', gap: '0.8rem', height: '8rem' }}>
                <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}
                        className="regular1"
                        name="border-top-left-radius"
                        register={methods.register('border-top-left-radius', {
                            onBlur: (ev) => {
                                updateStyle({
                                    borderRadius: undefined,
                                    borderTopLeftRadius: ev.target.value.trim() + methods.getValues('border-top-left-radius-unit')
                                })
                            }
                        })}
                        placeholder="TL radius"
                        type="number"
                        suffix={<Controller
                            name={'border-top-left-radius-unit'}
                            control={methods.control}
                            render={({ field }) => <Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={field.value}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-top-left-radius-unit')
                                    if (_tmp !== v.id) {
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderTopLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-left-radius'))}${v.id}`
                                        })
                                    }
                                }}
                            />}
                        />}
                    />
                    <TextField
                        onComplete={(ev) => ev.target.blur()}

                        className="regular1"
                        name="border-bottom-left-radius"
                        register={methods.register('border-bottom-left-radius', {
                            onBlur: (ev) => {
                                updateStyle({
                                    borderRadius: undefined,
                                    borderBottomLeftRadius: ev.target.value.trim() + methods.getValues('border-bottom-left-radius-unit')
                                })
                            }
                        })}
                        placeholder="BL radius"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-bottom-left-radius-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-bottom-left-radius-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-left-radius'))}${v.id}`
                                    })
                                }
                            }}
                        />}
                    />
                </div>
                <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}

                        className="regular1"
                        name="border-top-right-radius"
                        register={methods.register('border-top-right-radius', {
                            onBlur: (ev) => {
                                updateStyle({
                                    borderRadius: undefined,
                                    borderTopRightRadius: ev.target.value.trim() + methods.getValues('border-top-right-radius-unit')
                                })
                            }
                        })}
                        placeholder="TR radius"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-top-right-radius-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-top-right-radius-unit')
                                if (_tmp !== v.id)
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderTopRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-right-radius'))}${v.id}`
                                    })
                            }}
                        />}
                    />
                    <TextField
                        onComplete={(ev) => ev.target.blur()}

                        className="regular1"
                        name="border-bottom-right-radius"
                        register={methods.register('border-bottom-right-radius', {
                            onBlur: (ev) => {
                                updateStyle({
                                    borderRadius: undefined,
                                    borderBottomRightRadius: ev.target.value.trim() + methods.getValues('border-bottom-right-radius-unit')
                                })
                            }
                        })}
                        placeholder="BR radius"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-bottom-right-radius-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-bottom-right-radius-unit')
                                if (_tmp !== v.id)
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-right-radius'))}${v.id}`
                                    })
                            }}
                        />}
                    />
                </div>
            </div>}
            <div className="col" style={{ gap: '0.4rem' }}>
                <Text className="semibold1" style={{ color: '#999999' }}>Border style</Text>
                <Select1
                    readOnly
                    className="regular1"
                    popupClassName="border-style"
                    value={methods.watch('border-style')}
                    options={['none', 'solid', 'dashed', 'dotted'].map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(ev) => {
                        const _tmp = methods.getValues('border-style')
                        if (_tmp !== ev.id) {
                            updateStyle({ borderStyle: ev.id })
                        }
                    }}
                />
            </div>
            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.4rem', width: '30%' }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Border width</Text>
                    <TextField
                        onComplete={(ev) => ev.target.blur()}

                        className="regular1"
                        name="border-width"
                        register={methods.register('border-width', {
                            onBlur: (ev) => {
                                PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, borderWidth: ev.target.value.trim() + methods.getValues('border-unit') } } }])
                            }
                        })}
                        placeholder="border width"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-unit')
                                if (_tmp !== v.id) {
                                    PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, borderWidth: v.id === 'rem' ? `${parseFloat(methods.getValues('border-width')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-width')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-width'))}${v.id}` } } }])
                                }
                            }}
                        />}
                    />
                </div>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Border color</Text>
                    {methods.watch("border-color") ? <div style={{}}>
                        <ColorPicker
                            colorName={'border-color'}
                            colorOpcaityName={'border-color-opacity'}
                            style={{ width: '100%', height: 28, }}
                            register={methods.register}
                            colorValue={methods.watch("border-color") + Ultis.percentToHex(parseFloat(methods.watch('border-color-opacity')))}
                            onChange={({ colorValue, opacity }) => updateStyle({ borderColor: colorValue })}
                        />
                    </div> : null}
                </div>
            </div>

        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Background</Text>
            {methods.watch("background-color-value") ? <button type="button" className="row icon-button16">
                <FontAwesomeIcon icon={faChevronDown} />
            </button> : <button type="button" className="row icon-button16">
                <FontAwesomeIcon icon={faPlus} />
            </button>}
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <Select1
                className="regular1"
                prefix={<div style={{
                    backgroundColor: methods.watch("background-color-value"),
                    width: "1.4rem",
                    height: "1.4rem",
                    borderRadius: '0.2rem',
                    border: "1px solid #444444"
                }} />}
                placeholder={`Background Color`}
                value={methods.watch('background-color-id')}
                options={designToken.map((el) => {
                    return {
                        id: el.Id,
                        name: el.Name,
                        disabled: el.Type === DesignTokenType.group
                    }
                })}
                style={{}}
                onChange={(v) => {
                }}
            />
            {/* {methods.watch("background-color-id") ?
                <div className='row'>

                    
                </div>
                :
                methods.watch("background-color-value") ? <ColorPicker
                    colorName={'background-color-value'}
                    colorOpcaityName={'background-color-opacity'}
                    style={{ width: '100%', height: 28, }}
                    register={methods.register}
                    colorValue={methods.watch("background-color-value") + Ultis.percentToHex(parseFloat(methods.watch('background-color-opacity')))}
                    opacity={methods.watch('opacity')}
                    onChange={({ colorValue, opacity }) => updateStyle({ backgroundColor: colorValue, opacity: opacity })}
                    onDelete={() => updateStyle({ backgroundColor: undefined, opacity: undefined })}
                /> : null} */}
        </div>
    </>
}

