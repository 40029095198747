import { Button, TextField } from 'wini-web-components'
import { FilledAppBell, FilledAppMail, FilledAppMenu, OutlineFlagVN } from '../assets/icon'
import styles from './index.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { CustomerActions } from '../screen/home/customer/reducer'

export default function Header() {
    const user = useSelector((store) => store.customer.data)
    const dispatch = useDispatch()

    useEffect(() => {
        CustomerActions.getInfor(dispatch, "b081a926bb0c4318aebce949de0ce998")
    }, [])

    return <div className={`row ${styles['header']}`}>
        <button type='button' className='row icon-button24'>
            <FilledAppMenu color="#61616b" size={'2rem'} />
        </button>
        <Button
            prefix={<FontAwesomeIcon icon={faPlusCircle} style={{ color: 'var(--primary-main-color)' }} />}
            label='Button'
            style={{ border: 'var(--neutral-border)', backgroundColor: "#fff", width: '9.4rem', color: 'var(--neutral-color-subtitle)', borderRadius: '2.4rem' }}
        />
        <TextField
            prefix={<FontAwesomeIcon icon={faSearch} style={{ color: 'var(--neutral-color-subtitle)', fontSize: '1.3rem' }} />}
            className={`placeholder-2 ${styles['header-search']}`}
            placeholder='Search'
        />
        <div style={{ flex: 1 }} />
        <button type='button' className='row icon-button24'>
            <FilledAppBell color="#61616b" size={'2rem'} />
        </button>
        <button type='button' className='row icon-button24'>
            <FilledAppMail color="#61616b" size={'2rem'} />
        </button>
        <button type='button' className='row icon-button32'>
            <OutlineFlagVN size={'3.2rem'} />
        </button>
        <button type='button' className='row'>
            <img src={user?.AvatarUrl ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU3FcmHH1HtWFPQqC9Z-IK4JdvSWkvswcDfA&s"} alt='' style={{ borderRadius: "50%", width: '3.2rem', height: '3.2rem' }} />
        </button>
    </div>
}