import { useEffect, useState } from "react";
import { FEDataType } from "../../home/table/da";
import RenderChartByType from "./chartByType";
import { checkTimeChartType } from "../../../Utils";

// filterTime: _day, _month
export default function MapGroupData({ listData = [], cardItem, filterTime = "_day" }) {
    const [chartProps, setChartProps] = useState()

    useEffect(() => {
        if (listData.length && cardItem) {
            const _datasets = []
            let _times = []
            listData.forEach((e) => {
                if (e[filterTime] && !_times.includes(e[filterTime])) _times.push(e[filterTime])
                //
                switch (cardItem.DataType) {
                    case FEDataType.BOOLEAN:
                        e[cardItem.Name] = e[cardItem.Name] ? "true" : "false"
                        break;
                    case FEDataType.NUMBER:
                        e[cardItem.Name] = e[cardItem.Name] ? typeof e[cardItem.Name] === 'string' ? parseFloat(e[cardItem.Name]) : e[cardItem.Name] : undefined
                        break;
                    case FEDataType.DATE:
                        e[cardItem.Name] = e[cardItem.Name] ? typeof e[cardItem.Name] === 'string' ? parseFloat(e[cardItem.Name]) : e[cardItem.Name] : undefined
                        break;
                    case FEDataType.DATETIME:
                        e[cardItem.Name] = e[cardItem.Name] ? typeof e[cardItem.Name] === 'string' ? parseFloat(e[cardItem.Name]) : e[cardItem.Name] : undefined
                        break;
                    case FEDataType.MONEY:
                        e[cardItem.Name] = e[cardItem.Name] ? typeof e[cardItem.Name] === 'string' ? parseFloat(e[cardItem.Name]) : e[cardItem.Name] : undefined
                        break;
                    default:
                        break;
                }
            })

            if (checkTimeChartType(cardItem.Type)) {

                _times = _times.sort((a, b) => {
                    const _aSplt = a.split("-").map(e => parseInt(e))
                    const _bSplt = b.split("-").map(e => parseInt(e))
                    if (_aSplt[1]) {
                        const _tmp = _aSplt[1] - _bSplt[1]
                        if (_tmp === 0) return _aSplt[0] - _bSplt[0]
                        else return _tmp
                    } else return _aSplt[0] - _bSplt[0]
                })
                for (const dataItem of (cardItem.Setting.Datasets ?? [])) {
                    const _data = _times.map((m) => listData.find(e => e[cardItem.GroupBy] === dataItem.Id && e[filterTime] === m)?.[cardItem.Name] ?? 0)

                    _datasets.push({
                        type: cardItem.Type,
                        name: dataItem.Label,
                        data: _data,
                        color: dataItem.BackgroundColor,
                    })
                }
                setChartProps({
                    labels: filterTime === "_month" ? _times.map((e) => `Tháng ${parseInt(e)}`) : _times,
                    datasets: _datasets
                })
            } else {
                setChartProps({
                    labels: cardItem.Setting.Datasets.map(e => e.Label),
                    datasets: listData.map(e => e[cardItem.Name]),
                    color: cardItem.Setting.Datasets.map(e => e.BackgroundColor),
                })
            }
        }
    }, [listData, cardItem])

    return <RenderChartByType
        labels={chartProps?.labels}
        datasets={chartProps?.datasets ?? []}
        pieColors={chartProps?.color}
        type={cardItem.Type}
        title={cardItem.Title}
        legendPosition={cardItem.Setting.Legend}
    />
}

