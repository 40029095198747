import styles from './view.module.css'
import { useFieldArray, useForm } from "react-hook-form"
import { randomGID } from "../../../Utils"
import { EventStep, FEDataType } from "./da"
import { TableController } from "./controller"
import { useEffect, useRef, useState } from "react"
import { Popup, showPopup, Text } from "wini-web-components"
import { Select1Form, TextAreaForm, TextFieldForm } from "../../../project-component/component-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretRight, faMinusCircle, faPlusCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import AutoCompleteInput from "../../../project-component/autocomplete-input"
import PopupAddEditRelative from "./createRel"

export default function SettingKeysTab({ pid, item, cols = [], relatives = [], initCols = [], onChange = ({ newItem, newCols, newRels }) => { } }) {
    const methodOptions = useForm({ shouldFocusError: false })
    const methods = useForm({ shouldFocusError: false, defaultValues: { Id: randomGID(), column: [], rel: [], openCol: true, openRel: true } })
    const _tbController = new TableController(pid, "table")
    const _colController = new TableController(pid, "column")
    const columns = useFieldArray({
        control: methods.control,
        name: 'column',
        keyName: undefined
    })
    const rels = useFieldArray({
        control: methods.control,
        name: 'rel',
        keyName: undefined
    })
    const popupRef = useRef()

    useEffect(() => {
        if (item) {
            Object.keys(item).forEach(prop => {
                methods.setValue(prop, item[prop])
            })
        }
    }, [])

    useEffect(() => {
        methods.setValue("column", cols)
        methods.setValue("rel", relatives)
    }, [])

    useEffect(() => {
        if (methods.watch("rel").length)
            rels.fields.forEach((_rel) => {
                _colController.getListSimple({ page: 1, size: 50, query: `@TableName:{${_rel.TablePK}}`, returns: ["Name"] }).then((res) => {
                    methodOptions.setValue(`${_rel.TablePK}_Options`, (res?.data ?? []).map(e => e.Name))
                })
            })
    }, [methods.watch("rel").length])

    useEffect(() => {
        const _tmpCol = methods.getValues("column")
        onChange({ newCols: _tmpCol })
    }, [methods.watch("column")])

    useEffect(() => {
        const _tmpRel = methods.getValues("rel")
        onChange({ newRels: _tmpRel })
    }, [methods.watch("rel")])

    return <>
        <Popup ref={popupRef} />
        <form className="col" style={{ flex: 1, width: '100%', height: '100%', overflow: 'hidden auto', gap: '1.2rem' }}>
            <div className="col" style={{ padding: '1.6rem 2.4rem 0.8rem', gap: '1.2rem' }}>
                {!item ? <TextFieldForm
                    required
                    name={`Name`}
                    register={methods.register}
                    errors={methods.formState.errors}
                    label={'Tên bảng'}
                    style={{ gap: '0.8rem' }}
                    onFocus={() => { methods.clearErrors() }}
                    onBlur={async (ev) => {
                        const _isExisted = await _tbController.getListSimple({ page: 1, size: 1, query: `@Name:{${ev.target.value.trim()}}`, returns: ["Id"] })
                        if (_isExisted.code === 200 && _isExisted.count) {
                            methods.setError('Name', { message: "Table name is existed!" })
                        } else {
                            onChange({ newItem: { ...methods.getValues(), Name: ev.target.value.trim() } })
                        }
                    }}
                /> : null}
                <TextAreaForm
                    required
                    name={`Description`}
                    register={methods.register}
                    errors={methods.formState.errors}
                    label={'Mô tả'}
                    placeholder={'Mô tả ngắn gọn về bảng này...'}
                    style={{ gap: '0.8rem' }}
                    textFieldStyle={{ padding: '1.2rem' }}
                    onBlur={(ev) => { onChange({ newItem: { ...methods.getValues(), Description: ev.target.value.trim() } }) }}
                />
            </div>
            <div className={`col ${styles['setting-key-table']}`}>
                <div className="row" style={{ overflow: 'visible' }}>
                    <div style={{ width: '9.2rem', left: 0 }} />
                    <Text className="semibold2" style={{ width: '12rem', left: '9.2rem' }}>Type</Text>
                    <Text className="semibold2" style={{ flex: 1, textAlign: 'center', minWidth: '16rem', left: '21.2rem' }}>Key</Text>
                    <Text className="semibold2" style={{ flex: 1, textAlign: 'center', minWidth: '20rem' }}>Data type</Text>
                    <div className="row" style={{ flex: 3, justifyContent: 'center', gap: '0.8rem', minWidth: '24rem' }}>
                        <Text className="semibold2" >Caculate</Text>
                        <button type="button" className="row icon-button24"><FontAwesomeIcon icon={faQuestionCircle} /></button>
                    </div>
                    <div style={{ width: '2.8rem' }} />
                </div>
                {columns.fields.map((col, i) => {
                    return <div key={col.Id} className="row" style={{ padding: '1.2rem 0', overflow: 'visible', display: i === 0 || methods.watch('openCol') ? "flex" : "none" }}>
                        {
                            col.Name === 'Id' ?
                                <>
                                    <div className="row" style={{ gap: '0.4rem', width: '9.2rem', left: 0 }}>
                                        <button type="button" className="row icon-button32" onClick={() => { methods.setValue('openCol', !methods.getValues('openCol')) }} >
                                            <FontAwesomeIcon icon={methods.watch('openCol') ? faCaretDown : faCaretRight} style={{ fontSize: '2rem' }} />
                                        </button>
                                        <button type="button" className="row icon-button32" onClick={() => { columns.append({ Id: randomGID(), DataType: FEDataType.STRING }) }} >
                                            <FontAwesomeIcon icon={faPlusCircle} style={{ color: 'var(--primary-main-color)', fontSize: '1.8rem' }} />
                                        </button>
                                    </div>
                                    <Text className="semibold2" style={{ width: '12rem', left: '9.2rem', height: '3.6rem' }}>Basic key</Text>
                                </> :
                                <>
                                    <div style={{ width: '9.2rem', height: '4rem', left: 0 }} />
                                    <div style={{ width: '12rem', left: '9.2rem', height: '4rem' }} />
                                </>
                        }
                        <TextFieldForm
                            required
                            name={`column[${i}].Name`}
                            register={methods.register}
                            errors={methods.formState.errors}
                            style={{ flex: 1, height: '4rem', minWidth: '16rem', left: '21.2rem' }}
                            textFieldStyle={{ height: '4rem', padding: '0.6rem 1.2rem' }}
                            disabled={["Id", "Name", "DateCreated"].includes(col.Name) || initCols.some(id => id === col.Id)}
                            autoFocus={!col.Name}
                            helperText={'Key name không được để trống'}
                            onChange={(ev) => ev.target.value = ev.target.value.trim()}
                            onBlur={(ev) => {
                                if (methods.getValues("column").some((c) => c.Id !== col.Id && c.Name.toLowerCase() === ev.target.value.toLowerCase())) {
                                    methods.setError(`column[${i}].Name`, { message: 'Key name is existed' })
                                } else {
                                    columns.update(i, { ...methods.getValues("column")[i], Name: ev.target.value })
                                }
                            }}
                        />
                        {col.Name === 'DateCreated' ? <Text className="semibold2" style={{ flex: 1, textAlign: 'center', minWidth: '20rem' }}>{FEDataType.DATETIME}</Text> : <Select1Form
                            name={`column[${i}].DataType`}
                            control={methods.control}
                            errors={methods.formState.errors}
                            style={{ flex: 1, height: '4rem', minWidth: '20rem' }}
                            disabled={["Id", "Name", "DateCreated"].includes(col.Name)}
                            options={Object.keys(FEDataType).map((el) => {
                                return { id: FEDataType[el], name: FEDataType[el] }
                            })}
                            onChange={(v) => { columns.update(i, { ...methods.getValues("column")[i], DataType: v.id }) }}
                        />}
                        {
                            ["Id", "Name", "DateCreated"].includes(col.Name) ?
                                <Text className="semibold2" style={{ textAlign: 'center', flex: 3, minWidth: '24rem' }}>{col.Name === 'DateCreated' ? "new Date().now()" : "none"}</Text> :
                                <div className='row' style={{ minWidth: '24rem', padding: '0 1.2rem', flex: 3 }}>
                                    <AutoCompleteCaculate
                                        steps={EventStep.APPLY.EXPRESSION}
                                        variables={columns.fields.map(e => `@${e.Name}`)}
                                        name={`column[${i}].Query`}
                                        register={methods.register}
                                    />
                                </div>
                        }
                        {["Id", "Name", "DateCreated"].includes(col.Name) ? <div style={{ width: '2.8rem' }} /> :
                            <button type="button" className="row icon-button28" onClick={() => { columns.remove(i) }}>
                                <FontAwesomeIcon icon={faMinusCircle} style={{ color: 'var(--error-main-color)' }} />
                            </button>}
                    </div>
                })}
                <div className="col divider" style={{ width: "100%", position: "sticky", left: 0 }} />
                {rels.fields.length ? rels.fields.map((_rel, i) => {
                    const _options = methodOptions.watch(`${_rel.TablePK}_Options`)
                    return <div key={_rel.Id} className="row" style={{ padding: '1.2rem 0', overflow: 'visible', display: i === 0 || methods.watch('openRel') ? "flex" : "none" }}>
                        {
                            i === 0 ?
                                <>
                                    <div className="row" style={{ gap: '0.4rem', width: '9.2rem', left: 0 }}>
                                        <button type="button" className="row icon-button32" onClick={() => { methods.setValue('openRel', !methods.getValues('openRel')) }} >
                                            <FontAwesomeIcon icon={methods.watch('openRel') ? faCaretDown : faCaretRight} style={{ fontSize: '2rem' }} />
                                        </button>
                                        <button type="button" className="row icon-button32" onClick={() => {
                                            const tbNames = methods.watch('rel').map(e => e.TablePK)
                                            if (item?.Name) tbNames.push(item.Name)
                                            showPopup({
                                                ref: popupRef,
                                                heading: <div className='heading-6 popup-header'>Tạo quan hệ mới</div>,
                                                style: { width: '60rem' },
                                                content: <PopupAddEditRelative
                                                    ref={popupRef}
                                                    pid={pid}
                                                    rels={tbNames}
                                                    relItem={{ TableFK: item?.Name, Required: true }}
                                                    onSuccess={(_newRel) => {
                                                        rels.append(_newRel)
                                                        if (!methods.getValues('openRel')) methods.setValue('openRel', true)
                                                    }}
                                                />
                                            })
                                        }} >
                                            <FontAwesomeIcon icon={faPlusCircle} style={{ color: 'var(--primary-main-color)', fontSize: '1.8rem' }} />
                                        </button>
                                    </div>
                                    <Text className="semibold2" style={{ width: '12rem', left: '9.2rem', height: '4rem' }}>Relative key</Text>
                                </> :
                                <>
                                    <div style={{ width: '9.2rem', left: 0, height: '4rem' }} />
                                    <div style={{ width: '12rem', left: '9.2rem', height: '4rem' }} />
                                </>
                        }
                        <TextFieldForm
                            required
                            disabled
                            name={`rel[${i}].Column`}
                            register={methods.register}
                            errors={methods.formState.errors}
                            style={{ flex: 1, height: '4rem', minWidth: '16rem', left: '21.2rem' }}
                            textFieldStyle={{ height: '4rem', padding: '0.6rem 1.2rem' }}
                            helperText={'Key name không được để trống'}
                        />
                        <Text className="semibold2" style={{ flex: 1, textAlign: 'center', minWidth: '20rem' }}>GID</Text>
                        <div className='row' style={{ minWidth: '24rem', padding: '0 1.2rem', flex: 3 }}>
                            <AutoCompleteCaculate
                                steps={[]}
                                variables={_options?.map(prop => `@${prop}`) ?? []}
                                name={`rel[${i}].Query`}
                                register={methods.register}
                            />
                        </div>
                        <button type="button" className="row icon-button28" onClick={() => { rels.remove(i) }}><FontAwesomeIcon icon={faMinusCircle} style={{ color: 'var(--error-main-color)' }} /></button>
                    </div>
                }) : <div className="row">
                    <div className="row" style={{ gap: '0.4rem', width: '9.2rem', left: 0 }}>
                        <div className="row icon-button32" />
                        <button type="button" className="row icon-button32" onClick={() => {
                            const tbNames = methods.watch('rel').map(e => e.TablePK)
                            if (item?.Name) tbNames.push(item.Name)
                            showPopup({
                                ref: popupRef,
                                heading: <div className='heading-6 popup-header'>Tạo quan hệ mới</div>,
                                style: { width: '60rem' },
                                content: <PopupAddEditRelative
                                    ref={popupRef}
                                    pid={pid}
                                    rels={tbNames}
                                    relItem={{ TableFK: item?.Name, Required: true }}
                                    onSuccess={(_newRel) => {
                                        rels.append(_newRel)
                                        if (!methods.getValues('openRel')) methods.setValue('openRel', true)
                                    }}
                                />
                            })
                        }} >
                            <FontAwesomeIcon icon={faPlusCircle} style={{ color: 'var(--primary-main-color)', fontSize: '1.8rem' }} />
                        </button>
                    </div>
                    <Text className="semibold2" style={{ width: '12rem', left: '9.2rem' }}>Relative key</Text>
                </div>}
            </div>
        </form>
    </>
}

const AutoCompleteCaculate = ({ variables = [], register, name, steps, onChangeText, style = {} }) => {
    const [options, setOptions] = useState()

    return <AutoCompleteInput
        name={name}
        register={register}
        style={{ flex: 3, height: '4rem', padding: '0.6rem 1.2rem', ...style }}
        options={options}
        placeholder="Nhập công thức"
        onFocus={(ev) => { ev.stopPropagation() }}
        onChangeText={(ev, typingIndex) => {
            const _split = ev.value.split(" ")
            const typingText = (_split[typingIndex] ?? "").toLowerCase()
            if (typingText.includes("@")) {
                const _filterV = variables.filter(e => e.toLowerCase().includes(ev.value.substring(ev.value.substring(0, ev.selectionStart ?? 0).lastIndexOf("@"), ev.selectionStart ?? 0).toLowerCase()))
                if (_filterV.length) setOptions(_filterV.map(e => e))
                else setOptions(undefined)
            } else {
                const _filterV = ["@", ...steps].filter(e => (e.FUNC ?? e).toLowerCase().includes(typingText))
                if (_filterV.length) setOptions(_filterV.map(e => e.FUNC ?? e))
                else setOptions(undefined)
            }
        }}
    />
}