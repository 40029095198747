import React, { useEffect, useRef } from "react";
import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react";
import { checkTimeChartType, formatNumberConvert, Ultis } from "../../../Utils";

type LineData = {
    type: string;
    name: string;
    data: any[];
    color: string;
};

export default function RenderChartByType(props: { type: any, datasets: LineData[], pieColors?: Array<string>, labels?: Array<string>, legendPosition?: "left" | "top" | "right" | "bottom" | "center" }) {

    const option = {
        legend: {
            left: 'left',
            icon: 'roundRect',
            borderRadius: 8,
        },
        tooltip: {},
        dataset: checkTimeChartType(props.type) ? {
            source: [
                ['0', ...props.datasets.map(item => item.name)],
                ...props.labels?.map((item, index) => [item, ...props.datasets[index]?.data || []]) ?? [],
            ]
        } : undefined,
        yAxis: checkTimeChartType(props.type) ? [{
            type: 'value',
            axisLine: {
                lineStyle: {
                    type: 'dotted'
                }
            },
            axisLabel: {
                formatter: (value: any) => formatNumberConvert(value)
            }
        },] : undefined,
        xAxis: checkTimeChartType(props.type) ? {
            type: 'category',
            data: props.labels ?? []
        } : undefined,
        series: props.datasets.map((item: any) => (
            {
                type: props.type,
                itemStyle: checkTimeChartType(props.type) ? {
                    color: item.color,
                    borderRadius: [2, 2, 0, 0]
                } : undefined,
                data: checkTimeChartType(props.type) ? undefined : props.labels?.map((itemLb, index) => ({
                    value: props.datasets[index], name: itemLb, itemStyle: {
                        color: props.pieColors![index],
                    },
                })),
                radius: '50%',
                barMinWidth: 8,
                barWidth: 8,
                barGap: 0.2,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
            }
        ))
    };
    return <ReactEcharts option={option} style={{ width: '100%', aspectRatio: '2 / 1' }} />;
}