import styles from './view.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, closePopup, Dialog, Pagination, Popup, showPopup, Text, TextField, ToastMessage } from 'wini-web-components'
import { faEllipsisH, faPlus, faSearch, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import { DataController } from '../controller'
import PopupAddEditData from './settingForm'
import PopupFormFilter from './formSearch'
import { TableController } from "../../home/table/controller";
import { getTableConfig } from '../config'
import { FilledAppGridInterface, OutlineAppFolder, OutlineAppMoreV, OutlineAppSettings, OutlineAppSetupPreferences, OutlineAppStar, OutlineArrowDown, OutlineChevronDown, OutlineEdit, OutlineEditorUnGroup, OutlineEnlargeVertical, OutlineMoveLayerLeft, OutlineMoveLayerRight, OutlineViewOff } from '../../../assets/icon'
import { useForm } from 'react-hook-form'
import PopupManageTableFK from './tableFKManger'
import { PopupActions, DrawerSettingTableManager } from './settingTableManager'
import { FEDataType } from '../../home/table/da'
import { useSelector } from 'react-redux'

export default function ModuleListView({ module }) {
    const selectedM = useSelector((store) => store.module.data)
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _colController = new TableController(_pid, "column")
    const _relController = new TableController(_pid, "rel")
    const _dataController = new DataController({ pid: _pid, module: module })
    const ref = useRef()
    const dialogRef = useRef()
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const [data, setData] = useState({ data: [], total: undefined })
    const [cols, setCols] = useState([])
    const [rels, setRels] = useState([])
    const [actions, setActions] = useState([])
    const methods = useForm({ shouldFocusError: false })
    const [selected, setSelected] = useState([])

    const getData = async (page, size) => {
        let _query = []
        for (const [key, value] of searchParams) {
            if (key !== "pid") {
                const _col = cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""))
                switch (_col?.DataType) {
                    case FEDataType.STRING:
                        _query.push(`@${key}:${value}`)
                        break;
                    case FEDataType.BOOLEAN:
                        _query.push(`@${key}:{${value}}`)
                        break;
                    default:
                        if (_col && [FEDataType.DATE, FEDataType.DATETIME, FEDataType.NUMBER, FEDataType.MONEY].includes(_col.DataType)) {
                            if (_query.every(e => !e.startsWith(`@${_col.Name}:`))) _query.push(`@${_col.Name}:[${searchParams.get(`${_col.Name}_min`)} ${searchParams.get(`${_col.Name}_max`)}]`)
                        }
                        break;
                }
            }
        }
        const res = await _dataController.aggregateList({
            page: page ?? 1,
            size: size ?? 10,
            searchRaw: _query.length ? _query.join(" ") : "*"
        })
        if (res.code === 200) {
            setData({ data: res.data, total: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const deleteItem = async (item) => {
        const res = await _dataController.delete((selected.length ? selected : [item.Id]).map(id => id.toLowerCase().replaceAll('-', '')))
        if (res.code === 200) {
            ToastMessage.success(`Xóa ${(selected.length > 1 ? "các " : "") + module} thành công`)
            getData(pageDetails.page, pageDetails.size)
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const showPopupSettingColumn = (ev, _col) => {
        const _tbCellRect = ev.target.closest(`div[class*="header"]`).getBoundingClientRect()
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', right: `calc(100vw - ${_tbCellRect.right}px)`, top: _tbCellRect.bottom },
            content: <div className='col popup-actions' style={{ borderRadius: '0.8rem', minWidth: '20rem' }}>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineMoveLayerLeft />
                    <Text>Move to start</Text>
                </button>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineMoveLayerRight />
                    <Text>Move to end</Text>
                </button>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineViewOff />
                    <Text>Hide column</Text>
                </button>
            </div>
        })
    }

    const showDrawer = () => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', right: 0, bottom: 0, height: `calc(100vh - 18.8rem)`, borderRadius: 0, maxHeight: '100%' },
            content: <DrawerSettingTableManager
                ref={ref}
                pid={_pid}
                cols={cols}
                rels={rels}
                actions={actions}
                onChange={({ newCols, newRels, newActions }) => {
                    if (newCols) setCols(newCols)
                    if (newRels) setRels(newRels)
                    if (newActions) setActions(newActions)
                }}
            />
        })
    }

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: ref,
            style: { width: '80rem' },
            hideButtonClose: true,
            content: <PopupAddEditData
                ref={ref}
                id={item?.Id?.toLowerCase()?.replaceAll("-", "")}
                pid={_pid}
                module={module}
                onSuccess={() => { getData(pageDetails.page, pageDetails.size) }}
            />
        })
    }

    const showPopupFilter = () => {
        showPopup({
            ref: ref,
            style: { width: '80rem' },
            content: <PopupFormFilter
                ref={ref}
                pid={_pid}
                module={module}
                cols={cols.filter(e => !e.Setting.IsHidden)}
                onSuccess={getData}
            />
        })
    }

    const showPopupAction = (ev, item) => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', left: ev.pageX, top: ev.pageY },
            content: <PopupActions
                ref={ref}
                pid={_pid}
                item={item}
                onEdit={() => { showPopupAddEdit(item) }}
                onDelete={() => { deleteItem(item) }}
                actions={actions.filter(e => !e.Shortcut.IsHidden)}
                onSelectAction={(_action) => {
                    closePopup(ref)
                    showPopup({
                        ref: ref,
                        style: { height: '80%', width: '80%' },
                        hideButtonClose: true,
                        content: <PopupManageTableFK ref={ref} pid={_pid} item={item} rel={{ ..._action, TablePK: module }} />
                    })
                }}
            />
        })
    }

    const onMouseEnter = () => {
        document.body.onmousemove = (ev) => {
            if (`${ev.target.className}`.includes("resize-cell-stick")) return
            if (ev.target.classList.contains("header-cell")) {
                let cellElement = ev.target
                const _cellRect = cellElement.getBoundingClientRect()
                // if ((ev.pageX <= (_cellRect.x + 8) && ev.pageX >= _cellRect.x) || (ev.pageX <= _cellRect.right && ev.pageX >= (_cellRect.right - 8))) {
                if (ev.pageX <= _cellRect.right && ev.pageX >= (_cellRect.right - 8)) {
                    let _resizeSticky = document.body.querySelector(`div[class*="resize-cell-stick"]`)
                    if (!_resizeSticky) {
                        _resizeSticky = document.createElement("div")
                        _resizeSticky.className = styles["resize-cell-stick"]
                        document.body.appendChild(_resizeSticky)
                        _resizeSticky.onmousedown = () => {
                            cellElement.style.backgroundColor = "var(--neutral-background-darker)"
                            document.body.onmousemove = (event) => {
                                event.stopImmediatePropagation()
                                event.preventDefault()
                                if (ev.pageX <= _cellRect.x + 4) {
                                    [cellElement, ...document.body.querySelectorAll(`div[class*="${cellElement.id}"]`)].forEach(e => {
                                        e.style.width = `${_cellRect.width + ev.pageX - event.pageX}px`
                                    })
                                } else {
                                    [cellElement, ...document.body.querySelectorAll(`div[class*="${cellElement.id}"]`)].forEach(e => {
                                        e.style.width = `${_cellRect.width + event.pageX - ev.pageX}px`
                                    })
                                }
                                _resizeSticky.style.left = `${cellElement.getBoundingClientRect().right - 4}px`
                            }
                            document.body.onmouseup = (event) => {
                                document.body.onmousemove = undefined
                                document.body.onmouseup = undefined
                                cellElement.style.backgroundColor = ''
                                document.body.querySelector(`div[class*="resize-cell-stick"]`)?.remove()
                            }
                        }
                    }
                    _resizeSticky.style.cssText = `top: ${_cellRect.y}px;left: ${(ev.pageX <= _cellRect.x + 8 ? _cellRect.x : _cellRect.right) - 4}px`
                    return
                }
            } else if (!ev.target.closest(".header-cell")) {
                document.body.onmousemove = undefined
                document.body.onmouseup = undefined
                document.body.querySelector(`div[class*="resize-cell-stick"]`)?.remove()
            }
        }
    }

    useEffect(() => {
        const _rels = rels.filter(e => !e.Setting.IsHidden)
        if (_rels.length && data.total) {
            _rels.forEach((_rel) => {
                const _relItems = methods.getValues(_rel.Column) ?? []
                let _relIds = []
                data.data.map(e => e[_rel.Column]?.split(",") ?? []).reduce((a, b) => a.concat(b)).forEach(id => {
                    if (!_relIds.includes(id)) _relIds.push(id)
                })
                _relIds = _relIds.filter(id => _relItems.every(e => e.Id !== id))
                if (_relIds.length) {
                    const _relDataCotroller = new DataController({ pid: _pid, module: _rel.TablePK })
                    _relDataCotroller.getByListId(_relIds).then(res => {
                        if (res.code === 200) methods.setValue(_rel.Column, [..._relItems, ...res.data.filter(e => e != undefined)])
                    })
                }
            })
        }
    }, [rels.filter(e => !e.Setting.IsHidden).length, data])

    useEffect(() => {
        if (cols.length) getData()
    }, [cols.length, module])

    useEffect(() => {
        methods.reset()
        _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${module}}`, returns: ["Id", "Name", "Setting", "DataType"] }).then(res => {
            if (res.code === 200) {
                const _colRes = res.data.map((e, i) => {
                    e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                    return e
                }).sort((a, b) => a.Name === "Name" ? -1 : b.Name === "Name" ? 1 : a.Setting.Sort - b.Setting.Sort)
                setCols(_colRes.map((e, i) => { return { ...e, Setting: { ...e.Setting, Sort: i } } }))
            }
        })
        _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${module}}`, returns: ["Id", "Column", "Setting", "TablePK"] }).then(res => {
            if (res.code === 200) setRels(res.data.map((e, i) => {
                e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                return e
            }).sort((a, b) => a.Setting.Sort - b.Setting.Sort))
        })
        _relController.getListSimple({ page: 1, size: 100, query: `@TablePK:{${module}}`, returns: ["Id", "Column", "Shortcut", "TableFK"] }).then(res => {
            if (res.code === 200) setActions(res.data.map((e, i) => {
                e.Shortcut = e.Shortcut ? JSON.parse(e.Shortcut) : { Title: e.Name, Sort: i, IsHidden: true }
                return e
            }).sort((a, b) => a.Shortcut.Sort - b.Shortcut.Sort))
        })
    }, [_pid, module])

    return <div className="col">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '2rem 3.2rem', gap: '0.8rem', borderBottom: 'var(--neutral-border)' }}>
            <div className='row' style={{ flex: 1, gap: '2.4rem' }}>
                <button type='button' className='row' style={{ gap: '1.6rem' }}>
                    <OutlineAppFolder size={'3.2rem'} />
                    <Text className='heading-5'>{selectedM?.Name}</Text>
                    <OutlineChevronDown size={'2rem'} />
                </button>
                <Button label='Button' style={{ width: '6.8rem', backgroundColor: "var(--neutral-background)", color: "var(--neutral-color-subtitle)" }} />
                <OutlineAppStar size={'2.4rem'} />
                <div className='row' style={{ gap: '0.6rem', borderRadius: '10rem', backgroundColor: "var(--neutral-background)", border: "var(--neutral-border)", height: '2.4rem', padding: '0 0.8rem' }}>
                    <div style={{ width: '0.6rem', height: '0.6rem', borderRadius: '50%', backgroundColor: "var(--neutral-color-subtitle)" }} />
                    <Text className='button-text-6' style={{ color: "var(--neutral-color-subtitle)" }}>On track</Text>
                    <OutlineChevronDown size={'1.2rem'} />
                </div>
            </div>
            <div className='row' style={{ flex: 1, justifyContent: "end" }}>
                <div className='row'>
                    {Array.from({ length: 5 }).map((_, i) => {
                        if (i === 4) return <div key={'mem-' + i} className='row icon-button24' style={{ backgroundColor: "#f4f4f5", borderRadius: "50%", transform: `translateX(-${i * 0.4}rem)` }}><FontAwesomeIcon icon={faEllipsisH} style={{ color: "#61616b" }} /></div>
                        else return <div key={'mem-' + i} className='row icon-button24' style={{ borderRadius: "50%", transform: i === 0 ? undefined : `translateX(-${i * 0.4}rem)` }}><img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU3FcmHH1HtWFPQqC9Z-IK4JdvSWkvswcDfA&s"} alt='' style={{ width: '100%', height: '100%' }} /></div>
                    })}
                </div>
                <Button
                    label='Share'
                    className='button-primary'
                    style={{ padding: '0 1.2rem', borderRadius: '0.4rem' }}
                    prefix={<FontAwesomeIcon icon={faShareFromSquare} />}
                />
                <div className='row divider' style={{ height: '1.6rem' }} />
                <Button
                    label='Customize'
                    style={{ padding: '0 1.2rem', borderRadius: '0.4rem', backgroundColor: "transparent", border: "var(--neutral-border)", color: "var(--neutral-color-subtitle)" }}
                    prefix={<FilledAppGridInterface />}
                />
            </div>
        </div>
        <div className="col table-view" style={{ padding: '0 3.2rem 1.2rem' }}>
            <div className='row' style={{ padding: '1.2rem 0', gap: "0.8rem" }}>
                <div className='row' style={{ height: '3.2rem', alignItems: "stretch", border: "var(--neutral-border)", borderRadius: '0.8rem' }} >
                    <button type='button' className='row' style={{ padding: '0 1.2rem', borderRight: "var(--neutral-border)", gap: '0.8rem' }} onClick={() => { showPopupAddEdit() }}>
                        <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.4rem', color: "var(--neutral-color-subtitle)" }} />
                        <Text className='button-text-3' style={{ color: "var(--neutral-color-subtitle)" }}>Add {selectedM?.Name}</Text>
                    </button>
                    <div className='row' style={{ padding: '0.8rem' }}>
                        <OutlineChevronDown />
                    </div>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Search'
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.2rem', color: "var(--neutral-color-subtitle)" }} />}
                    style={{ padding: '0 1.6rem', borderRadius: '0.8rem', height: '3.2rem', width: '26.8rem', gap: '1.2rem' }}
                />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { showPopupFilter() }}>
                    <OutlineAppSetupPreferences />
                    <Text className='button-text-3'>Filter</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <OutlineEnlargeVertical />
                    <Text className='button-text-3'>Sort</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={showDrawer}>
                    <OutlineAppSettings />
                    <Text className='button-text-3'>Setting</Text>
                </button>
            </div>
            <div className={`col ${styles['table']}`}>
                <div className={`row ${styles['header']}`} onMouseOver={onMouseEnter}>
                    {cols.filter((e) => e.Name !== "Id" && !e.Setting.IsHidden).map((_col, i) => {
                        const { _minW } = getTableConfig(_col)
                        if (i > 0) {
                            return <div key={_col.Id} id={_col.Id} style={{ width: _col.Setting?.Width ?? _minW, order: _col.Setting.Sort }} className='row header-cell' >
                                <Text className='heading-9' maxLine={1}>{_col.Setting.Title}</Text>
                                <button type='button' className={`row icon-button20`}>
                                    <OutlineEnlargeVertical />
                                </button>
                                <div style={{ flex: 1 }} />
                                <button type='button' className={`row icon-button16`} onClick={(ev) => { showPopupSettingColumn(ev, _col) }}>
                                    <OutlineChevronDown size={'1rem'} />
                                </button>
                            </div>
                        } else {
                            return <div ref={(ref) => {
                                if (ref && !_col.Setting?.Width) {
                                    const _rect = ref.getBoundingClientRect()
                                    setCols(cols.map(e => {
                                        if (e.Id === _col.Id) return { ..._col, Setting: { ..._col.Setting, Width: `${Math.round(_rect.width / 10)}rem` } }
                                        else return e
                                    }))
                                }
                            }} key={_col.Id} id={_col.Id} style={{ flex: _col.Setting?.Width ? undefined : 1, minWidth: _col.Setting?.Width ? undefined : "48rem", width: _col.Setting?.Width, order: _col.Setting.Sort }} className='row header-cell' >
                                <Checkbox size={'1.6rem'} value={data.data.length && data.data.every(e => selected.includes(e.Id))} onChange={(v) => {
                                    const _filter = selected.filter(id => data.data.every(e => id !== e.Id))
                                    if (v) setSelected([..._filter, ...data.data.map(e => e.Id)])
                                    else setSelected(_filter)
                                }} />
                                <div className='row' style={{ height: '100%', gap: '1.2rem', paddingLeft: "0.4rem", flex: 1 }}>
                                    <Text className='heading-9' maxLine={1}>{_col.Setting.Title}</Text>
                                    <button type='button' className={`row icon-button20`}>
                                        <OutlineEnlargeVertical />
                                    </button>
                                    <div style={{ flex: 1 }} />
                                    <button type='button' className={`row icon-button16`} onClick={(ev) => { showPopupSettingColumn(ev, _col) }}>
                                        <OutlineChevronDown size={'1rem'} />
                                    </button>
                                </div>
                            </div>
                        }
                    })}
                    {rels.filter((e) => !e.Setting.IsHidden).map((_rel, i) => {
                        return <div key={`${_rel.Id}`} id={_rel.Id} className='row' style={{ width: _rel.Setting.Width ?? '20rem', order: _rel.Setting.Sort ?? (cols.length + i) }}>
                            <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>{_rel.Setting?.Title ?? _rel.Column}</Text>
                        </div>
                    })}
                    <div key={'hlast'} className='row' style={{ order: 100 }}>
                        <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis", textAlign: "center" }} maxLine={1}>Action</Text>
                    </div>
                </div>
                {
                    data.data.map((item, index) => <div key={`${item.name}-${index}`} className='row'>
                        {
                            cols.filter((e) => e.Name !== "Id" && !e.Setting.IsHidden).map((_col, i) => {
                                const { _value, _minW } = getTableConfig(_col, item)
                                if (i > 0) {
                                    return <div key={`${_col.Id}-${index}`} className={`row ${_col.Id}`} style={{ width: _col.Setting?.Width ?? _minW, order: _col.Setting.Sort }}>
                                        <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_value ?? ""}</Text>
                                    </div>
                                } else {
                                    return <div key={`${_col.Id}-${index}`} className={`row ${_col.Id}`} style={{ flex: _col.Setting?.Width ? undefined : 1, minWidth: _col.Setting?.Width ? undefined : "48rem", width: _col.Setting?.Width, order: _col.Setting.Sort }}>
                                        <Checkbox size={'1.6rem'} value={selected.includes(item.Id)} onChange={(v) => {
                                            if (v) setSelected([...selected, item.Id])
                                            else setSelected(selected.filter(id => id !== item.Id))
                                        }} />
                                        {typeof _value === "string" ? <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_value ?? ""}</Text> : _value}
                                    </div>
                                }
                            })
                        }
                        {
                            rels.filter((e) => !e.Setting.IsHidden).map((_rel) => {
                                const _ids = item[_rel.Column]?.split(",") ?? []
                                const _mapValue = _ids.length ? (methods.watch(_rel.Column) ?? []).filter(e => _ids.includes(e.Id)) : undefined
                                return <div key={`${_rel.Id}-${index}`} className={`row ${_rel.Id}`} style={{ width: _rel.Setting?.Width ?? "20rem", order: _rel.Setting.Sort }}>
                                    <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_mapValue?.map(e => e.Name ?? "-")?.join(",") ?? ""}</Text>
                                </div>
                            })
                        }
                        <div key={'blast-' + index} className='row' style={{ gap: '0.8rem', justifyContent: 'center', order: 100, visibility: selected.length === 0 || selected.includes(item.Id) ? "visible" : "hidden" }}>
                            <button type='button' className='row icon-button20' onClick={() => { showPopupAddEdit(item) }}><OutlineEdit /></button>
                            <button type='button' className='row icon-button20' onClick={(ev) => { showPopupAction(ev, item) }}><OutlineAppMoreV /></button>
                        </div>
                    </div>)
                }
            </div>
            <div className='row' style={{ height: '5.6rem', borderTop: "var(--neutral-border)" }}>
                <Pagination
                    currentPage={pageDetails.page}
                    itemPerPage={pageDetails.size}
                    totalItem={data?.total ?? 0}
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getData(page, size)
                            setSelected([])
                        }
                    }}
                />
            </div>
        </div>
    </div>
}