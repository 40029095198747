import { BaseDA } from "../../../da/baseDA"
import ConfigApi from "../../../da/configApi"
import {CustomerItem} from "./da"

export class CustomerController {
    static async getAll() {
        const res = await BaseDA.post(ConfigApi.url + 'wini/getAll', {
            headers: { module: 'Customer' }
        })
        return res
    }

    static async getByIds(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/getByIds', {
            headers: { module: 'Customer' },
            body: { ids: ids }
        })
        return res
    }

    static async add(data: Array<CustomerItem>) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/action?action=add', {
            headers: { module: 'Customer' },
            body: { data: data }
        })
        return res
    }

    static async edit(data: Array<CustomerItem>) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/action?action=edit', {
            headers: { module: 'Customer' },
            body: { data: data }
        })
        return res
    }

    static async delete(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'wini/action?action=delete', {
            headers: { module: 'Customer' },
            body: { ids: ids }
        })
        return res
    }
}