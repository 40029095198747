import styles from './view.module.css'
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, Popup, showDialog, showPopup, Switch, Text, TextField, ToastMessage } from "wini-web-components"
import { forwardRef, useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons"
import { OutlineAppSetupPreferences, OutlineEdit, OutlineTrashCan } from '../../../assets/icon'
import { FormType } from '../../module/da'
import { SettingDataController } from '../../module/controller'
import RenderFormByType from '../../module/manager/formByType'
import { TableController } from './controller'
import { useForm } from 'react-hook-form'
import { TextAreaForm, TextFieldForm } from '../../../project-component/component-form'
import { randomGID } from '../../../Utils'
import CaptureElement from '../../../project-component/captureElement'

export default function SettingFormTab({ pid, item }) {
    const _settingDataController = new SettingDataController({ pid: pid, setting: "form" })
    const _relController = new TableController(pid, "rel")
    const _colController = new TableController(pid, "column")
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [demoCols, setDemoCols] = useState([])
    const ref = useRef()
    const dialogRef = useRef()
    const [onEditId, setOnEditId] = useState()

    const getDemCols = async () => {
        const res = await _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${item.Name}}`, returns: ["Id", "Column", "TablePK", "TableFK", "Query", "Form"] })
        const colRes = await _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${item.Name}} -@Name:{Id | DateCreated}`, returns: ["Id", "Name", "DataType", "Query", "Form"] })
        if (colRes.code === 200) {
            setDemoCols([
                ...colRes.data.filter(e => !e.Query).map((e) => {
                    return { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Required: true }, type: "col" }
                }),
                ...(res.data ?? []).filter(e => !e.Query).map((e) => {
                    return { ...e, Form: e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Required: true }, type: "rel" }
                })
            ])
        }
    }

    const getData = async () => {
        const res = await _settingDataController.getListSimple({ page: 1, size: 20, query: `@TbName:{${item.Name}}`, sortby: { BY: "DateCreated" } })
        if (res.code === 200) {
            setData({
                data: res.data.map(e => {
                    return { ...e, Props: typeof e.Props === "string" ? JSON.parse(e.Props) : e.Props }
                }),
                totalCount: res.totalCount
            })
        }
    }

    const showDrawerForm = (formItem) => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', right: 0, bottom: 0, height: `calc(100% - 9rem)`, maxHeight: '100%', borderRadius: 0, width: 'calc(100vw - min(max(24%, 36rem), 48rem))' },
            content: <DrawerSettingFormLayout
                ref={ref}
                pid={pid}
                cols={demoCols.filter(e => e.type === "col")}
                rels={demoCols.filter(e => e.type === "rel")}
                formItem={formItem}
                onSubmit={getData}
            />
        })
    }

    useEffect(() => {
        if (demoCols.length && !data.totalCount) getData()
    }, [demoCols.length, data.totalCount])

    useEffect(() => {
        getDemCols()
    }, [])

    return <div className='row' style={{ flex: 1 }}>
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='col' style={{ width: "24%", minWidth: "36rem", maxWidth: "48rem", borderRight: "var(--neutral-border)", backgroundColor: "var(--neutral-background)", height: "100%" }}>
            <div className='row' style={{ padding: '0 1.6rem', height: '4.8rem', gap: '1.6rem' }}>
                <TextField
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: "1.2rem", color: "var(--neutral-color-subtitle)" }} />}
                    placeholder='Search'
                    style={{ height: '3.2rem', padding: '0 1.2rem', borderRadius: '0.4rem', backgroundColor: "#fff", flex: 1 }}
                />
                <button type='button' className='row'><OutlineAppSetupPreferences size={'2.4rem'} /></button>
            </div>
            <div className='row' style={{ padding: "1.6rem 2.4rem", backgroundColor: "var(--neutral-background-selected)", gap: "0.8rem" }}>
                <Text className='label-3' style={{ flex: 1 }}>All form</Text>
                <Text className='body-3'>4</Text>
            </div>
            <div className='col' style={{ flex: 1, overflow: "hidden auto", padding: '1.6rem 2.4rem', gap: '1.6rem' }}>
                {Object.keys(FormType).map((k) => {
                    return <div key={k} className={`col ${styles['demo-admin-assets']}`} draggable onDragStart={(ev) => { ev.dataTransfer.setData("form-type", FormType[k]) }}>
                        <img src={require(`../../../assets/form/${FormType[k].replaceAll(" ", "-").toLowerCase()}.png`)} alt='' height={'100%'} />
                        <Text className='heading-7'>{FormType[k]}</Text>
                    </div>
                })}
            </div>
        </div>
        <div className={`row ${styles['list-form-container']}`} onDragOver={(ev) => { ev.preventDefault() }} onDrop={async (ev) => {
            ev.preventDefault();
            const _type = ev.dataTransfer.getData("form-type")
            let _formProps = {}
            demoCols.forEach((p, i) => { _formProps[p.Name ?? p.Column] = i })
            const newFormRes = await _settingDataController.action("add", {
                data: [{
                    Id: randomGID(),
                    Name: _type,
                    TbName: item.Name,
                    DateCreated: (new Date()).getTime(),
                    Description: "default description...",
                    Type: _type,
                    Props: JSON.stringify(_formProps)
                }]
            })
            if (newFormRes.code !== 200) return ToastMessage.errors(newFormRes.message)
            getData()
        }}>
            {data.data.map((e) => {
                return <div key={e.Id} className={`col col12 col8-xxl`} style={{ gap: '0.4rem', '--gutter': "2.4rem" }}>
                    <div onDoubleClick={() => { setOnEditId(e.Id) }}>
                        <TextField
                            autoFocus
                            defaultValue={e.Name}
                            className={`label-4 ${styles["input-card-name"]}`}
                            disabled={onEditId !== e.Id}
                            style={{ padding: '0.4rem' }}
                            onComplete={(ev) => ev.target.blur()}
                            onBlur={async (ev) => {
                                if (onEditId === e.Id) {
                                    const res = await _settingDataController.action("edit", {
                                        data: [{
                                            ...e,
                                            Name: ev.target.value,
                                            Props: typeof e.Props === "string" ? e.Props : JSON.stringify(e.Props)
                                        }]
                                    })
                                    if (res.code !== 200) return ToastMessage.errors(res.message)
                                    setData({ ...data, data: data.data.map(d => d.Id === e.Id ? { ...d, Name: ev.target.value } : d) })
                                    setOnEditId(undefined)
                                }
                            }}
                        />
                    </div>
                    <div className={`col ${styles["demo-container"]}`} >
                        <CaptureElement className='col demo-card-container' style={{ width: "100%", height: "100%", flex: 1, padding: "1.6rem" }}>
                            <RenderFormByType
                                pid={pid}
                                style={{ width: '100%', minWidth: "80rem", maxHeight: '60rem', height: '100%', flex: 1 }}
                                formItem={e}
                                columns={demoCols}
                            />
                        </CaptureElement>
                        <div className='row'>
                            <button type='button' className='row icon-button40' onClick={() => { showDrawerForm(e) }}><OutlineEdit size={'2.8rem'} color={"#fff"} /></button>
                            {data.totalCount > 1 && <button type='button' className='row icon-button40' onClick={() => {
                                showDialog({
                                    ref: dialogRef,
                                    alignment: DialogAlignment.center,
                                    status: ComponentStatus.WARNING,
                                    title: "Confirm delete",
                                    content: "Are you sure you want to delete this form",
                                    cancelTitle: "Cancel",
                                    onSubmit: () => {
                                        _settingDataController.action("delete", { ids: [e.Id] }).then(res => {
                                            if (res.code === 200) getData()
                                        })
                                    },
                                    submitTitle: "Delete"
                                })
                            }}><OutlineTrashCan size={'2.8rem'} color={"#fff"} /></button>}
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>
}

const DrawerSettingFormLayout = forwardRef(function DrawerSettingFormLayout(data, ref) {
    const _settingDataController = new SettingDataController({ pid: data.pid, setting: "form" })
    const methods = useForm({ shouldFocusError: false })
    const [cols, setCols] = useState([])
    const [rels, setRels] = useState([])
    const [selectedId, setSelectedId] = useState(data.cols[0].Id)
    const dialogRef = useRef()

    const _onSubmit = (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: 'Confirm save',
            content: "Every changes will be applied on this form",
            submitTitle: "Submit",
            cancelTitle: "Cancel",
            onSubmit: async () => {
                const res = await _settingDataController.action("edit", { data: [{ ...ev, Props: typeof ev.Props !== "string" ? JSON.stringify(ev.Props) : ev.Props }] })
                if (res.code !== 200) return ToastMessage.errors(res.message)
                data.onSubmit()
                closePopup(ref)
            }
        })
    }

    const onMouseDown = (ev) => {
        ev.preventDefault()
        const _colTile = ev.target.closest(`.row[class*="setting-prop-tile"]`)
        const _tmp = [..._colTile.parentElement.children].sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
        function onDrag(event) {
            if (_colTile) {
                _colTile.style.display = "none"
                let _demo = document.body.querySelector(`div[class*="demo-sort"]`)
                if (!_demo) {
                    _demo = document.createElement("div")
                    _demo.className = styles['demo-sort']
                }
                const _children = _tmp.filter(e => e.id !== _colTile.id && !e.classList.contains(styles['demo-sort']))
                let _order = 0
                let _distance = 0
                let closestHTML = [..._children].sort((aHTML, bHTML) => {
                    let aRect = aHTML.getBoundingClientRect()
                    let bRect = bHTML.getBoundingClientRect()
                    let a_center_oy = Math.abs(event.pageY - (aRect.y + aRect.height / 2))
                    let b_center_oy = Math.abs(event.pageY - (bRect.y + bRect.height / 2))
                    return a_center_oy - b_center_oy
                })[0]
                if (closestHTML) {
                    let htmlRect = closestHTML.getBoundingClientRect()
                    _order = parseInt(window.getComputedStyle(closestHTML).order)
                    _distance = event.pageY - (htmlRect.y + htmlRect.height / 2)
                    if (_distance < 0) _order--
                } else _order = _children.length - 1
                _demo.style.order = _order
                _colTile.style.order = _order
                if (_demo.parentElement !== _colTile.parentElement) _colTile.parentElement.appendChild(_demo)
            }
        }
        document.body.addEventListener('mousemove', onDrag)
        document.body.onmouseup = () => {
            if (_colTile) {
                document.body.querySelector(`div[class*="demo-sort"]`)?.remove()
                _colTile.style.removeProperty("display")
                const _childrenId = [..._colTile.parentElement.children].sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0)).map(e => e.id)
                let listProps = []
                setCols(cols.map(e => {
                    let _index = _childrenId.findIndex(id => e.Id === id)
                    listProps.push({ key: e.Name, index: _index })
                    return { ...e, Form: { ...e.Form, Sort: _index } }
                }))
                setRels(rels.map(e => {
                    let _index = _childrenId.findIndex(id => e.Id === id)
                    listProps.push({ key: e.Column, index: _index })
                    return { ...e, Form: { ...e.Form, Sort: _index } }
                }))
                listProps = listProps.sort((a, b) => a.index - b.index)
                let _oldProps = methods.getValues("Props")
                let _newProps = {}
                listProps.forEach(e => {
                    _newProps[e.key] = _oldProps[e.key] < 0 ? -1 : e.index
                })
                methods.setValue("Props", _newProps)
            }
            document.body.removeEventListener('mousemove', onDrag)
            document.body.onmouseup = undefined
        }
    }

    useEffect(() => {
        const _listKey = Object.keys(data.formItem.Props).map(k => k)
        setCols(data.cols.map((e) => {
            let _tmp = { ...e }
            _tmp.Form.Sort = _listKey.indexOf(_tmp.Name)
            return _tmp
        }))
        setRels(data.rels.map((e) => {
            let _tmp = { ...e }
            _tmp.Form.Sort = _listKey.indexOf(_tmp.Name)
            return _tmp
        }))
        Object.keys(data.formItem).forEach((k) => {
            methods.setValue(k, data.formItem[k])
        })
    }, [])

    return <div className={`col ${styles['setting-form-item-container']}`}>
        <Dialog ref={dialogRef} />
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <Text className='heading-7' style={{ flex: 1 }}>{data.formItem.Name}</Text>
            <button type='button' className='row icon-button28' onClick={() => { closePopup(ref) }}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
        <div className='row' style={{ gap: '2.4rem', padding: "1.6rem 2.4rem", alignItems: 'start', height: "100%", flex: 1 }}>
            <div className={`col col16 ${styles['overview-container']}`}>
                <Text className='heading-8'>Overview</Text>
                <RenderFormByType
                    pid={data.pid}
                    style={{ height: '100%', flex: 1 }}
                    formItem={methods.watch()}
                    columns={[...cols, ...rels]}
                />
            </div>
            <div className={`col col8 ${styles['infor-container']}`}>
                <Text className='heading-8'>Setting</Text>
                <TextFieldForm
                    required
                    name='Name'
                    register={methods.register}
                    errors={methods.formState.errors}
                    label='Form name'
                />
                <TextAreaForm
                    name='Description'
                    register={methods.register}
                    errors={methods.formState.errors}
                    label='Form description'
                />
                <div className='col'>
                    {cols.filter(e => e.Name !== "Id").map((item) => {
                        return <div key={item.Id} onClick={() => { setSelectedId(item.Id) }} id={item.Id} className={`row ${styles['setting-prop-tile']} ${item.Id === selectedId ? styles['selected'] : ""}`} style={{ order: item.Form.Sort }}>
                            <div onMouseDown={onMouseDown} className='row icon-button24'><FontAwesomeIcon icon={faGripVertical} style={{ color: '#61616b' }} /></div>
                            <Text className='body-3'>{item.Form.Label}</Text>
                            <Switch disabled={item.Name === "Name"} value={methods.watch("Props")[item.Name] >= 0} onChange={(v) => {
                                let _tmp = methods.getValues("Props")
                                _tmp[item.Name] = v ? item.Form.Sort : -1
                                methods.setValue("Props", _tmp)
                            }} />
                        </div>
                    })}
                    {rels.map((item) => {
                        return <div key={item.Id} onClick={() => { setSelectedId(item.Id) }} id={item.Id} className={`row ${styles['setting-prop-tile']} ${item.Id === selectedId ? styles['selected'] : ""}`} style={{ order: item.Form.Sort }}>
                            <div onMouseDown={onMouseDown} className='row icon-button24'><FontAwesomeIcon icon={faGripVertical} style={{ color: '#61616b' }} /></div>
                            <Text className='body-3'>{item.Form.Label ?? item.Column}</Text>
                            <Switch value={methods.watch("Props")[item.Column] >= 0} onChange={(v) => {
                                let _tmp = methods.getValues("Props")
                                _tmp[item.Column] = v ? item.Form.Sort >= 0 ? item.Form.Sort : 0 : -1
                                methods.setValue("Props", _tmp)
                            }} />
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div className={`row`} style={{ padding: "1.6rem 2.4rem", borderTop: "var(--neutral-border)", justifyContent: "end", gap: "0.8rem" }}>
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "#fff", color: "var(--neutral-color-subtitle)" }}
                onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Confirm cancel',
                        content: "Every changes will be unsaved",
                        submitTitle: "Submit",
                        cancelTitle: "Cancel",
                        onSubmit: () => { closePopup(ref) }
                    })
                }}
            />
            <Button
                label="Save"
                className="button-primary"
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(_onSubmit)}
            />
        </div>
    </div>
})