import styles from './view.module.css'
import { forwardRef, useEffect, useRef, useState } from "react"
import { TableController } from "../../home/table/controller"
import { DataController } from "../controller"
import { Checkbox, closePopup, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Table, TbBody, TbCell, TbHeader, TbRow, Text, TextField, ToastMessage } from "wini-web-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faSearch, faXmark } from "@fortawesome/free-solid-svg-icons"
import { getTableConfig } from "../config"
import { OutlineAppSetupPreferences, OutlineChevronDown, OutlineEdit, OutlineEnlargeVertical, OutlineTrashCan } from "../../../assets/icon"
import PopupAddEditData from "./settingForm"

const PopupManageTableFK = forwardRef(function PopupManageTableFK({ pid, item, rel }, ref) {
    const _colController = new TableController(pid, "column")
    const _dataController = new DataController({ pid: pid, module: rel.TableFK })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [cols, setCols] = useState([])
    const popupRef = useRef()
    const dialogRef = useRef()
    const [selected, setSelected] = useState([])

    const getData = async (page, size) => {
        const res = await _dataController.aggregateList({
            page: page ?? pageDetails.page,
            size: size ?? pageDetails.size,
            searchRaw: `@${rel.Column}:{${item.Id}*}`
        })
        if (res.code === 200) {
            setData({ data: res.data, totalCount: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        getData()
        _colController.getListSimple(
            {
                page: 1,
                size: 100,
                query: `@TableName:{${rel.TableFK}}`,
                returns: ["Id", "Name", "Setting", "DataType"]
            }
        ).then(res => {
            if (res.code === 200) setCols(res.data.map((e, i) => {
                e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                return e
            }).filter((e) => e.Name !== "Id" && !e.Setting.IsHidden).sort((a, b) => a.Setting.Sort - b.Setting.Sort))
        })
    }, [])

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: popupRef,
            style: { width: '80rem' },
            hideButtonClose: true,
            content: <PopupAddEditData
                ref={popupRef}
                id={item?.Id?.toLowerCase()?.replaceAll("-", "")}
                rel={{ ...rel, value: item.Id }}
                pid={pid}
                module={rel.TableFK}
                onSuccess={getData}
            />
        })
    }

    return <div className='col' style={{ flex: 1, height: '100%', width: '100%' }}>
        <Popup ref={popupRef} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem', alignItems: 'start', gap: '0.8rem', borderBottom: 'var(--neutral-border)' }}>
            <Text className='heading-7' style={{ flex: 1, marginTop: '0.4rem' }} maxLine={2}>{rel.TableFK} of {item.Name}</Text>
            <button type='button' className='row icon-button32' onClick={() => { closePopup(ref) }}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
        <div className='col' style={{ padding: '0 2.4rem 1.6rem', width: '100%', flex: 1, height: '100%' }}>
            <div className='row' style={{ padding: '1.2rem 0', gap: "0.8rem" }}>
                <button type='button' className='row' style={{ padding: '0 1.2rem', border: "var(--neutral-border)", gap: '0.8rem', height: '3.2rem', borderRadius: '0.8rem' }} onClick={() => { showPopupAddEdit() }}>
                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.4rem', color: "var(--neutral-color-subtitle)" }} />
                    <Text className='button-text-3' style={{ color: "var(--neutral-color-subtitle)" }}>Add {rel.TableFK} to this {rel.TablePK}</Text>
                </button>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Search'
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.2rem', color: "var(--neutral-color-subtitle)" }} />}
                    style={{ padding: '0 1.6rem', borderRadius: '0.8rem', height: '3.2rem', width: '26.8rem', gap: '1.2rem' }}
                />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <OutlineAppSetupPreferences  />
                    <Text className='button-text-3'>Filter</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <OutlineEnlargeVertical  />
                    <Text className='button-text-3'>Sort</Text>
                </button>
            </div>
            <div className={`col ${styles['table']}`}>
                <div className={`row ${styles['header']}`}>
                    {cols.map((_col, i) => {
                        const { _minW } = getTableConfig(_col)
                        if (i > 0) {
                            return <div key={_col.Id} style={{ width: _col.Setting?.Width ?? _minW, order: _col.Setting.Sort }} className='row' >
                                <Text className='heading-9' maxLine={1}>{_col.Setting.Title}</Text>
                                <button type='button' className={`row icon-button20`}>
                                    <OutlineEnlargeVertical  />
                                </button>
                                <div style={{ flex: 1 }} />
                            </div>
                        } else {
                            return <div key={_col.Id} style={{ flex: _col.Setting?.Width ? undefined : 1, minWidth: _col.Setting?.Width ? undefined : "48rem", width: _col.Setting?.Width, order: _col.Setting.Sort }} className='row'>
                                <Checkbox size={'1.6rem'} value={data.data.every(e => selected.includes(e.Id))} onChange={(v) => {
                                    const _filter = selected.filter(id => data.data.every(e => id !== e.Id))
                                    if (v) setSelected([..._filter, ...data.data.map(e => e.Id)])
                                    else setSelected(_filter)
                                }} />
                                <div className='row' style={{ height: '100%', gap: '1.2rem', paddingLeft: "0.4rem", flex: 1 }}>
                                    <Text className='heading-9' maxLine={1}>{_col.Setting.Title}</Text>
                                    <button type='button' className={`row icon-button20`}>
                                        <OutlineEnlargeVertical  />
                                    </button>
                                    <div style={{ flex: 1 }} />
                                </div>
                            </div>
                        }
                    })}
                    <div key={'hlast'} className='row' style={{ order: 100 }}>
                        <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis", textAlign: "center" }} maxLine={1}>Action</Text>
                    </div>
                </div>
                {
                    data.data.map((e, index) => <div key={`${e.name}-${index}`} className='row'>
                        {
                            cols.map((_col, i) => {
                                const { _value, _minW } = getTableConfig(_col, e)
                                if (i > 0) {
                                    return <div key={`${_col.Id}-${index}`} className={`row ${_col.Id}`} style={{ width: _col.Setting?.Width ?? _minW, order: _col.Setting.Sort }}>
                                        <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_value ?? ""}</Text>
                                    </div>
                                } else {
                                    return <div key={`${_col.Id}-${index}`} className={`row ${_col.Id}`} style={{ flex: _col.Setting?.Width ? undefined : 1, minWidth: _col.Setting?.Width ? undefined : "48rem", width: _col.Setting?.Width, order: _col.Setting.Sort }}>
                                        <Checkbox size={'1.6rem'} value={selected.includes(e.Id)} onChange={(v) => {
                                            if (v) setSelected([...selected, e.Id])
                                            else setSelected(selected.filter(id => id !== e.Id))
                                        }} />
                                        <Text maxLine={2} className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }}>{_value ?? ""}</Text>
                                    </div>
                                }
                            })
                        }
                        <div key={'blast-' + index} className='row' style={{ gap: '0.8rem', justifyContent: 'center', order: 100, visibility: selected.length === 0 || selected.includes(e.Id) ? "visible" : "hidden" }}>
                            <button type='button' className='row icon-button20' onClick={() => { showPopupAddEdit(e) }}><OutlineEdit  /></button>
                            <button type='button' className='row icon-button20' onClick={() => {
                                showDialog({
                                    ref: dialogRef,
                                    alignment: DialogAlignment.center,
                                    status: ComponentStatus.WARNING,
                                    title: "Confirm delete",
                                    content: `Are you sure to remove this ${rel.TableFK} out of ${item.Name}?`,
                                    submitTitle: "Delete",
                                    cancelTitle: "Cancel",
                                    onSubmit: async () => {
                                        let _editItem = { ...e }
                                        _editItem[rel.Column] = _editItem[rel.Column].split(",").filter(id => id !== item.Id).join(",")
                                        const res = await _dataController.edit([_editItem])
                                        if (res.code !== 200) return ToastMessage.errors(res.message)
                                        getData()
                                    }
                                })
                            }}><OutlineTrashCan  /></button>
                        </div>
                    </div>)
                }
            </div>
            <div className='row' style={{ height: '5.6rem', borderTop: "var(--neutral-border)" }}>
                <Pagination
                    currentPage={pageDetails.page}
                    itemPerPage={pageDetails.size}
                    totalItem={data.totalCount ?? 0}
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getData(page, size)
                        }
                    }}
                />
            </div>
        </div>
    </div>
})

export default PopupManageTableFK