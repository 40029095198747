export enum DesignTokenType {
    group = "group",
    color = "color",
    string = "string",
}

export interface DesignTokenItem {
    Id: string,
    Name: string,
    DateCreated: number,
    ParentId: string,
    Type: DesignTokenType, // type === group => parentId & value = undefined
    Sort: number,
    Value: string | { darkMode: string, lightMode: string },
}