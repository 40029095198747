import styles from './view.module.css'
import { faChevronDown, faChevronRight, faEdit, faListUl, faPlus, faSearch, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { CellAlignItems, Checkbox, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Table, TbBody, TbCell, TbHeader, TbRow, Text, TextField, ToastMessage } from "wini-web-components";
import { TableController } from "./controller";
import PopupAddEditTable from "./createTb";
import { Ultis } from "../../../Utils";
import { OutlineAppFolder, OutlineAppKey, OutlineAppSetupPreferences, OutlineEnlargeVertical, OutlineEdit, OutlineTrashCan, OutlineAppCoins } from "../../../assets/icon";
import PopupSettingAssets from './store';

export default function ListTable() {
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _tbController = new TableController(_pid, 'table')
    const ref = useRef()
    const dialogRef = useRef()
    const [tables, setTables] = useState({ data: [] })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { width: "68%", height: "100%" },
            content: <PopupAddEditTable
                ref={ref}
                pid={_pid}
                item={item}
                onSuccess={getTables}
            />
        })
    }

    const showPopupSettingAssets = (item) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%", borderRadius: 0 },
            content: <PopupSettingAssets
                ref={ref}
                pid={_pid}
                item={item}
            />
        })
    }

    const getTables = async (page, size) => {
        const res = await _tbController.getListSimple({ page: page ?? pageDetails.page, size: size ?? pageDetails.size })
        setTables({ data: res.data, count: res.count })
    }

    useEffect(() => {
        if (_pid) getTables()
    }, [_pid])

    return <div className="col">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ padding: '2rem 3.2rem', gap: '0.8rem', borderBottom: 'var(--neutral-border)' }}>
            <button type='button' className='row' style={{ gap: '1.6rem' }}>
                <OutlineAppFolder size={'3.2rem'}  />
                <Text className='heading-5'>Table</Text>
                <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '1.6rem', color: "#61616b" }} />
            </button>
        </div>
        <div className="col table-view" style={{ padding: '0 3.2rem 1.2rem' }}>
            <div className='row' style={{ padding: '1.2rem 0', gap: "0.8rem" }}>
                <div className='row' style={{ height: '3.2rem', alignItems: "stretch", border: "var(--neutral-border)", borderRadius: '0.8rem' }} >
                    <button type='button' className='row' style={{ padding: '0 1.2rem', borderRight: "var(--neutral-border)", gap: '0.8rem' }} onClick={() => { showPopupAddEdit() }}>
                        <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.4rem', color: "var(--neutral-color-subtitle)" }} />
                        <Text className='button-text-3' style={{ color: "var(--neutral-color-subtitle)" }}>Add table</Text>
                    </button>
                    <div className='row' style={{ padding: '0.8rem' }}>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '1.4rem', color: "var(--neutral-color-subtitle)" }} />
                    </div>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Search'
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.2rem', color: "var(--neutral-color-subtitle)" }} />}
                    style={{ padding: '0 1.6rem', borderRadius: '0.8rem', height: '3.2rem', width: '26.8rem', gap: '1.2rem' }}
                />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <OutlineAppSetupPreferences  />
                    <Text className='button-text-3'>Filter</Text>
                </button>
                <div className='row divider' style={{ height: '1.2rem', margin: 0 }} />
                <button type='button' className='row button-neutral' style={{ borderColor: "transparent" }} onClick={() => { }}>
                    <OutlineEnlargeVertical  />
                    <Text className='button-text-3'>Sort</Text>
                </button>
            </div>
            <div className={`col ${styles['table']}`}>
                <div className={`row ${styles['header']}`}>
                    <div key={'h0'} className='row' style={{ flex: 1, minWidth: '24rem' }}>
                        <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                        <div className='row' style={{ height: '100%', gap: '1.2rem', paddingLeft: "0.4rem", flex: 1 }}>
                            <Text className='heading-9' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>Name</Text>
                            <button type='button' className={`row icon-button24 ${styles['button-sort']}`} >
                                <OutlineEnlargeVertical color={"#878792"} />
                            </button>
                        </div>
                    </div>
                    <div key={'h2'} className='row' style={{ width: '20rem' }}>
                        <Text className='heading-9' >Created date</Text>
                    </div>
                    <div key={'h3'} className='row' style={{ width: '40rem' }}>
                        <Text className='heading-9'>Description</Text>
                    </div>
                    <div key={'hlast'} className='row' style={{ width: "14rem" }}>
                        <Text className='heading-9' style={{ flex: 1, textAlign: CellAlignItems.center }}>Action</Text>
                    </div>
                </div>
                {
                    tables.data.map((item, index) => <div key={`${item.Id}`} className='row'>
                        <div key={'b0-' + index} className='row' style={{ flex: 1, minWidth: '24rem' }}>
                            <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                            <Text className='body-3' style={{ flex: 1, textOverflow: "ellipsis" }} maxLine={1}>{item.Name}</Text>
                        </div>
                        <div key={'b2-' + index} className='row' style={{ width: '20rem' }}>
                            <Text className='body-3'>{item.DateCreated ? Ultis.datetoString(new Date(item.DateCreated), "dd/mm/yyyy hh:mm") : "-"}</Text>
                        </div>
                        <div key={'b3-' + index} className='row' style={{ width: '40rem' }}>
                            <Text className='body-3'>{item.Description ?? "-"}</Text>
                        </div>
                        <div key={'blast-' + index} className='row' style={{ gap: "0.8rem", justifyContent: 'center', width: "14rem" }} >
                            <button type='button' className='row icon-button24' onClick={() => { showPopupAddEdit(item) }}>
                                <OutlineAppKey  />
                            </button>
                            <button type='button' className='row icon-button24' onClick={() => { showPopupSettingAssets(item) }}>
                                <OutlineAppCoins size={'1.8rem'}  />
                            </button>
                            <button type='button' className='row icon-button24' onClick={(ev) => {
                                showDialog({
                                    ref: dialogRef,
                                    alignment: DialogAlignment.center,
                                    status: ComponentStatus.WARNING,
                                    title: 'Bạn chắc chắn muốn xóa bảng ' + item.Name,
                                    content: `Mọi dữ liệu của bảng ${item.Name} sẽ bị xóa vĩnh viễn`,
                                    onSubmit: async () => {
                                        const res = await _tbController.delete([item.Id])
                                        if (res.code === 200) {
                                            ToastMessage.success(`Xóa bảng ${item.Name} thành công`)
                                            setTables({ data: tables.data.filter(e => e.Id !== item.Id), count: tables.data.filter(e => e.Id !== item.Id).length })
                                        } else {
                                            ToastMessage.errors(res.message)
                                        }
                                    }
                                })
                            }}><OutlineTrashCan  /></button>
                        </div>
                    </div>)
                }
            </div>
            <div style={{ padding: '1.2rem 2.4rem' }}>
                <Pagination
                    /// Size
                    currentPage={pageDetails.page}
                    /// pageSize
                    itemPerPage={pageDetails.size}
                    // data.total
                    totalItem={tables?.count ?? 0}
                    /// action
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getTables(page, size)
                        }
                    }}
                />
            </div>
        </div>
    </div>
}