import { Button, Checkbox, RadioButton, Switch, Text, TextArea, TextField } from "wini-web-components"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { ChartCard } from "../../../project-component/cards"
import { TableController } from "../../home/table/controller"
import { ComponentType } from "../../home/table/da"
import { getElementByPattern } from "../controller"
import RenderFormByType from "../manager/formByType"
import RenderCardNewsByType from "../manager/cardByType/cardNews"


export default function RenderPageByLayers({ onDemo }) {
    const { layers } = useSelector((store) => store.page.data)
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")

    const renderPageView = (item) => {
        const children = (layers ?? []).filter(e => e.ParentId === item.Id)
        if (onDemo) {
            var _props = { ...item.Setting }
            _props.id = item.Id
            _props.className = _props.className ? (_props.className + " layer-item") : "layer-item"
        }
        switch (item.Type) {
            case ComponentType.container:
                return <div key={item.Id} component-type={item.Type} {...(_props ?? item.Setting)}>{children.map(e => renderPageView(e))}</div>
            case ComponentType.text:
                return <Text key={item.Id} {...(_props ?? item.Setting)}>{item.Setting?.value ?? ""}</Text>
            case ComponentType.button:
                return <Button type="button" key={item.Id} {...(_props ?? item.Setting)} />
            case ComponentType.img:
                return <img key={item.Id} alt="" {...(_props ?? item.Setting)} />
            case ComponentType.chart:
                return <ChartById pid={_pid} key={item.Id} {...(_props ?? item.Setting)} />
            case ComponentType.form:
                return <FormById pid={_pid} key={item.Id} {...(_props ?? item.Setting)} />
            case ComponentType.card:
                return <CardById pid={_pid} key={item.Id} {...(_props ?? item.Setting)} />
            case ComponentType.checkbox:
                return <Checkbox value key={item.Id} {...(_props ?? item.Setting)} />
            case ComponentType.switch:
                return <Switch value key={item.Id} {...(_props ?? item.Setting)} />
            case ComponentType.textField:
                return <TextField key={item.Id} {...(_props ?? item.Setting)} />
            case ComponentType.textArea:
                return <TextArea key={item.Id} {...(_props ?? item.Setting)} />
            case ComponentType.radio:
                return <RadioButton key={item.Id} {...(_props ?? item.Setting)} />
            default:
                return <div key={item.Id} />
        }
    }

    return onDemo ? (layers ?? []).filter(e => !e.ParentId).map(e => renderPageView(e)) : <div className="col view">
        {(layers ?? []).filter(e => !e.ParentId).map(e => renderPageView(e))}
    </div>
}

const ChartById = ({ id, pid, cardId, style, className }) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (cardId && pid) {
            getElementByPattern({ pid: pid, pattern: `data_chart*:${cardId}` }).then(async (res) => {
                if (res.code === 200) {
                    const _relController = new TableController(pid, "rel")
                    const relRes = await _relController.getByListId(res.data.map(e => e.RelativeId))
                    if (relRes.code === 200 && relRes.data[0]) {
                        setData({
                            ...res.data[0],
                            TableFK: relRes.data[0].TableFK
                        })
                    }
                }
            })
        }
    }, [cardId, pid])

    return data ? <ChartCard
        id={id}
        pid={pid}
        item={data}
        timeRannge={120}
        style={style}
        className={className}
    /> : <div id={id} />
}

const FormById = ({ id, pid, formId, style, className }) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (formId && pid) {
            getElementByPattern({ pid: pid, pattern: `data_form*:${formId}` }).then(async (res) => {
                if (res.code === 200) {
                    let _formItem = res.data[0]
                    if (typeof _formItem.Props === "string") _formItem.Props = JSON.parse(_formItem.Props)
                    setData(_formItem)
                }
            })
        }
    }, [formId, pid])

    return data ? <RenderFormByType
        isActive
        id={id}
        pid={pid}
        formItem={data}
        style={style}
        className={className}
    /> : <div id={id} />
}

const CardById = ({ id, pid, cardId, style, className }) => {
    const [cardItem, setCardItem] = useState()

    useEffect(() => {
        if (cardId && pid) {
            getElementByPattern({ pid: pid, pattern: `data_card*:${cardId}` }).then(async (res) => {
                if (res.code === 200) {
                    let _cardItem = res.data[0]
                    if (_cardItem.Props && typeof _cardItem.Props === "string") _cardItem.Props = JSON.parse(_cardItem.Props)
                    setCardItem(_cardItem)
                }
            })
        }
    }, [cardId, pid])

    return cardItem ? <RenderCardNewsByType
        id={id}
        pid={pid}
        cardItem={cardItem}
        style={style}
        className={className}
    /> : <div id={id} />
}