import styles from './view.module.css'
import { useEffect, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { SelectMultiple, Text, TextField } from "wini-web-components"
import { ComponentType, FEDataType, ValidateType } from "./da"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { Select1Form, SelectMultipleForm, SwitchForm, TextFieldForm } from "../../../project-component/component-form"
import { randomGID } from "../../../Utils"
import { RenderComponentByType } from "../../module/config"

export default function SettingKeysForm({ cols = [], rels = [], onChange = ({ newCols, newRels }) => { } }) {
    const methods = useForm({ shouldFocusError: false, defaultValues: { column: [], rel: [] } })
    const columns = useFieldArray({
        control: methods.control,
        name: 'column',
        keyName: undefined
    })
    const relatives = useFieldArray({
        control: methods.control,
        name: 'rel',
        keyName: undefined
    })
    const [selectedId, setSelectedId] = useState()
    const formRef = useRef()

    const settingComponentByType = () => {
        if (!selectedId) return <div />
        let _index = 0
        let item = methods.watch("column").find((e, i) => {
            if (e.Id === selectedId) {
                _index = i
                return true
            } else return false
        })
        if (item) {
            switch (item.Form.ComponentType) {
                case ComponentType.textField:
                    var _expand = <>
                        <div className='col' style={{ gap: '0.8rem' }}>
                            <Text className='label-3'>Validate</Text>
                            <SelectMultiple
                                key={'val-' + selectedId}
                                value={(item.Form.Validate ?? []).map(e => e.type)}
                                options={Object.keys(ValidateType).filter(key => isNaN(parseInt(key))).map(key => {
                                    return {
                                        id: ValidateType[key],
                                        name: key
                                    }
                                })}
                                placeholder='Chọn kiểu validate'
                                onChange={(v) => {
                                    const _tmpVal = methods.getValues(`column[${_index}].Form`).Validate ?? []
                                    columns.update(_index, {
                                        ...item, Form: {
                                            ...item.Form, Validate: v.map(e => {
                                                return _tmpVal.find(el => el.type === e) ?? { type: e }
                                            })
                                        }
                                    })
                                }}
                            />
                            {item.Form.Validate?.length && <div className='col' style={{ gap: '0.8rem', borderRadius: '0.8rem', backgroundColor: "#C0CBD83D", padding: '1.6rem ' }}>
                                {(item.Form.Validate ?? []).map(e => {
                                    switch (e.type) {
                                        case ValidateType.email:
                                            e.message ??= 'Không đúng định dạng email'
                                            break;
                                        case ValidateType.minLength:
                                            e.value ??= 3
                                            e.message ??= `Tối thiểu ${e.value} ký tự`
                                            break;
                                        case ValidateType.maxLength:
                                            e.value ??= 8
                                            e.message ??= `Tối da ${e.value} ký tự`
                                            break;
                                        case ValidateType.number:
                                            e.message ??= `Chỉ cho phép ký tự số`
                                            break;
                                        case ValidateType.phone:
                                            e.message ??= `Số điện thoại không hợp lệ`
                                            break;
                                        case ValidateType.greaterThan:
                                            e.value ??= 0
                                            e.message ??= `Giá trị phải lớn hơn ${e.value}`
                                            break;
                                        case ValidateType.greaterThanOrEqualTo:
                                            e.value ??= 0
                                            e.message ??= `Giá trị không được nhỏ hơn ${e.value}`
                                            break;
                                        case ValidateType.lessThan:
                                            e.value ??= 10
                                            e.message ??= `Giá trị phải nhỏ hơn ${e.value}`
                                            break;
                                        case ValidateType.lessThanOrEqualTo:
                                            e.value ??= 10
                                            e.message ??= `Giá trị không được lớn hơn ${e.value}`
                                            break;
                                        default:
                                            break;
                                    }
                                    return <div key={e.type + "-" + selectedId} className='col' style={{ padding: '0.4rem 0', gap: '0.4rem' }}>
                                        <Text className='semibold1'>{Object.keys(ValidateType).find(key => ValidateType[key] === e.type)}</Text>
                                        <div className='row' style={{ gap: '0.8rem', justifyContent: 'space-between' }}>
                                            <Text className='regular1'>Message</Text>
                                            <TextField
                                                className='regular1'
                                                style={{ flex: 1, maxWidth: '24rem', padding: '0.6rem 1.2rem' }}
                                                defaultValue={e.message}
                                                onComplete={(ev) => { ev.target.blur() }}
                                                onBlur={(ev) => {
                                                    const _tmpVal = methods.getValues(`column[${_index}].Form`).Validate ?? []
                                                    columns.update(_index, {
                                                        ...item, Form: {
                                                            ...item.Form, Validate: _tmpVal.map(el => {
                                                                if (e.type === el.type) return { ...el, message: ev.target.trim() }
                                                                return el
                                                            })
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            e.value != undefined ? <div className='row' style={{ gap: '0.8rem', justifyContent: 'space-between' }}>
                                                <Text className='regular1'>Value</Text>
                                                <TextField
                                                    className='regular1'
                                                    style={{ flex: 1, maxWidth: '24rem', padding: '0.6rem 1.2rem' }}
                                                    defaultValue={e.value}
                                                    type='number'
                                                    onComplete={(ev) => { ev.target.blur() }}
                                                    onBlur={(ev) => {
                                                        if (isNaN(parseFloat(ev.target.trim()))) {
                                                            ev.target.value = e.value
                                                        } else {
                                                            const _tmpVal = methods.getValues(`column[${_index}].Form`).Validate ?? []
                                                            columns.update(_index, {
                                                                ...item, Form: {
                                                                    ...item.Form, Validate: _tmpVal.map(el => {
                                                                        if (e.type === el.type) return { ...el, value: parseFloat(ev.target.trim()) }
                                                                        return el
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div> : null
                                        }
                                    </div>
                                })}
                            </div>}
                        </div>
                    </>
                    break;
                case ComponentType.switch:
                    _expand = <TextFieldForm
                        key={'tit-' + selectedId}
                        name={`column[${_index}].Form.Title`}
                        label="Title"
                        errors={methods.formState.errors}
                        register={methods.register}
                        onBlur={(ev) => { columns.update(_index, { ...item, Form: { ...item.Form, Title: ev.target.value.trim() } }) }}
                    />
                    break;
                case ComponentType.rate:
                    _expand = <TextFieldForm
                        key={'tit-' + selectedId}
                        name={`column[${_index}].Form.Title`}
                        label="Title"
                        errors={methods.formState.errors}
                        register={methods.register}
                        onBlur={(ev) => { columns.update(_index, { ...item, Form: { ...item.Form, Title: ev.target.value.trim() } }) }}
                    />
                    break;
                default:
                    if ([ComponentType.radio, ComponentType.select1, ComponentType.selectMultiple].includes(item.Form.ComponentType)) {
                        const _op = item.Form.Options ?? []
                        _expand = <>
                            <div className='row' style={{ gap: '0.8rem' }}>
                                <Text className='label-3'>Options</Text>
                                <button type='button' className='row icon-button20' onClick={() => { columns.update(_index, { ...item, Form: { ...item.Form, Options: [..._op, { key: randomGID() }] } }) }}>
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </button>
                            </div>
                            <div className='row' style={{ gap: '1.6rem' }}>
                                <Text className='semibold1' style={{ flex: 1, textAlign: "center" }}>Title</Text>
                                <Text className='semibold1' style={{ flex: 1, textAlign: "center" }}>Value</Text>
                                <div style={{ width: '2rem' }} />
                            </div>
                            {_op.map((e, i) => {
                                return <div key={e.id + "-" + selectedId + "-" + e.key} className='row' style={{ gap: '1.6rem', overflow: "visible", padding: '0.6rem 0' }}>
                                    <TextFieldForm
                                        register={methods.register}
                                        name={`column[${_index}].Form.Options[${i}].name`}
                                        errors={methods.formState.errors}
                                        onComplete={(ev) => { ev.target.blur() }}
                                        style={{ flex: 1 }}
                                        onBlur={(ev) => {
                                            columns.update(_index, {
                                                ...item, Form: {
                                                    ...item.Form, Options: _op.map(el => {
                                                        if (el.key === e.key) return { id: e.id, name: ev.target.value.trim() }
                                                        else return el
                                                    })
                                                }
                                            })
                                        }}
                                    />
                                    <TextFieldForm
                                        autoFocus={!e.id}
                                        register={methods.register}
                                        name={`column[${_index}].Form.Options[${i}].id`}
                                        errors={methods.formState.errors}
                                        onComplete={(ev) => { ev.target.blur() }}
                                        type={item.DataType === FEDataType.NUMBER ? "number" : item.DataType === FEDataType.MONEY ? "money" : "text"}
                                        style={{ flex: 1 }}
                                        onBlur={(ev) => {
                                            const _v = item.DataType === FEDataType.NUMBER || item.DataType === FEDataType.MONEY ? parseFloat(ev.target.value.trim().replaceAll(",", "")) : ev.target.value.trim()
                                            const _tmpOp = methods.getValues(`column[${_index}].Form`).Options ?? []
                                            if (_tmpOp.every((el) => el.id !== _v || e.key === el.key)) {
                                                columns.update(_index, {
                                                    ...item, Form: {
                                                        ...item.Form, Options: _tmpOp.map(el => {
                                                            if (el.ke === e.key) return { id: ev.target.value.trim(), name: e.name }
                                                            else return el
                                                        })
                                                    }
                                                })
                                            } else {
                                                methods.setError(`column[${_index}].Form.Options[${i}].id`, { message: "Vaue is existed in option list!" })
                                            }
                                        }}
                                    />
                                    <button type='button' className='row icon-button20' onClick={() => { columns.update(_index, { ...item, Form: { ...item.Form, Options: _op.filter((el) => el.key !== e.key) } }) }}>
                                        <FontAwesomeIcon icon={faMinusCircle} />
                                    </button>
                                </div>
                            })}
                        </>
                    }
                    break;
            }
            return <>
                <Text className='semibold3' style={{ borderBottom: '1px solid #00358033', paddingBottom: '0.8rem' }}>{item.Form.ComponentType}</Text>
                <TextFieldForm
                    key={'lb-' + selectedId}
                    name={`column[${_index}].Form.Label`}
                    label="Label"
                    errors={methods.formState.errors}
                    register={methods.register}
                    onBlur={(ev) => { columns.update(_index, { ...item, Form: { ...item.Form, Label: ev.target.value.trim() } }) }}
                />
                <TextFieldForm
                    key={'pl-' + selectedId}
                    name={`column[${_index}].Form.Placeholder`}
                    label="Placeholder"
                    errors={methods.formState.errors}
                    register={methods.register}
                    onBlur={(ev) => { columns.update(_index, { ...item, Form: { ...item.Form, Placeholder: ev.target.value.trim() } }) }}
                />
                {_expand}
            </>
        } else {
            item = methods.watch("rel").find((e, i) => {
                if (e.Id === selectedId) {
                    _index = i
                    return true
                } else return false
            })
            if (item) return <>
                <Text className='semibold3' style={{ borderBottom: '1px solid #00358033', paddingBottom: '0.8rem' }}>{item.Form.ComponentType}</Text>
                <TextFieldForm
                    key={'lb-' + selectedId}
                    name={`rel[${_index}].Form.Label`}
                    label="Label"
                    errors={methods.formState.errors}
                    register={methods.register}
                    onBlur={(ev) => { relatives.update(_index, { ...item, Form: { ...item.Form, Label: ev.target.value.trim() } }) }}
                />
                <TextFieldForm
                    key={'pl-' + selectedId}
                    name={`rel[${_index}].Form.Placeholder`}
                    label="Placeholder"
                    errors={methods.formState.errors}
                    register={methods.register}
                    onBlur={(ev) => { relatives.update(_index, { ...item, Form: { ...item.Form, Placeholder: ev.target.value.trim() } }) }}
                />
            </>
            else return <div />
        }
    }

    const clickDown = (id) => {
        const formChildren = [...formRef.current.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
        const _index = formChildren.findIndex(e => e.id === id)
        let _tmp = []
        if ((_index + 1) === formChildren.length) {
            _tmp = [
                {
                    id: formChildren[_index].id,
                    sort: 0,
                },
                ...formChildren.slice(0, _index).map((e, i) => {
                    return {
                        id: e.id,
                        sort: i + 1,
                    }
                })
            ]
        } else {
            _tmp = [...formChildren.slice(0, _index), formChildren[_index + 1], formChildren[_index], ...formChildren.slice(_index + 2)].map((e, i) => {
                return {
                    id: e.id,
                    sort: i,
                }
            })
        }
        methods.setValue("column", methods.getValues("column").map(e => {
            return { ...e, Form: { ...e.Form, Sort: _tmp.find(el => el.id === e.Id)?.sort ?? 0 } }
        }))
        methods.setValue("rel", methods.getValues("rel").map(e => {
            return { ...e, Form: { ...e.Form, Sort: _tmp.find(el => el.id === e.Id)?.sort ?? 0 } }
        }))
    }

    const clickUp = (id) => {
        const formChildren = [...formRef.current.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
        const _index = formChildren.findIndex(e => e.id === id)
        let _tmp = []
        if (_index === 0) {
            _tmp = [
                ...formChildren.slice(1).map((e, i) => {
                    return {
                        id: e.id,
                        sort: i,
                    }
                }),
                {
                    id: formChildren[_index].id,
                    sort: formChildren.length - 1,
                }
            ]
        } else {
            _tmp = [...formChildren.slice(0, _index - 1), formChildren[_index], ...formChildren.slice(_index - 1).filter(e => e.id !== id)].map((e, i) => {
                return {
                    id: e.id,
                    sort: i,
                }
            })
        }
        methods.setValue("column", methods.getValues("column").map(e => {
            return { ...e, Form: { ...e.Form, Sort: _tmp.find(el => el.id === e.Id)?.sort ?? 0 } }
        }))
        methods.setValue("rel", methods.getValues("rel").map(e => {
            return { ...e, Form: { ...e.Form, Sort: _tmp.find(el => el.id === e.Id)?.sort ?? 0 } }
        }))
    }

    useEffect(() => {
        if (cols.length) {
            methods.setValue("column", cols.map((e, i) => {
                e.Form = e.Form ? typeof e.Form === "string" ? JSON.parse(e.Form) : e.Form : { Required: true }
                e.Form.Sort ??= i
                if (!e.Form.Placeholder?.length) e.Form.Label ??= e.Name
                switch (e.DataType) {
                    case FEDataType.GID:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.STRING:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.BOOLEAN:
                        e.Form.ComponentType ??= ComponentType.checkbox
                        break;
                    case FEDataType.NUMBER:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.DATE:
                        e.Form.ComponentType ??= ComponentType.datePicker
                        break;
                    case FEDataType.DATETIME:
                        e.Form.ComponentType ??= ComponentType.dateTimePicker
                        break;
                    case FEDataType.MONEY:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.PASSWORD:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.HTML:
                        e.Form.ComponentType ??= ComponentType.ckEditor
                        break;
                    default:
                        break;
                }
                return e
            }).sort((a, b) => a.Form.Sort - b.Form.Sort))
            setSelectedId(cols.find(e => e.Name === "Name")?.Id)
        }
    }, [cols.length])

    useEffect(() => {
        if (rels.length) {
            methods.setValue("rel", rels.map((e, i) => {
                e.Form = e.Form && typeof e.Form === "string" ? JSON.parse(e.Form) : { Label: e.Column, ComponentType: ComponentType.select1, Required: true }
                e.Form.Sort ??= i
                return e
            }).sort((a, b) => a.Form.Sort - b.Form.Sort))
        }
    }, [rels.length])

    useEffect(() => {
        const _tmpCol = methods.getValues("column")
        if (_tmpCol.length) onChange({ newCols: _tmpCol })
    }, [methods.watch("column")])

    useEffect(() => {
        const _tmpRel = methods.getValues("rel")
        if (_tmpRel.length) onChange({ newRels: _tmpRel })
    }, [methods.watch("rel")])

    return <div className="row" style={{ flex: 1, width: '100%', height: '100%', alignItems: "stretch" }}>
        <div ref={formRef} className={`col ${styles['form-setting-container']}`} style={{ flex: 2, width: '100%', height: '100%', padding: '2.4rem', gap: '1.6rem', overflow: 'hidden auto', borderRight: "var(--neutral-main-border-color)" }}>
            {columns.fields.map((_col, i) => {
                let _listCom = []
                switch (_col.DataType) {
                    case FEDataType.GID:
                        _listCom = [ComponentType.textField, ComponentType.select1, ComponentType.selectMultiple]
                        break;
                    case FEDataType.STRING:
                        _listCom = [ComponentType.textField, ComponentType.select1, ComponentType.textArea, ComponentType.selectMultiple, ComponentType.radio, ComponentType.ckEditor, ComponentType.upload]
                        break;
                    case FEDataType.BOOLEAN:
                        _listCom = [ComponentType.checkbox, ComponentType.switch, ComponentType.radio, ComponentType.select1]
                        break;
                    case FEDataType.NUMBER:
                        _listCom = [ComponentType.textField, ComponentType.select1, ComponentType.rate]
                        break;
                    case FEDataType.DATE:
                        _listCom = [ComponentType.datePicker]
                        break;
                    case FEDataType.DATETIME:
                        _listCom = [ComponentType.dateTimePicker]
                        break;
                    case FEDataType.MONEY:
                        _listCom = [ComponentType.textField, ComponentType.select1]
                        break;
                    case FEDataType.PASSWORD:
                        _listCom = [ComponentType.textField]
                        break;
                    case FEDataType.HTML:
                        _listCom = [ComponentType.textField, ComponentType.ckEditor]
                        break;
                    default:
                        break;
                }
                return <div key={_col.Id} id={_col.Id} className={`${styles['component-form-setting-block']} ${_col.Id === selectedId ? styles["selected"] : ""} col`} setting-type="column" style={{ order: _col.Form.Sort }} onClick={() => { setSelectedId(_col.Id) }}>
                    <RenderComponentByType fieldItem={_col} methods={methods} />
                    {
                        _col.Id === selectedId ? <>
                            <div className='col divider' />
                            <div className={`row ${styles['simple-setting']}`} style={{ gap: '0.8rem' }}>
                                <SwitchForm
                                    control={methods.control}
                                    name={`column[${i}].Form.Required`}
                                    label='Required'
                                    size={'1.8rem'}
                                    disabled={_col.Name === "Name"}
                                    onChange={(v) => { columns.update(i, { ..._col, Form: { ..._col.Form, Required: v } }) }}
                                />
                                <div style={{ flex: 1 }} />
                                <button type='button' className='row icon-button20' onClick={() => { clickUp(_col.Id) }}>
                                    <FontAwesomeIcon icon={faArrowUp} />
                                </button>
                                <button type='button' className='row icon-button20' onClick={() => { clickDown(_col.Id) }}>
                                    <FontAwesomeIcon icon={faArrowDown} />
                                </button>
                                {_listCom.length ? <Select1Form
                                    readonly
                                    control={methods.control}
                                    disabled={_col.Name === "Name" || _listCom.length === 1}
                                    style={{ width: '15rem', padding: '0.4rem', textAlign: "center" }}
                                    errors={methods.formState.errors}
                                    name={`column[${i}].Form.ComponentType`}
                                    options={_listCom.map(e => {
                                        return { id: e, name: e }
                                    })}
                                    onChange={(v) => {
                                        let _tmp = { ..._col.Form, ComponentType: v.id }
                                        if ([ComponentType.radio, ComponentType.select1, ComponentType.selectMultiple].includes(v.id) && !_tmp.Options?.length) _tmp.Options = [{ key: randomGID() }]
                                        columns.update(i, { ..._col, Form: _tmp })
                                    }}
                                /> : null}
                            </div>
                        </> : null
                    }
                </div>
            })}
            {relatives.fields.map((_rel, i) => {
                switch (_rel.ComponentType) {
                    case ComponentType.selectMultiple:
                        var _component = <SelectMultipleForm
                            required={_rel.Form.Required}
                            control={methods.control}
                            errors={methods.formState.errors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={[]}
                        />
                        break;
                    default:
                        _component = <Select1Form
                            required={_rel.Form.Required}
                            control={methods.control}
                            errors={methods.formState.errors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={[]}
                        />
                        break;
                }
                return <div key={_rel.Id} id={_rel.Id} className={`${styles['component-form-setting-block']} ${_rel.Id === selectedId ? styles["selected"] : ""} col`} setting-type="relative" style={{ order: _rel.Form.Sort }} onClick={() => { setSelectedId(_rel.Id) }}>
                    {_component}
                    {
                        _rel.Id === selectedId ? <>
                            <div className='col divider' />
                            <div className='row' style={{ gap: '0.8rem' }}>
                                <SwitchForm
                                    control={methods.control}
                                    name={`rel[${i}].Form.Required`}
                                    label='Required'
                                    size={'1.8rem'}
                                    onChange={(v) => { relatives.update(i, { ..._rel, Form: { ..._rel.Form, Required: v } }) }}
                                />
                                <div style={{ flex: 1 }} />
                                <button type='button' className='row icon-button20' onClick={() => { clickUp(_rel.Id) }}>
                                    <FontAwesomeIcon icon={faArrowUp} />
                                </button>
                                <button type='button' className='row icon-button20' onClick={() => { clickDown(_rel.Id) }}>
                                    <FontAwesomeIcon icon={faArrowDown} />
                                </button>
                                <Select1Form
                                    disabled
                                    control={methods.control}
                                    placeholder={ComponentType.select1}
                                    style={{ width: '15rem', padding: '0.4rem', textAlign: "center" }}
                                    errors={methods.formState.errors}
                                    name={`rel[${i}].Form.ComponentType`}
                                    options={[ComponentType.selectMultiple, ComponentType.select1].map(e => {
                                        return { id: e, name: e }
                                    })}
                                />
                            </div>
                        </> : null
                    }
                </div>
            })}
        </div>
        <div className='col' style={{ flex: 1, width: '100%', height: '100%', padding: '2.4rem', gap: '0.8rem', overflow: 'hidden auto' }}>
            {settingComponentByType()}
        </div>
    </div>
}