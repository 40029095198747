import styles from './cardProduct.module.css'
import React from "react"
import { CSSProperties, ReactNode } from "react"
import { OutlineHeart, OutlineShoppingCart, OutlineSwapHorizontal } from '../../../assets/icon'
import { Rating, Text } from 'wini-web-components'

interface CardData {
    Name: string,
    Image: string,
    Subtitle?: string,
    DateCreated?: string,
    Description: string,
    Options?: Array<{ Id: string, Icon: ReactNode, Name: string, Items: Array<{ Id: string, Name: string | ReactNode }>, children?: ReactNode }>,
    Price: number,
    Rate: number,
    RateQuantity: number
}

interface Props {
    data: CardData,
    className?: string,
    style?: CSSProperties,
    direction?: "col" | "row", // row or column,
    mediaType?: "contain" | "cover",
    mediaSize?: number | string,
}

export default function CardProduct({ data, className = '', style = {}, direction = "col", mediaType = "contain", mediaSize = "14.8rem" }: Props) {
    return <div className={`${styles['card-container']} ${className.split(' ').filter(e => e !== 'row' && e !== 'col').join(' ')} ${styles[direction]}`} style={style}>
        {data.Image ? <img className={styles['image-' + mediaType]} src={data.Image} style={direction === 'row' ? { height: mediaSize } : { width: mediaSize }} alt='' /> : undefined}
        <div className={`col ${styles['description-container']}`} style={{ gap: '0.8rem', flex: direction === 'row' ? 1 : undefined }}>
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <Text className="heading-7" maxLine={1}>{data.Name ?? 'Lorem Ipsum is simply dummy text'}</Text>
                <Text className="subtitle-4" maxLine={1}>{data.Subtitle ?? 'Subtitle'}</Text>
            </div>
            {data.Description && <Text className="body-3" maxLine={3}>{data.Description}</Text>}
            {
                data.Options && <div className={`col ${styles['options-container']}`} >
                    {data.Options.map((e) => e.children ?? <div key={e.Id} className="row">
                        <div className='row' style={{ gap: '0.8rem' }}>
                            {e.Icon}
                            <Text className="label-4" maxLine={1}>{e.Name}</Text>
                        </div>
                        <div className='row' style={{ gap: '0.8rem' }}>{e.Items.map((e) => e.Name)}</div>
                    </div>)}
                </div>
            }
            <div className={`row ${styles['combo-action-container']}`} style={{ width: '100%', gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="heading-6">${data.Price}</Text>
                    <Text className="subtitle-4">{data.RateQuantity} reviews</Text>
                    <Rating value={data.Rate} size={'2rem'} />
                </div>
                <button type='button' className='row icon-button32'><OutlineSwapHorizontal size={'2rem'} /></button>
                <button type='button' className='row icon-button32'><OutlineHeart size={'2rem'} /></button>
                <button type='button' className='row icon-button32'><OutlineShoppingCart size={'2rem'} /></button>
            </div>
        </div>
    </div>
}