import { useForm } from "react-hook-form";
import { Text, ToastMessage } from "wini-web-components";
import { ImportFileForm, TextAreaForm, TextFieldForm } from "../../../project-component/component-form";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { ProjectController } from "./controller";

export default function CreateProject() {
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()


    const onSubmit = async (ev) => {
        const newProject = {
            Id: window.crypto.randomUUID().replaceAll('-', ''),
            Name: ev.Name,
            Description: ev.Description,
            CustomerId: "b081a926bb0c4318aebce949de0ce998",
            DateCreated: (new Date()).getTime(),
        }
        console.log(newProject)
        const res = await ProjectController.add([newProject])
        if (res.code === 200) {
            navigate('/setting-project', {
                state: { project: newProject }
            })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    return <div className="col view" style={{ alignItems: 'center', paddingTop: '6%', overflow: 'hidden auto' }}>
        <div className="col" style={{ gap: '3.2rem' }}>
            <Text className="heading-3">Tạo phần mềm mới</Text>
            <TextFieldForm
                className={'row'}
                required
                style={{ width: '68rem', gap: '0.4rem' }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Name'}
                label={'Tên phần mềm'}
            />
            <ImportFileForm
                required
                control={methods.control}
                className={'row'}
                style={{ width: '68rem', gap: '0.4rem' }}
                errors={methods.formState.errors}
                name="LogoId"
                label="Logo"
                title="Chọn ảnh logo"
                direction="column"
            />
            <TextAreaForm
                className={'row'}
                required
                style={{ width: '68rem', gap: '0.4rem' }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Description'}
                label={'Mô tả phân mềm'}
                placeholder={'Nhập mô tả phần mềm muốn xây dựng: quản trị nhân sự, lương thuế,...'}
            />
            <div className={'row'} style={{ justifyContent: 'end' }}>
                <button type="button" className={`row ${methods.watch('Name') && methods.watch('Description') ? 'button-primary' : 'button-disabled'}`} onClick={methods.handleSubmit(onSubmit)}>
                    <Text className="button-text-3">Tiếp tục</Text>
                </button>
            </div>
        </div>
        <NavLink to={'/home'} className={'icon-button32 row'} style={{ position: 'fixed', right: '6%' }}>
            <FontAwesomeIcon icon={faXmarkCircle} />
        </NavLink>
    </div>
}