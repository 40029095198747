import React, { CSSProperties, useEffect, useRef } from "react";
import { useState } from "react";
import { BaseDA } from "../da/baseDA";

export function SvgByLink(params: { link: string, style?: CSSProperties, className?: string }) {
    const [data, setData] = useState<string>("")
    const ref = useRef<any>()

    useEffect(() => {
        if (params.link) {
            fetch(params.link).then(async (res) => {
                let _result = await res.text()
                setData(_result)
            })
        }
    }, [params.link])

    return <div ref={ref} style={params.style} className={`svg-container ${params.className ?? ""}`} dangerouslySetInnerHTML={{ __html: data }}/>
}