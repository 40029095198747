import { Button, ComponentStatus, Tag, Text } from 'wini-web-components';
import styles from './cardNews.module.css';
import React, { CSSProperties, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { OutlineAppMoreV, OutlineArrowRight, OutlineChevronRight } from '../../../assets/icon';

interface CardData {
    Name: string,
    Image: string,
    Subtitle?: string,
    DateCreated?: string,
    Description: string | ReactNode,
    Link?: string,
    ListItem?: Array<{ Id: string, Icon?: ReactNode, Name: string, children?: ReactNode }>,
    Tags?: string,
    Statistics1?: { Id: string, Name: string, Value: number | string, children?: ReactNode },
    Statistics2?: { Id: string, Name: string, Value: number | string, children?: ReactNode },
}

interface Props {
    id?: string,
    data: CardData,
    className?: string,
    style?: CSSProperties,
    direction?: "col" | "row", // row or column,
    mediaType?: "contain" | "cover" | "hug",
    mediaSize?: number | string,
}

export default function CardNews({ id, data, className = '', style = {}, direction = "col", mediaType = "contain", mediaSize = "4.8rem" }: Props) {
    if (mediaType === "hug") direction = "col";
    return <div id={id} className={`${styles['card-container']} ${className.split(' ').filter(e => e !== 'row' && e !== 'col').join(' ')} ${styles[direction]}`} style={style}>
        {data.Image && mediaType !== "hug" ? <img className={styles['image-' + mediaType]} src={data.Image} style={direction === 'row' ? { height: mediaSize, maxWidth: '41.2rem' } : { width: mediaSize }} alt='' /> : undefined}
        <div className={`col ${styles['description-container']}`}>
            <div className="row" style={{ gap: '1.6rem' }}>
                {data.Image && mediaType === "hug" ? <img className={styles['image-' + mediaType]} src={data.Image} style={{ width: mediaSize }} alt='' /> : undefined}
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="heading-7" maxLine={1}>{data.Name ?? ''}</Text>
                    <Text className="subtitle-4" maxLine={1}>{data.Subtitle ?? ''}</Text>
                </div>
            </div>
            {data.Description ? typeof data.Description === "string" ? <div className={`comp-text body-3 ${styles['description-text']}`} style={{ '--max-line': 3 } as any} dangerouslySetInnerHTML={{ __html: data.Description }} /> : data.Description : undefined}
            {data.ListItem && <div className={`col ${styles['list-item-container']}`}>
                {data.ListItem.map((e) => e.children ?? <div key={e.Id} className="row" style={{ gap: '0.8rem' }}>
                    {e.Icon}
                    <Text className="label-4" style={{ flex: 1, textAlign: 'start' }} maxLine={1}>{e.Name}</Text>
                </div>)}
            </div>}
            {data.Link ? <NavLink to={data.Link} className={`row ${styles['link-to']}`}>
                <Text className='button-text-5'>See more</Text>
                <OutlineArrowRight color={"var(--primary-main-color)"} size={'1.2rem'} />
            </NavLink> : undefined}
            {data.Tags && <div className='row' style={{ gap: '0.8rem', width: 'fit-content' }}>
                {data.Tags.split(',').map((t, i) => <Tag
                    key={t}
                    title={t}
                    status={ComponentStatus.INFOR}
                    className={styles['tag-container']}
                    suffix={<OutlineChevronRight size={'1.2rem'} />}
                />)}
            </div>}
            {(data.Statistics1 || data.Statistics2) && <div className={`row ${styles['statistics-container']}`}>
                {data.Statistics1 ? (data.Statistics1.children ?? <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="heading-6" maxLine={1} style={{ width: '100%' }}>{data.Statistics1.Value}</Text>
                    <Text className="subtitle-4" maxLine={1} style={{ width: '100%' }}>{data.Statistics1.Name}</Text>
                </div>) : null}
                {data.Statistics2 ? (data.Statistics2.children ?? <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="heading-6" maxLine={1} style={{ width: '100%' }}>{data.Statistics2.Value}</Text>
                    <Text className="subtitle-4" maxLine={1} style={{ width: '100%' }}>{data.Statistics2.Name}</Text>
                </div>) : null}
            </div>}
        </div>
    </div>
}