import './button.module.css'
import { onSelectedLayer } from "../pageView"
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Select1, Text, TextField } from "wini-web-components"
import React, { useEffect, useState } from 'react'
import { PageActions } from '../reducer'
import { ColorPicker } from './functions/funtions'
import { Ultis } from '../../../../Utils'
import { CheckboxForm } from '../../../../project-component/component-form'

export default function SettingRadio({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const layers = useSelector((store) => store.page.data?.layers)
    const dispatch = useDispatch()

    const _iframe = document.getElementById("iframe-render-page-view")


    const updateStyle = (style) => {
        let _newSettingStyle = { ...selectedLayer.Setting.style, ...style }
        for (const prop in _newSettingStyle) {
            if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
        }
        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle } }])
    }

    useEffect(() => {
        if (!_iframe || !selectedLayer) return
        setTimeout(() => {
            const _newElement = _iframe.contentWindow.document.getElementById(selectedLayer?.Id)

            const _newStyle = window.getComputedStyle(_newElement)

            if (_newElement) {
                const _rootFz = parseFloat(window.getComputedStyle(_iframe.contentWindow.document.documentElement)['font-size'].replace("px", ""))

                if (selectedLayer.Setting.size.includes("px")) {
                    methods.setValue('size', selectedLayer.Setting.size.replace("px", ""))
                    methods.setValue('size-unit', "px")
                } else {
                    methods.setValue('size', selectedLayer.Setting.size.replace("rem", ""))
                    methods.setValue('size-unit', "rem")
                }

                const _isActive = Ultis.rgbToHex(_newStyle.borderColor)
                methods.setValue('disabled', selectedLayer.Setting.disabled ?? false)
                methods.setValue('active-color', _isActive.substring(0, 7))
                methods.setValue('active-color-opacity', `${Ultis.hexToPercent(_isActive.substring(7))}`)

                if (selectedLayer.Setting.style.borderWidth?.includes("px")) {
                    methods.setValue('border-width', _newStyle['border-width'].replace("px", ""))
                    methods.setValue('border-unit', "px")
                } else if (selectedLayer.Setting.style.borderWidth?.includes("%")) {
                    methods.setValue('border-width', parseFloat(_newStyle['border-width'].replace("%", "")))
                    methods.setValue('border-unit', "%")
                } else {
                    methods.setValue('border-width', (parseFloat(_newStyle['border-width'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                    methods.setValue('border-unit', "rem")
                }
            }
        }, 100)
    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faBars} /></button>
        </div>

        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Box Properties</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faChevronDown} /></button>

        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem 0.8rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold1" style={{ color: '#999999' }}>Size</Text>
                    <TextField
                        className="regular1"
                        name="size"
                        register={methods.register('size', {
                            onBlur: (ev) => {
                                PageActions.updateLayerAll(dispatch, layers.map(e => {
                                    if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, size: ev.target.value.trim() + methods.getValues('size-unit') } }
                                    else return e
                                }))
                            }
                        })}
                        defaultValue={methods.getValues('size')}
                        placeholder="size"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('size-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('size-unit')
                                if (_tmp !== v.id) {
                                    PageActions.updateLayerAll(dispatch, layers.map(e => {
                                        if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, size: v.id === 'rem' ? `${parseFloat(methods.getValues('size')) / 10}rem` : `${parseFloat(methods.getValues('size')) * 10}${v.id}` } }
                                        else return e
                                    }))
                                }
                            }}
                        />}
                    />
                </div>
            </div>
            <div className="col" style={{ gap: '0.4rem', paddingTop: '0.2rem' }}>
                <CheckboxForm label={<Text className="semibold1" >Disabled</Text>} name="disabled" size={16} control={methods.control} onChange={(v) => {
                    PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, disabled: v } }])
                }} />
                {methods.watch("active-color") && <Text className="semibold1" style={{ color: '#999999' }}>Active color</Text>}
                {methods.watch("active-color") ? <ColorPicker
                    colorName={'active-color'}
                    colorOpcaityName={'active-color-opacity'}
                    register={methods.register}
                    colorValue={methods.watch("active-color") + Ultis.percentToHex(parseFloat(methods.watch('active-color-opacity')))}
                    onChange={({ colorValue, opacity }) => PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, activeColor: colorValue, defaultChecked: methods.getValues('disabled'), style: { ...selectedLayer.Setting.style, borderColor: colorValue } } }])
                    }
                /> : null}
            </div>
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <Text className="semibold1" style={{ color: '#999999' }}>Border width</Text>
                <TextField
                    className="regular1"
                    name="border-width"
                    register={methods.register('border-width', { onBlur: (ev) => { updateStyle({ borderWidth: ev.target.value.trim() + methods.getValues('border-unit') }) } })}
                    placeholder="border width"
                    type="number"
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('border-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('border-unit')
                            if (_tmp !== v.id) {
                                updateStyle({ borderWidth: v.id === 'rem' ? `${parseFloat(methods.getValues('border-width')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-width')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-width'))}${v.id}` })
                            }
                        }}
                    />}
                />
            </div>

        </div>

    </>
}