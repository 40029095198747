import { forwardRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage } from "wini-web-components";
import { RenderComponentByType, validateForm } from "../config";
import { ComponentType, FEDataType, ValidateType } from "../../home/table/da";
import { randomGID, Ultis } from "../../../Utils";
import { DataController } from "../controller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { TableController } from "../../home/table/controller";
import { Select1Form, SelectMultipleForm } from "../../../project-component/component-form";
import { BaseDA } from "../../../da/baseDA";
import ConfigApi from "../../../da/configApi";

const PopupAddEditData = forwardRef(function PopupAddEditData(data, ref) {
    const _dataController = new DataController({ pid: data.pid, module: data.module })
    const [item, setItem] = useState()
    const [column, setColumn] = useState([])
    const [relative, setRelative] = useState([])
    const dialogRef = useRef()

    const getSetting = async () => {
        const _colController = new TableController(data.pid, "column")
        const _relController = new TableController(data.pid, "rel")
        _colController.getListSimple({
            page: 1,
            size: 100,
            query: `@TableName:{${data.module}} -@Name:{Id | DateCreated}`,
            returns: ["Id", "Name", "DataType", "Query", "Form"]
        }).then(res => {
            if (res.count) setColumn(res.data.map((e, i) => {
                e.Form = e.Form ? JSON.parse(e.Form) : {}
                e.Form.Sort ??= i
                if (!e.Form.Placeholder?.length) e.Form.Label ??= e.Name
                switch (e.DataType) {
                    case FEDataType.GID:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.STRING:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.BOOLEAN:
                        e.Form.ComponentType ??= ComponentType.checkbox
                        break;
                    case FEDataType.NUMBER:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.DATE:
                        e.Form.ComponentType ??= ComponentType.datePicker
                        break;
                    case FEDataType.DATETIME:
                        e.Form.ComponentType ??= ComponentType.dateTimePicker
                        break;
                    case FEDataType.MONEY:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.PASSWORD:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    default:
                        break;
                }
                return e
            }).sort((a, b) => a.Form.Sort - b.Form.Sort))
        })
        _relController.getListSimple({
            page: 1,
            size: 100,
            query: `@TableFK:{${data.module}}${data.rel ? ` -@Column:{${data.rel.Column}}` : ""}`,
            returns: ["Id", "Column", "Form", "TablePK", "Query"]
        }).then(res => {
            if (res.count) setRelative(res.data.map((e, i) => {
                e.Form = e.Form ? JSON.parse(e.Form) : { Label: e.Column, ComponentType: ComponentType.select1, Required: true }
                e.Form.Sort ??= i
                return e
            }).sort((a, b) => a.Form.Sort - b.Form.Sort))
        })
    }

    const getData = async () => {
        if (data.id) {
            _dataController.getById(data.id).then(res => {
                if (res.code === 200) setItem(res.data)
                else ToastMessage.errors(res.message)
            })
        } else if (data.rel) {
            const defaultItem = {}
            defaultItem[data.rel.Column] = data.rel.value
            setItem(defaultItem)
        }
    }

    useEffect(() => {
        getSetting()
        getData()
    }, [])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <Dialog ref={dialogRef} />
        <div className='popup-header row' style={{ gap: '0.8rem', padding: '0.8rem', paddingLeft: '2.4rem' }}>
            <Text className="heading-8" style={{ flex: 1 }}>{data.item?.Name ?? `Add new ${data.module}`}</Text>
            <button type="button" className="row icon-button32" onClick={() => { closePopup(ref) }}><FontAwesomeIcon icon={faXmark} color="#61616b" /></button>
        </div>
        <ViewMode
            cols={column.filter(e => !e.Query?.length)}
            rels={relative}
            item={item}
            pid={data.pid}
            module={data.module}
            onCancel={() => {
                showDialog({
                    ref: dialogRef,
                    alignment: DialogAlignment.center,
                    status: ComponentStatus.WARNING,
                    submitTitle: "Submit",
                    cancelTitle: "Cancel",
                    title: 'Confirm cancel ' + (data.item ? 'update' : 'add'),
                    onSubmit: () => { closePopup(ref) }
                })
            }}
            onSuccess={() => {
                closePopup(ref)
                ToastMessage.success(`${data.item ? 'Update' : 'Add'} ${data.module} successfully!`)
                data.onSuccess()
            }}
        />
    </div>
})

export default PopupAddEditData

const ViewMode = ({ cols = [], rels = [], item, pid, module, onCancel, onSuccess }) => {
    const _dataController = new DataController({ pid: pid, module: module })
    const methods = useForm({ shouldFocusError: false, defaultValues: { Id: randomGID() } })
    const methodOptions = useForm({ shouldFocusError: false })

    const onSubmit = async (ev) => {
        let dataItem = { ...ev }
        dataItem.DateCreated ??= (new Date()).getTime()
        for (let _col of cols) {
            if (_col.Name === "DateCreated") {
                dataItem[_col.Name] ??= (new Date()).getTime()
            } else if (dataItem[_col.Name] != undefined) {
                if (!_col.Query) {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            break;
                        case FEDataType.STRING:
                            if (_col.Form.ComponentType === ComponentType.upload) {
                                const res = await BaseDA.uploadFiles([ev[_col.Name]])
                                if (res[0]) dataItem[_col.Name] = ConfigApi.imgUrl + res[0].url
                            }
                            break;
                        case FEDataType.BOOLEAN:
                            break;
                        case FEDataType.NUMBER:
                            dataItem[_col.Name] = typeof dataItem[_col.Name] === 'string' ? parseFloat(dataItem[_col.Name]) : dataItem[_col.Name]
                            break;
                        case FEDataType.DATE:
                            dataItem[_col.Name] = Ultis.stringToDate(dataItem[_col.Name]).getTime()
                            break;
                        case FEDataType.DATETIME:
                            dataItem[_col.Name] = Ultis.stringToDate(dataItem[_col.Name], 'dd/mm/yyyy hh:mm:ss').getTime()
                            break;
                        case FEDataType.MONEY:
                            dataItem[_col.Name] = parseInt(dataItem[_col.Name].replaceAll(',', ''))
                            break;
                        case FEDataType.PASSWORD:
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        for (let _rel of rels) {
            if (dataItem[_rel.Column] && typeof dataItem[_rel.Column] !== 'string')
                dataItem[_rel.Column] = dataItem[_rel.Column].join(",")
        }
        const _val = await validateForm({
            list: cols.filter(e => e.Validate?.length),
            formdata: dataItem
        })
        // Cập nhật lỗi vào React Hook Form
        if (_val && Object.keys(_val).length > 0) {
            Object.keys(_val).forEach((field) => {
                methods.setError(field, { message: _val[field].join(', ') });
            });
            return;
        }

        // Nếu có lỗi, dừng lại không thực hiện submit
        const res = await _dataController.add([dataItem])
        if (res.code !== 200) return ToastMessage.errors(res.message)
        onSuccess()
    }

    const onError = (ev) => { }

    useEffect(() => {
        if (item) {
            Object.keys(item).forEach(prop => {
                const _col = cols.find(e => e.Name === prop)
                const _rel = rels.find(e => e.Column === prop)
                if (_col) {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            methods.setValue(prop, item[prop])
                            break;
                        case FEDataType.STRING:
                            methods.setValue(prop, item[prop])
                            break;
                        case FEDataType.BOOLEAN:
                            methods.setValue(prop, item[prop])
                            break;
                        case FEDataType.NUMBER:
                            methods.setValue(prop, typeof item[prop] === 'string' ? parseFloat(item[prop]) : item[prop])
                            break;
                        case FEDataType.DATE:
                            methods.setValue(prop, Ultis.datetoString(new Date(typeof item[prop] === 'string' ? parseInt(item[prop]) : item[prop])))
                            break;
                        case FEDataType.DATETIME:
                            methods.setValue(prop, Ultis.datetoString(new Date(typeof item[prop] === 'string' ? parseInt(item[prop]) : item[prop]), 'dd/mm/yyyy hh:mm:ss'))
                            break;
                        case FEDataType.MONEY:
                            methods.setValue(prop, Ultis.money(item[prop]))
                            break;
                        case FEDataType.PASSWORD:
                            methods.setValue(prop, item[prop])
                            break;
                        default:
                            break;
                    }
                } else if (_rel) {
                    const _tmpParse = item[prop]?.length ? item[prop].split(",") : []
                    methods.setValue(prop, _rel.ComponentType === ComponentType.selectMultiple ? _tmpParse : _tmpParse[0])
                } else {
                    methods.setValue(prop, item[prop])
                }
            })
        } else {
            cols.filter((e) => e.DefaultValue != undefined).forEach((_col) => {
                switch (_col.DataType) {
                    case FEDataType.GID:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.STRING:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.BOOLEAN:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.NUMBER:
                        methods.setValue(_col.Name, typeof _col.DefaultValue === 'string' ? parseFloat(_col.DefaultValue) : _col.DefaultValue)
                        break;
                    case FEDataType.DATE:
                        methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.DefaultValue === 'string' ? parseInt(_col.DefaultValue) : _col.DefaultValue)))
                        break;
                    case FEDataType.DATETIME:
                        methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.DefaultValue === 'string' ? parseInt(_col.DefaultValue) : _col.DefaultValue), 'dd/mm/yyyy hh:mm:ss'))
                        break;
                    case FEDataType.MONEY:
                        methods.setValue(_col.Name, Ultis.money(_col.DefaultValue))
                        break;
                    case FEDataType.PASSWORD:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    default:
                        break;
                }
            })
        }
    }, [item])

    useEffect(() => {
        rels.forEach((_rel) => {
            const _dataPKController = new DataController({ pid: pid, module: _rel.TablePK })
            _dataPKController.getListSimple({ page: 1, size: 20, query: _rel.Query }).then((res) => {
                methodOptions.setValue(`${_rel.Column}_Options`, res?.data ?? [])
            })
        })
    }, [rels])

    return <form className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: '1.6rem 2.4rem', gap: '1.6rem', overflow: 'hidden auto' }}>
            {cols.map((e, i) => <RenderComponentByType key={e.Id} fieldItem={e} methods={methods} />)}
            {rels.map((_rel, _) => {
                switch (_rel.ComponentType) {
                    case ComponentType.selectMultiple:
                        return <SelectMultipleForm
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            control={methods.control}
                            errors={methods.clearErrors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={(methodOptions.watch(`${_rel.Column}_Options`) ?? []).map(e => {
                                return {
                                    id: e.Id,
                                    name: e.Name
                                }
                            })}
                        />
                    default:
                        return <Select1Form
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            control={methods.control}
                            errors={methods.clearErrors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={(methodOptions.watch(`${_rel.Column}_Options`) ?? []).map(e => {
                                return {
                                    id: e.Id,
                                    name: e.Name
                                }
                            })}
                        />
                }
            })}
        </div>
        <div className="row popup-footer">
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "var(--neutral-background)", color: "var(--neutral-color-subtitle)" }}
                onClick={onCancel}
            />
            <Button
                label="Save"
                className="button-primary"
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(onSubmit, onError)}
            />
        </div>
    </form>
}