import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'wini-web-components';
import { Home } from './router/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Provider } from 'react-redux';
import { store } from './store';
import LandingLayout from './screen/module/landing/view';
import React from 'react';


library.add(fas, fab, far);
function App() {
  return <Provider store={store} stabilityCheck="always">
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<LandingLayout />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  </Provider>
}

export default App;