import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { ProjectItem } from './da'
import { ProjectController } from './controller'

interface ProjectSimpleResponse {
    data?: ProjectItem,
    onLoading?: boolean,
    type?: string
}

const initState: ProjectSimpleResponse = {
    data: undefined,
    onLoading: false
}

export const projectSlice = createSlice({
    name: 'project',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETINFOR':
                    state.data = action.payload.data
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = projectSlice.actions

export default projectSlice.reducer

export class ProjectActions {
    static getInfor = (dispatch: Dispatch<UnknownAction>, id: string) => {
        dispatch(onFetching())
        ProjectController.getByIds([id]).then(res => {
            if (res.code === 200) {
                dispatch(handleActions({
                    type: 'GETINFOR',
                    data: res.data[0],
                }))
            }
        })
    }
}