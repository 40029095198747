import styles from './index.module.css'
import { CSSProperties, useEffect, useState } from "react";
import { CardItem, CardType } from "../../da";
import React from "react";
import CardNews from "../../card/cardNews";
import CardProduct from "../../card/cardProduct";
import { DataController } from "../../controller";
import { ToastMessage } from "wini-web-components";
import { TableController } from "../../../home/table/controller";
import { RelativeItem } from "../../../home/table/da";
import { useForm } from 'react-hook-form';

export default function RenderCardNewsByType(props: { id?: string, pid: string, cardItem: CardItem, data?: any, className?: string, style?: CSSProperties }) {
    const methods = useForm<any>({ shouldFocusError: false })
    const [data, setData] = useState({ data: [], totalCount: undefined })
    const [_rels, setRels] = useState<Array<RelativeItem>>([])

    const mapRelativeData = async () => {
        if (!props.cardItem.Props) return undefined
        //
        let _props = typeof props.cardItem.Props === "string" ? JSON.parse(props.cardItem.Props) : props.cardItem.Props
        const _propNames = Object.keys(_props).map(k => k)
        if (_propNames.length === 0) return undefined
        //
        const _relController = new TableController(props.pid, "rel")
        const _relIds: Array<string> = []
        _propNames.forEach((k) => {
            const _id = typeof _props[k] === "string" ? _props[k] : _props[k]?.RelativeId
            if (_id && _relIds.every(id => id !== _id)) _relIds.push(_id)
        })
        const _rels = await _relController.getByListId(_relIds)
        if (_rels.code !== 200) return undefined
        setRels(_rels.data.filter((e: any) => e !== undefined))
    }

    useEffect(() => {
        mapRelativeData()
    }, [props.cardItem])

    useEffect(() => {
        if (_rels.length && data.data.length) {
            Object.keys(props.cardItem.Props).forEach((k) => {
                const _tmpProps = (props.cardItem.Props as any)[k]
                const _relItem = _rels.find(e => typeof _tmpProps === "string" ? _tmpProps === e.Id : e.Id === _tmpProps?.RelativeId)
                if (_relItem) {
                    if (typeof _tmpProps === "string") {
                        const _tbPKController = new DataController({ pid: props.pid, module: _relItem.TablePK })
                        let _idsByPropName = data.data.map((e: any) => e[_relItem.Column]?.split(",") ?? []).reduce((a, b) => a.concat(b), [])
                        const _tmpPkItems = methods.getValues(k) ?? []
                        if (_tmpPkItems.length) {
                            _idsByPropName = _idsByPropName.filter((id: string) => _tmpPkItems.every((e: any) => e.Id !== id))
                        }
                        _tbPKController.getByListId(_idsByPropName).then((res) => {
                            if (res.code === 200) methods.setValue(k, [..._tmpPkItems, ...res.data])
                        })
                    } else {
                        const _tbFKController = new DataController({ pid: props.pid, module: _relItem.TableFK })
                        _tbFKController.group({
                            searchRaw: `@${_relItem.Column}:{${data.data.map((e: any) => `${e.Id}*`).join(" | ")}}`,
                            reducers: `GROUPBY 1 @${_relItem.Column} REDUCE ${_tmpProps.Reducer} ${_tmpProps.ReducerBy ? `1 @${_tmpProps.ReducerBy}` : 0} AS _value`,
                        }).then((res) => {
                            if (res.code === 200) methods.setValue(k, res.data.map((e: any) => { return { ...e, Name: _tmpProps.Name, Value: e['_value'] } }))
                        })
                    }
                }
            })
        }
    }, [_rels.length, data.data])

    useEffect(() => {
        if (!props.data && props.cardItem) {
            const dataController = new DataController({ pid: props.pid, module: props.cardItem.TbName })
            dataController.aggregateList({ page: 1, size: 10 }).then(res => {
                if (res.code === 200) setData({ data: res.data, totalCount: res.totalCount })
                else ToastMessage.errors(res.message)
            })
        }
    }, [props.cardItem, props.data])

    switch (props.cardItem.Type) {
        case CardType.cardNewsContainMediaVertical:
            return props.data ? <CardNews
                id={props.id}
                style={props.style}
                data={props.data}
                className={props.className}
            /> : <>
                {data.data.map((item: any) => {
                    let _tmp: { [key: string]: any } = {}
                    if (props.cardItem.Props)
                        Object.keys(props.cardItem.Props).forEach(k => {
                            const _rel = _rels.find((r) => r.Id === (props.cardItem.Props as any)[k])
                            const _list = methods.watch(k) ?? []
                            if (_rel) {
                                if (_rel.TablePK === props.cardItem.TbName) _tmp[k] = _list.find((e: any) => e[_rel?.Column ?? ""].includes(item.Id))
                                else _tmp[k] = _list.filter((e: any) => item[_rel?.Column ?? ""].includes(e.Id))
                            }
                        })
                    return <CardNews
                        id={props.id}
                        key={item.Id}
                        data={{ ...item, ..._tmp }}
                        style={props.style}
                        className={props.className} />;
                })}
            </>
        case CardType.cardNewsCoverMediaVertical:
            return props.data ? <CardNews
                id={props.id}
                mediaType="cover"
                mediaSize={'100%'}
                style={props.style}
                data={props.data}
                className={props.className}
            /> : <>
                {data.data.map((item: any) => {
                    let _tmp: { [key: string]: any } = {}
                    if (props.cardItem.Props)
                        Object.keys(props.cardItem.Props).forEach(k => {
                            const _tmpProps = (props.cardItem.Props as any)[k]
                            const _rel = _rels.find((r) => typeof _tmpProps === "string" ? r.Id === _tmpProps : r.Id === _tmpProps?.RelativeId)
                            const _list = methods.watch(k) ?? []
                            if (_rel) {
                                if (_rel.TablePK === props.cardItem.TbName) _tmp[k] = _list.find((e: any) => e[_rel?.Column ?? ""].includes(item.Id))
                                else _tmp[k] = _list.filter((e: any) => item[_rel?.Column ?? ""].includes(e.Id))
                            }
                        })
                    return <CardNews
                        id={props.id}
                        key={item.Id}
                        data={{ ...item, ..._tmp }}
                        mediaType="cover"
                        mediaSize={'100%'}
                        style={props.style}
                        className={props.className} />;
                })}
            </>
        case CardType.cardNewsContainMediaHorizontal:
            return props.data ? <CardNews
                id={props.id}
                style={props.style}
                direction="row"
                data={props.data}
                className={props.className}
            /> : <>
                {data.data.map((item: any) => {
                    let _tmp: { [key: string]: any } = {}
                    if (props.cardItem.Props)
                        Object.keys(props.cardItem.Props).forEach(k => {
                            const _rel = _rels.find((r) => r.Id === (props.cardItem.Props as any)[k])
                            const _list = methods.watch(k) ?? []
                            if (_rel) {
                                if (_rel.TablePK === props.cardItem.TbName) _tmp[k] = _list.find((e: any) => e[_rel?.Column ?? ""].includes(item.Id))
                                else _tmp[k] = _list.filter((e: any) => item[_rel?.Column ?? ""].includes(e.Id))
                            }
                        })
                    return <CardNews
                        id={props.id}
                        key={item.Id}
                        data={{ ...item, ..._tmp }}
                        style={props.style}
                        direction="row"
                        className={props.className} />;
                })}
            </>
        case CardType.cardNewsCoverMediaHorizontal:
            return props.data ? <CardNews
                id={props.id}
                mediaType="cover"
                direction="row"
                style={props.style}
                mediaSize={'100%'}
                data={props.data}
                className={props.className}
            /> : <>
                {data.data.map((item: any) => {
                    let _tmp: { [key: string]: any } = {}
                    if (props.cardItem.Props)
                        Object.keys(props.cardItem.Props).forEach(k => {
                            const _rel = _rels.find((r) => r.Id === (props.cardItem.Props as any)[k])
                            const _list = methods.watch(k) ?? []
                            if (_rel) {
                                if (_rel.TablePK === props.cardItem.TbName) _tmp[k] = _list.find((e: any) => e[_rel?.Column ?? ""].includes(item.Id))
                                else _tmp[k] = _list.filter((e: any) => item[_rel?.Column ?? ""].includes(e.Id))
                            }
                        })
                    return <CardNews
                        id={props.id}
                        key={item.Id}
                        data={{ ...item, ..._tmp }}
                        mediaType="cover"
                        direction="row"
                        style={props.style}
                        mediaSize={'100%'}
                        className={props.className} />;
                })}
            </>
        case CardType.cardNewsHugMedia:
            return props.data ? <CardNews
                id={props.id}
                mediaType="hug"
                style={props.style}
                data={props.data}
                className={props.className}
            /> : <>
                {data.data.map((item: any) => {
                    let _tmp: { [key: string]: any } = {}
                    if (props.cardItem.Props)
                        Object.keys(props.cardItem.Props).forEach(k => {
                            const _rel = _rels.find((r) => r.Id === (props.cardItem.Props as any)[k])
                            const _list = methods.watch(k) ?? []
                            if (_rel) {
                                if (_rel.TablePK === props.cardItem.TbName) _tmp[k] = _list.find((e: any) => e[_rel?.Column ?? ""].includes(item.Id))
                                else _tmp[k] = _list.filter((e: any) => item[_rel?.Column ?? ""].includes(e.Id))
                            }
                        })
                    return <CardNews
                        id={props.id}
                        key={item.Id}
                        data={{ ...item, ..._tmp }}
                        mediaType="hug"
                        style={props.style}
                        className={props.className} />;
                })}
            </>
        case CardType.cardProductContainMediaVertical:
            return props.data ? <CardProduct
                style={props.style}
                data={props.data}
                className={props.className}
            /> : <div id={props.id} className={styles['list-card-container']}>
                {data.data.map((e: any) => <CardProduct
                    key={e.Id}
                    data={e}
                    style={props.style}
                    className={props.className}
                />)}
            </div>
        case CardType.cardProductCoverMediaVertical:
            return props.data ? <CardProduct
                mediaType="cover"
                style={props.style}
                data={props.data}
                className={props.className}
            /> : <>
                {data.data.map((e: any) => <CardProduct
                    key={e.Id}
                    data={e}
                    mediaType="cover"
                    style={props.style}
                    className={props.className}
                />)}
            </>
        case CardType.cardProductContainMediaHorizontal:
            return props.data ? <CardProduct
                direction="row"
                style={props.style}
                data={props.data}
                className={props.className}
            /> : <>
                {data.data.map((e: any) => <CardProduct
                    key={e.Id}
                    data={e}
                    direction="row"
                    style={props.style}
                    className={props.className}
                />)}
            </>
        case CardType.cardProductCoverMediaHorizontal:
            return props.data ? <CardProduct
                direction="row"
                mediaType="cover"
                style={props.style}
                data={props.data}
                className={props.className}
            /> : <>
                {data.data.map((e: any) => <CardProduct
                    key={e.Id}
                    data={e}
                    direction="row"
                    mediaType="cover"
                    style={props.style}
                    className={props.className}
                />)}
            </>
        default:
            return <div />
    }
}