import React, { createRef, CSSProperties } from "react";
import ReactDOM from "react-dom";
import { UseFormRegister } from "react-hook-form";
import { Text } from "wini-web-components";

interface AutoCompleteInputProps {
    options?: Array<string>,
    style?: CSSProperties,
    onChangeText: (ev: HTMLInputElement, typingIndex: number) => void,
    onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void
    name?: string,
    placeholder?: string,
    register?: UseFormRegister<any>,
}

interface AutoCompleteInputState {
    offset?: { x: number | string, y: number },
    selected?: string
}


export default class AutoCompleteInput extends React.Component<AutoCompleteInputProps, AutoCompleteInputState> {

    constructor(props: AutoCompleteInputProps) {
        super(props)
        this.state = {}
        this.onChange = this.onChange.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this.unFocus = this.unFocus.bind(this)
    }

    private typingIndex = 0
    private selectedIndex = 0
    private scrollRef = createRef<HTMLDivElement>()
    private hoverElement: string | undefined

    componentDidUpdate(prevProps: Readonly<AutoCompleteInputProps>, prevState: Readonly<AutoCompleteInputState>, snapshot?: any): void {
        if (prevProps.options !== this.props.options && this.props.options?.length) {
            this.setState({ ...this.state, selected: this.props.options[0] })
            this.selectedIndex = 0
        }
        if (prevState.selected !== this.state.selected && this.state.selected && this.scrollRef.current) {
            this.scrollRef.current.scrollTo({
                top: (this.scrollRef.current.firstChild as HTMLButtonElement).offsetHeight * this.selectedIndex,
                behavior: "smooth"
            })
        }
    }

    private onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        let _input = ev.target
        const _tmp = _input.getBoundingClientRect()
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        if (ctx) ctx.font = "1.4rem Inter"
        let text = ctx?.measureText(_input.value.substring(0, (_input.selectionStart ?? 0) + 1))
        this.setState({ ...this.state, offset: { y: _tmp.top + _tmp.height, x: `min(${_tmp.x + (text?.width ?? 0)}px, ${_tmp.x + _tmp.width}px)` } })
        let _split = _input.value.substring(0, (_input.selectionStart ?? 0) + 1).split(" ")
        this.typingIndex = _split.length - 1
        this.props.onChangeText(_input, this.typingIndex)
    }

    private onKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        console.log(ev.key)
        ev.preventDefault()
        if (this.props.options?.length && this.state.offset) {
            switch (ev.key.toLowerCase()) {
                case "enter":
                    let _input = ev.target as HTMLInputElement
                    let _split = _input.value.split(" ")
                    if (_split[this.typingIndex].includes("@")) {
                        const _vIndex = _input.value.substring(0, _input.selectionStart ?? 0).lastIndexOf("@")
                        const _spaceIndex = _input.value.substring(_input.selectionStart ?? 0).indexOf(" ")
                        _input.value = _input.value.substring(0, _vIndex) + (this.state.selected ?? "") + (_spaceIndex > 0 ? _input.value.substring(_spaceIndex) : "")
                    } else {
                        _split[this.typingIndex] = (this.state.selected ?? "")
                        _input.value = _split.filter(e => e.trim().length > 0).join(" ")
                    }
                    this.setState({ selected: undefined, offset: undefined })
                    this.props.onChangeText(_input, this.typingIndex)
                    break;
                case "arrowup":
                    if (this.state.selected) {
                        let _index = this.props.options.indexOf(this.state.selected)
                        _index = ((_index === 0) ? this.props.options.length : _index) - 1
                        this.selectedIndex = _index
                        this.setState({ ...this.state, selected: this.props.options[_index] })
                    }
                    break;
                case "arrowdown":
                    if (this.state.selected) {
                        let _index = this.props.options.indexOf(this.state.selected)
                        _index = ((_index + 1 === this.props.options.length) ? -1 : _index) + 1
                        this.selectedIndex = _index
                        this.setState({ ...this.state, selected: this.props.options[_index] })
                    }
                    break;
                case "control":
                    break
                default:
                    break;
            }
        }
    }

    private unFocus = (ev: any) => {
        if (this.hoverElement) {
            let _input = ev.target
            let _split = _input.value.split(" ")
            if (_split[this.typingIndex].includes("@")) {
                const _vIndex = _input.value.substring(0, _input.selectionStart ?? 0).lastIndexOf("@")
                const _spaceIndex = _input.value.substring(_input.selectionStart ?? 0).indexOf(" ")
                _input.value = _input.value.substring(0, _vIndex) + this.hoverElement + (_spaceIndex > 0 ? _input.value.substring(_spaceIndex) : "")
            } else {
                _split[this.typingIndex] = this.hoverElement
                _input.value = _split.filter((e: string) => e.trim().length > 0).join(" ")
            }
            this.props.onChangeText(_input, this.typingIndex)
        }
        this.setState({ selected: undefined, offset: undefined })
    }

    render(): React.ReactNode {
        return <div className={`auto-complete-input-container row body-3`} style={this.props.style} >
            {
                this.props.register && this.props.name ?
                    <input
                        {...this.props.register(this.props.name, {
                            onChange: this.onChange,
                            onBlur: this.unFocus
                        })}
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        onChange={this.onChange}
                        onKeyUp={this.onKeyUp}
                        onFocus={this.props.onFocus}
                    /> : <input
                        onFocus={this.props.onFocus}
                        placeholder={this.props.placeholder}
                        onChange={this.onChange}
                        onKeyUp={this.onKeyUp}
                        onBlur={this.unFocus}
                    />
            }
            {this.props.options?.length && this.state.offset && ReactDOM.createPortal(
                <div ref={this.scrollRef} className="col auto-complete-input-option-container" style={{ top: this.state.offset.y, left: this.state.offset.x }}>
                    {this.props.options.map((vl, i) => {
                        return <button
                            key={`${vl}-${i}`}
                            type="button"
                            className={`row auto-complete-input-option ${this.state.selected === vl ? "selected" : ''}`}
                            onMouseMove={() => { this.hoverElement = vl }}
                            onMouseOut={() => { this.hoverElement = undefined }}
                        >
                            <Text className="button-text-3" maxLine={1}>{vl}</Text>
                        </button>
                    })}
                </div>,
                document.body
            )}
        </div>
    }


}
